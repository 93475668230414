import { useEffect, useState } from 'react';
import { setTranslations } from 'store/translation/translation.slice';
import { endpoint } from 'services/translationsEndpoint';
import { TCodes } from 'utils/interfaces/store/translations.interfaces';
import { useAppDispatch } from 'store/hooks';
import { Numbers, NumbersText } from 'utils/helpers/dictionaries';

export const useGetTextInSelectLanguaje = (languageCode: TCodes) => {
  const dispatch = useAppDispatch();
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    endpoint(languageCode)
      .then((query: object | any) => {
        if (query['statusCode'] === Numbers.notFound) throw Error(NumbersText.notFoundText)
        dispatch(
          setTranslations({
            translations: query,
            isSucces: true
          })
        );
      })
      .catch(() => {
        dispatch(setTranslations({ isError: true }))
        setIsError(true);
      });
  }, [languageCode])

  return { isError }
}
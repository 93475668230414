import * as yup from 'yup';

const AcademicInformationSchema = yup.object().shape({
  id: yup.number().nullable(),
  educationalLevel: yup.string().required('Este campo es obligatorio'),
  professionalTitle: yup.string().required('Este campo es obligatorio'),
  institution: yup.string().required('Este campo es obligatorio'),
  startDate: yup.string().required('Este campo es obligatorio'),
  endDate: yup.string().required('Este campo es obligatorio'),
});

const EmergencyContactInformationSchema = yup.object().shape({
  id: yup.number().nullable(),
  fullname: yup.string().required('Este campo es obligatorio'),
  primaryPhone: yup.string().required('Este campo es obligatorio'),
});

const UserResponseSchema = yup.object().shape({
  id: yup.number(),
  names: yup.string().required('Este campo es obligatorio'),
  lastname: yup.string().required('Este campo es obligatorio'),
  documentType: yup.string().required('Este campo es obligatorio'),
  documentNumber: yup.string().required('Este campo es obligatorio'),
  gender: yup.string().required('Este campo es obligatorio'),
  birthdate: yup.string().required('Este campo es obligatorio'),
  countryOfBirth: yup.string().required('Este campo es obligatorio'),
  departamentOfBirth: yup.string().required('Este campo es obligatorio'),
  cityOfBirth: yup.string().required('Este campo es obligatorio'),
  civilStatus: yup.string().required('Este campo es obligatorio'),
  bloodGroup: yup.string().required('Este campo es obligatorio'),
  phone: yup.string().required('Este campo es obligatorio'),
  email: yup
    .string()
    .email('Tiene que ser un email válido')
    .required('Este campo es obligatorio'),
  countryOfResidence: yup.string().required('Este campo es obligatorio'),
  departamentOfResidence: yup.string().required('Este campo es obligatorio'),
  cityOfResidence: yup.string().required('Este campo es obligatorio'),
  address: yup.string().required('Este campo es obligatorio').max(40, 'Máximo 40 caracteres'),
  preferredName: yup.string().required('Este campo es obligatorio'),
  shirtSize: yup.string().required('Este campo es obligatorio'),
  secondName: yup.string().optional(),
  secondLastname: yup.string().optional(),
  residenceDistrict: yup.string().when('countryOfResidence', {
    is: 'Perú',
    then: yup.string().required('Este campo es obligatorio'),
  }),
  academicInformation: yup
    .array(AcademicInformationSchema)
    .max(5)
    .min(1)
    .required('Este campo es obligatorio'),
  emergencyContactInformation: yup
    .array(EmergencyContactInformationSchema)
    .max(2)
    .min(1)
    .required('Este campo es obligatorio'),
});

export default UserResponseSchema;

import { ACalendar, Input, Label } from 'components/atoms';
import ACalendarNext from 'components/atoms/AInputDateNext/ACalendarNext.component';
import CatalogSelect from 'components/molecules/CatalogSelect/CatalogSelect';
import CountrySelect from 'components/molecules/CountrySelect/CountrySelect';
import DepartmentSelect from 'components/molecules/DepartmentSelect/DepartmentSelect';
import moment from 'moment';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { catalogoPersonal } from 'utils/helpers/dictionaries';
import { IUserConfirmData } from '../ConfirmDataTabForm';
import CitySelect from 'components/molecules/CitySelect/CitySelect';
import { useExternalCodeContext } from '../ExternalCodesContext';
import { DocumentTypeSelect } from 'components/molecules/DocumentTypeSelect/DocumentTypeSelect';
import useCalendar from 'hooks/useCalendar';
interface PersonalDataSectionProps {
  saveConfirmData: () => void;
}

export default function PersonalDataSection({
  saveConfirmData,
}: Readonly<PersonalDataSectionProps>) {
  const { control, setValue, getValues } = useFormContext<IUserConfirmData>();
  const {
    countryOfBirth,
    setCountryOfBirth,
    departmentOfBirth,
    setDepartmentOfBirth,
  } = useExternalCodeContext();
  const [_, setMarginForCalendar] = useState(0);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const birthdateCalendar = useCalendar();
  function handleCalendarCloseOnClick() {
    birthdateCalendar.setIsCalendarOpen(false);
  }
  return (
    <>
      <Controller
        control={control}
        name="names"
        rules={{ required: true }}
        render={({ field: { value, onBlur, ...rest }, fieldState }) => (
          <Input
            id="names"
            label="Primer Nombre"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            defaultValue={value}
            onBlur={() => {
              saveConfirmData();
              onBlur();
            }}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="secondName"
        rules={{ required: true }}
        render={({ field: { value, onBlur, ...rest }, fieldState }) => (
          <Input
            id="secondName"
            label="Segundo Nombre (Opcional)"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            defaultValue={value}
            onBlur={() => {
              saveConfirmData();
              onBlur();
            }}
            {...rest}
          />
        )}
      /> 
      <Controller
        control={control}
        name="lastname"
        rules={{ required: true }}
        render={({
          field: { value, onChange, onBlur, ...rest },
          fieldState,
        }) => (
          <Input
            id="lastname"
            label="Primer apellido"
            key="lastname"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            defaultValue={value}
            onChange={onChange}
            onBlur={() => {
              saveConfirmData();
              onBlur();
            }}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="secondLastname"
        rules={{ required: true }}
        render={({
          field: { value, onChange, onBlur, ...rest },
          fieldState,
        }) => (
          <Input
            id="secondLastname"
            label="Segundo Apellido (Opcional)"
            key="secondLastname"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            defaultValue={value}
            onChange={onChange}
            onBlur={() => {
              saveConfirmData();
              onBlur();
            }}
            {...rest}
          />
        )}
      />
      <div>
        <Label text="Fecha de nacimiento" htmlFor="birthdate" />
        <Controller
          control={control}
          name="birthdate"
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <ACalendar
                birthdateForm={moment(value)}
                updateBirthdate={(value) => {
                  onChange(value);
                  saveConfirmData();
                }}
                setMarginForCalendar={birthdateCalendar.setMarginForCalendar}
                isOpenCalendar={birthdateCalendar.isCalendarOpen}
                setIsOpenCalendar={birthdateCalendar.setIsCalendarOpen}
                error={fieldState.error !== undefined}
              />
            </>
          )}
        />
      </div>
      <Controller
        control={control}
        name="documentType"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <DocumentTypeSelect
            onClick={handleCalendarCloseOnClick}
            textLabel="Tipo de documento"
            error={fieldState.error !== undefined}
            htmlForLabel="documentType"
            subtext={fieldState.error?.message}
            handleChange={(value) => {
              onChange(value);
              saveConfirmData();
            }}
            value={{ label: value as any, value: value as any }}
            country="Perú"
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="documentNumber"
        rules={{ required: true }}
        render={({
          field: { value, onChange, onBlur, ...rest },
          fieldState,
        }) => (
          <Input
            id="documentNumber"
            label="Número de documento"
            key="documentNumber"
            subtext={fieldState.error?.message}
            error={fieldState.error !== undefined}
            defaultValue={value}
            onChange={onChange}
            onBlur={() => {
              saveConfirmData();
              onBlur();
            }}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="countryOfBirth"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <CountrySelect
            onClick={handleCalendarCloseOnClick}
            textLabel="País de nacimiento"
            htmlForLabel="countryOfBirth"
            placeholder="Seleccione una"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            handleChange={(value) => {
              if (value !== getValues("countryOfBirth")) {
                setValue('departamentOfBirth', '');
                setValue('cityOfBirth', '');
              }
              onChange(value);
              saveConfirmData();
            }}
            value={{
              label: value as any,
              value: value as any,
            }}
            onExternalCodeChange={(externalCode) => {
              setCountryOfBirth(externalCode);
            }}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="departamentOfBirth"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <DepartmentSelect
            onClick={handleCalendarCloseOnClick}
            textLabel="Departamento de nacimiento"
            htmlForLabel="departamentOfBirth"
            placeholder={'Seleccione uno'}
            key="departamentOfBirth"
            subtext={fieldState.error?.message}
            error={fieldState.error !== undefined}
            handleChange={(value) => {
              if (value !== getValues("departamentOfBirth")) {
                setValue('cityOfBirth', '');
              }
              onChange(value);
              saveConfirmData();
            }}
            value={{
              label: value as any,
              value: value as any,
            }}
            disabled={!countryOfBirth}
            country={countryOfBirth}
            onExternalCodeChange={(externalCode) => {
              setDepartmentOfBirth(externalCode);
            }}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="cityOfBirth"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <CitySelect
            onClick={handleCalendarCloseOnClick}
            textLabel="Provincia de nacimiento"
            htmlForLabel="cityOfBirth"
            key="cityOfBirth"
            subtext={fieldState.error?.message}
            placeholder={'Seleccione uno'}
            error={fieldState.error !== undefined}
            handleChange={(value) => {
              onChange(value);
              saveConfirmData();
            }}
            value={{
              label: value as any,
              value: value as any,
            }}
            disabled={!departmentOfBirth}
            department={departmentOfBirth}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="bloodGroup"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <CatalogSelect
            onClick={handleCalendarCloseOnClick}
            textLabel="Grupo sanguíneo"
            htmlForLabel="bloodGroup"
            subtext={fieldState.error?.message}
            placeholder={'Seleccione uno'}
            handleChange={(value) => {
              onChange(value);
              saveConfirmData();
            }}
            value={{ label: value as any, value: value as any }}
            catalog={catalogoPersonal.bloodGroup}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="gender"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <CatalogSelect
            onClick={handleCalendarCloseOnClick}
            textLabel="Sexo"
            htmlForLabel="gender"
            subtext={fieldState.error?.message}
            error={fieldState.error !== undefined}
            placeholder={'Seleccione uno'}
            handleChange={(value) => {
              onChange(value);
              saveConfirmData();
            }}
            value={{ label: value as any, value: value as any }}
            catalog={catalogoPersonal.gender}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="civilStatus"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <CatalogSelect
            onClick={handleCalendarCloseOnClick}
            textLabel="Estado civil"
            htmlForLabel="civilStatus"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            placeholder={'Seleccione uno'}
            handleChange={(value) => {
              onChange(value);
              saveConfirmData();
            }}
            value={{ label: value as any, value: value as any }}
            catalog={catalogoPersonal.ecMaritalStatus}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="preferredName"
        rules={{ required: true }}
        render={({
          field: { value, onChange, onBlur, ...rest },
          fieldState,
        }) => (
          <Input
            id="preferredName"
            label="¿Cuál es tu nombre de preferencia?"
            key="preferredName"
            defaultValue={value}
            subtext={fieldState.error?.message}
            error={fieldState.error !== undefined}
            onChange={(e) => {
              onChange(e);
            }}
            {...rest}
            onBlur={() => {
              saveConfirmData();
              onBlur();
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="shirtSize"
        rules={{ required: true }}
        render={({ field: { onChange, value, ...rest }, fieldState }) => (
          <CatalogSelect
            textLabel="Talla de camiseta"
            htmlForLabel="shirtSize"
            error={fieldState.error !== undefined}
            subtext={fieldState.error?.message}
            placeholder={'Seleccione uno'}
            handleChange={(value) => {
              onChange(value);
              saveConfirmData();
            }}
            value={{ label: value as any, value: value as any }}
            catalog={catalogoPersonal.tallaCamisa}
            {...rest}
          />
        )}
      />
    </>
  );
}

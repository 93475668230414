export enum userStates {
  initial = 0,
  qualifyProspect = 1,
  identifyCandidate = 2,
  evaluateCandidate = 3,
  makeProposal = 4,
  hire = 5,
  parked = 6,
  pragmatic = 7,
  discarded = 8,
  inProgress = 9,
  repostulated = 10,
}

export enum STATES {
  THREE = '3',
  FOUR = '4',
}

export enum activities {
  hackerrank = '1',
  criteria = '2',
  grit = '3',
  essay = '4',
  psychological_interview = '5',
  activity_seven = '7',
  approachTheRol = '20',
  technical_interview = '45',
  teamInterview = '47',
  clientInterview = '48',
  technicalKnowledge = '1008',
  confirmData = 51,
  documentUpload = 52,
  welcomeKit = 55,
  medicalExam = 54,
  signContract = 53,
  familyInterview = 56,
}

export enum activityStates {
  error = 1,
  initial = 2,
  inProgress = 3,
  completed = 4,
  disabled = 5,
  approved = 6,
  disapproved = 7,
  locked = 8,
}

export enum ActivityTypes {
  uploadFile = 1,
  quiz = 2,
  interview = 3,
}

export enum bloodTypes {
  A = 'A',
  B = 'B',
  AB = 'AB',
  O = 'O',
}

export enum RHs {
  rh_negative = '-',
  rh_positive = '+',
  point = '.',
}

export enum identificationTypes {
  cc = 'Cédula de ciudadanía',
  ce = 'Cédula de extranjería',
  passport = 'Pasaporte',
  ti = 'Tarjeta de identidad',
  cip = 'Cédula de identidad personal',
}

export enum maritalStatusTypes {
  married = 'Casado(a)',
  single = 'Soltero(a)',
  divorced = 'Divorciado(a)',
  widowed = 'Viudo(a)',
}

export enum educationLevelTypes {
  undergraduate = 'Pregrado',
  postgraduate = 'Posgrado',
}

export enum shirtSizeTypes {
  s = 'S',
  m = 'M',
  l = 'L',
  xl = 'XL',
}

export enum documentTypes {
  identificationDocument = 'cedula',
  militaryCard = 'libreta',
  epsCertificate = 'cert_eps',
  degreeCertificate = 'acta_grado',
  studyCertificate = 'cert_estudios',
  professionalCard = 'tarjeta_profesional',
  bankCertificate = 'cert_bancario',
  pensionCertificate = 'cert_pension',
  cesantiasCertificate = 'cert_cesantias',
  beneficiario = 'beneficiario',
}

export enum documentTypesNames {
  rentCertificate = 'Certificado de renta',
  dniDocument = 'DNI',
  gradeCertificate = 'Diploma de grado',
  passport = 'Pasaporte',
  declaSeguroVida = 'Decalración seguro de vida',
  beneficiario = 'Beneficiario',
  epsCertificate = 'Certificado EPS',
  pensionCertificate = 'Certificado pensión',
  cesantiasCertificate = 'Certificado cesantías',
  identificationDocument = 'Cédula',
  militaryCard = 'Libreta militar',
  degreeCertificate = 'Acta de grado',
  bankCertificate = 'Certificado bancario',
  studyCertificate = 'Certificado de estudios',
  professionalCard = 'Tarjeta profesional',
  presentationLetter = 'Carta de presentación',
}

export enum AlertModalType {
  question = 'question',
  success = 'success',
  error = 'error',
  exit = 'exit',
}

export enum VisibleModals {
  identificationDocument = 'identificationDocument',
  degreeCertificate = 'degreeCertificate',
  invalidPDF = 'invalidPDF',
  uploadingError = 'uploadingError',
  invalidSize = 'invalidSize',
}

export enum fileTypes {
  pdf = 'pdf',
  applicationPdf = 'application/pdf',
}

export enum YesOrNot {
  yes = 'yes',
  no = 'no',
}

export enum SioNo {
  si = 'Si',
  no = 'No',
}

export enum Next {
  Next = 'next',
}

export enum EmptyString {
  emptyString = '',
  emptyStringSpace = ' ',
  point = '.',
}

export enum Bancolombia {
  bancolombia = 'Bancolombia',
  message = `Te enviaremos los detalles al correo electrónico`,
}

export enum Numbers {
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
  seven = 7,
  eight = 8,
  nine = 9,
  ten = 10,
  eleven = 11,
  twelve = 12,
  thirteen = 13,
  fourteen = 14,
  fifteen = 15,
  thirtythree = 33,
  fourty = 40,
  haundred = 100,
  limitList = 800,
  timeOut = 3000,
  timeOutMin = 1000,
  success = 200,
  notFound = 404
}
export enum Vacants {
  initialVacantIndex = 0,
}
export enum DesktopQuery {
  desktop = 1279,
}

export enum ModalTypes {
  warning = 'warning',
  info = 'info',
}

export enum TrueOrFalse {
  true = 'true',
  false = 'false',
}

export enum SapApi {
  sapApi = 'sapApi',
}

export enum ContentTypes {
  text = 'text',
  textWithAtoms = 'textWithAtoms',
  textItem = 'textItem',
  button = 'button',
}

export enum ContentTabs {
  description = 'description',
  recomendations = 'recomendations',
  both = 'both',
}

export enum DocOptions {
  documentValue = 'cedula',
  documentLable = 'Cédula',
  civilRegistrationValue = 'reg_civil',
  civilRegistrationLable = 'Registro civil',
}

export enum RelationShips {
  mom = 'Madre',
  dad = 'Padre',
}

export enum ModalSelector {
  next = 'Siguiente',
  add = 'Agregar',
}

export enum OnCloseModal {
  title = '¿Deseas salir de la página?',
  message = 'Tus cambios no se han enviado',
}

export enum OptionAddBeneficiary {
  name = 'Nombres',
  documentTypeBen = 'documentTypeBen',
  documentType = 'Tipo de documento',
  documentBen = 'documentBen',
  bornDateBen = 'Fecha de nacimiento',
  lastNamesBen = 'lastNamesBen',
  namesBen = 'namesBen',
  relationshipSelector = 'Selecciona el parentesco',
  beneficiaryType = 'beneficiaryType',
  errorFile = 'No se puede continuar sin subir el archivo',
  parentesco = 'Parentesco',
  bornDateBenEnglish = 'bornDateBen',
  documentIdentity = 'Documento de identidad',
  citizenshipCard = 'Cédula de ciudadanía',
  militaryCardOptional = 'Libreta militar (opcional)',
  militaryCardOptionalMessage = 'Carga una copia de tu libreta militar. Ubica ambas caras en una sola hoja.',
  epsMessage = '¿Estás afiliado a una EPS?',
  noEpsMessage = 'Te afiliaremos a la EPS',
  degreeCertificate = 'Acta de grado',
  studyCertificateOptional = 'Certificado de estudio (opcional)',
  studyCertificateOptionalMessage = 'Si estas estudiando carga una copia de tu certificado de estudio. Si tienes certificaciones, cursos, talleres o conferencias a las que hayas asistido carga un archivo por cada uno.',
  professionalCardOptional = 'Tarjeta profesional (opcional)',
  professionalCardOptionalMessage = 'Carga una copia de tu tarjeta profesional o una constrancia de trámite.',
  bankCertificateMessages = '¿Tienes una cuenta bancaria para consignar tu salario?',
  pensionCertificateMessage = '¿Has cotizado a pensión?',
  cesantiasCertificateMessage = '¿Estás inscrito a un fondo de cesantías?',
  exitPageMessage = '¿Deseas salir de la página?',
  sendDontDocumentsMessage = 'Tus documentos no se han enviado',
  exitMessage = 'Salir',
  editMessage = 'Seguir editando',
  uploadDocuments = 'Carga documentos',
  uploadDocumentsMessages = 'Carga algunos documentos que necesitamos para formalizar tu contrato. Los documentos deben ser cargados únicamente en formato PDF. El tamaño máximo de cada archivo debe ser de 10 MB.',
}

export enum UrlPlataform {
  homeHiring = '/home-hiring',
  documentUpload = '/home-hiring/upload-documents-',
  confirmData = '/home-hiring/confirm-data-',
  welcomeKit = '/home-hiring/welcome-kit-',
}
export enum atomTable {
  tableThreeCols = 'atomTable__tableThreeCols',
  tableFiveCols = 'atomTable__tableFiveCols',
}

export enum NumbersText {
  zeroText = '0',
  oneText = '1',
  twoText = '2',
  threeText = '3',
  fourText = '4',
  fiveText = '5',
  sixText = '6',
  sevenText = '7',
  eightText = '8',
  nineText = '9',
  notFoundText = '404'
}

export enum Navegate {
  nuevospragmaticos = '/admin/nuevospragmaticos',
}
export enum OrganizationalInfo {
  division = 'division',
  department = 'department',
  chapter1 = 'chapter1',
  chapter2 = 'chapter2',
  especialidad1 = 'especialidad1',
  especialidad2 = 'especialidad2',
  technology1 = 'technology1',
  technology2 = 'technology2',
  technicalLevel1 = 'technicalLevel1',
  technicalLevel2 = 'technicalLevel2',
  referred = 'referred',
  divisionLabel = 'División',
  selectionOnePlaceHolder = 'Selecciona uno',
  chapter1Label = 'Chapter 1',
  technologyPlaceHolder = 'Selecciona una tecnología',
  technology1Label = 'Tecnología 1 (opcional)',
  technicalLevel1Label = 'Nivel técnico 1 (opcional)',
  technicalLevelPlaceHolder = 'Selecciona un nivel técnico',
  departmentLabel = 'Departamento (opcional)',
  departamentPlaceHolder = 'Selecciona un departamento',
  chapterPlaceholder = 'Selecciona un chapter',
  chapter2Label = 'Chapter 2 (opcional)',
  technology2Label = 'Tecnología 2 (opcional)',
  technicalLevel2Label = 'Nivel técnico 2 (opcional)',
  referredPlaceHolder = 'Ej: luis.perez@pragma.com.co',
  especialidad1PlaceHolder = 'Ej: Diseñador UI',
  especialidad2PlaceHolder = 'Ej: Analista UX',
  referredLabel = 'Referido por (opcional)',
  especialidad1Label = 'Especialidad 1',
  especialidad2Label = 'Especialidad 2 (opcional)',
  accountNumberLabel = 'Número de cuenta',
}

export enum Options {
  opcion1 = 'opcion1',
  opcion2 = 'opcion2',
  opcion3 = 'opcion3',
  opcion4 = 'opcion4',
  opcion5 = 'opcion5',
}
export enum AcademicInfo {
  jobTitleLabel = 'Título profesional',
  jobTitlePlaceHolder = 'Ej: Ingeniería de Sistemas',
  jobTitle = 'jobTitle',
  professionalCardNumber = 'professionalCardNumber',
  cv = 'cv',
  professionalCardNumberLabel = 'Número de tarjeta profesional (opcional)',
  professionalCardNumberPlaceHolder = 'Ej: 052156478931ANT',
  cvLabel = 'Hoja de vida',
  cvPlaceHolder = 'Ej: http://www.behance.net/nombre',
}
export enum PersonalInfo {
  firstName = 'firstName',
  secondName = 'secondName',
  lastName = 'lastName',
  lastname = 'lastname',
  secondLastName = 'secondLastName',
  birthDate = 'birthDate',
  birthdate = 'birthdate',
  gender = 'gender',
  civilStatus = 'civilStatus',
  bloodType = 'bloodType',
  documentType = 'documentType',
  documentNumber = 'documentNumber',
  preferredName = 'preferredName',
  shirtSize = 'shirtSize',
  bloodGroup = 'bloodGroup',
  names = 'names',
  FirstNameLabel = 'Primer nombre',
  FirstNamePlaceHolderl = 'Ej: Luisa',
  LastNamePlaceHolder = 'Ej: Gomez',
  LastNameLabel = 'Primer apellido',
  birthDateLabel = 'Fecha de nacimiento',
  civilStatusLabel = 'Estado civil',
  civilStatusPlaceHolder = 'Selecciona un estado civil',
  documentTypeLabel = 'Tipo de documento de identificación',
  documentTypePlaceHolder = 'Selecciona un tipo de documento',
  shirtSizeLabel = 'Talla de camiseta',
  shirtSizePlaceHoldler = 'Selecciona una',
  secondNameLabel = 'Segundo nombre(Opcional)',
  secondNamePlaceHolder = 'Ej: Maria',
  secondLastNamePlaceHolder = 'Ej: Muñoz',
  secondLastNameLabel = 'Segundo apellido(Opcional)',
  genderPlaceHolder = 'Selecciona un género',
  genderLabel = 'Género',
  bloodTypePlaceHolder = 'Selecciona un grupo sanguineo',
  bloodTypeLabel = 'Grupo Sanguíneo',
  documentNumberPlaceHolder = 'Ej: 1001001111',
  documentNumberLabel = 'Número de identificación',
  preferredNamePlaceHolder = 'Ej: Mari',
  preferredNameLabel = 'Nombre de preferencia',
}

export enum ContactInfo {
  email = 'email',
  phone = 'phone',
  address = 'address',
  emailLabel = 'Correo electrónico',
  phoneLabel = 'Celular',
  phonePlaceHolder = 'Ej: +57 3XX XXX XXXX',
  phoneSubtext = 'Ingresa el número de indicativo',
}

export enum calendarConstant {
  Do = 'Do',
  Lu = 'Lu',
  Ma = 'Ma',
  Mi = 'Mi',
  Ju = 'Ju',
  Vi = 'Vi',
  Sa = 'Sa',
  days = 'days',
  day = 'day',
  month = 'month',
  months = 'months',
  years = 'years',
  year = 'year',
  week = 'week',
  dayMonthYear = 'YYYY-MM-DD',
  calendarProfile = 'calendar-profile',
  es = 'es',
  openCalendary = 'Botón para abrir calendario',
  calendar = 'calendar',
  calendarPlaceholder = '1993-12-11',
  MMMM = 'MMMM y',
  button = 'button',
  btnLastMonth = 'Botón para ir al mes anterior',
  de = 'de',
  openYear = 'Botón para expandir años',
  btnNextMonth = 'Botón para ir al mes siguiente',
  btnLastYear = 'Botón para ir al año anterior',
  btnNextYear = 'Botón para ir al año siguiente',
  MMM2 = 'MMM',
  YYYY = 'YYYY',
}

export enum generalLists {
  option1 = 'Cédula de Ciudadanía',
  option2 = 'Tarjeta de identidad',
  option3 = 'Cédula de Extranjería',
  option4 = 'Pasaporte',
  option5 = 'Registro civil de nacimiento',
  option6 = 'Permiso de trabajo',
  option7 = 'Permiso de proteccion temporal',
  option8 = 'Documento Nacional de Identidad', //pen
  option9 = 'Documento Personal de Identidad', //pen
  option10 = 'Permiso Especial de Permanencia',
  option11 = 'Permiso de protección Temporal', //pen
  option12 = 'Cédula de Identidad ID',
  option13 = 'Carné de Residente Permanente', //pen
  option14 = 'Carné de extranjería',
}

export enum catalogoPersonal {
  gender = 'gender',
  bloodGroup = 'bloodGroup',
  tallaCamisa = 'tallaCamisa',
  ecMaritalStatus = 'ecMaritalStatus',
  ISOCountryList = 'ISOCountryList',
  REGION_COL = 'REGION_COL',
  MUNICIPALITY_COL = 'MUNICIPALITY_COL',
  addressType = 'addressType',
  contractType = 'contractType',
  currency = 'currency',
  FOPayComponent = 'FOPayComponent',
  Seniority = 'Seniority',
  FODivision = 'FODivision',
  FODepartment = 'FODepartment',
  FOJobFunction = 'FOJobFunction',
  cust_Teconologia = 'cust_Teconologia',
  degree = 'degree',
  Speciality = 'Speciality',
  EmpEmployment = 'EmpEmployment',
  DISTRITO_PER = 'DISTRITO_PER',
  Bancos = 'PaymentInformationDetail',
  technicalLevel = 'cust_Seniority_Level_Especialidad',
  eps = 'catalogue-eps',
  cesantias = 'severance-catalogue',
}

export enum catalogoLenguage {
  ES = 'label_es_ES',
  US = 'label_en_US',
  ESyUS = 'label_es_ES,label_en_US',
  ESyUSyISO = 'label_es_ES,label_en_US,externalCode',
  name = 'name',
  externalName = 'externalName',
  externalCode = 'externalCode',
  line = '-',
  COL = 'COL',
  signal = '@',
  ESyUSyISOyId = 'label_es_ES,label_en_US,externalCode,optionId',
  point = '.',
}

export enum LoginPanelConst {
  datos = 'datos',
  recargado = 'recargado',
  true = 'true',
  success = 'success',
  Error = 'error',
  noPermissions = 'No tienes permisos para acceder a este sitio, contacta al administrador e intenta de nuevo',
  failCreateSap = 'No se pudo crear el usuario en sap',
  successMessgge = 'Has confirmado los datos del nuevo Pragmático',
  Domicilio = 'Domicilio',
}
export enum ModalMenssaje {
  save = 'Guardando información',
}

export enum devicesWidth {
  mobile = 425,
  tablet = 768,
  desktop = 1280,
}

export enum CatalogoConstratoCOL {
  undefinedTerm = 'Término Indefinido-COL',
  fixedTerm = 'Término Fijo-COL',
  productiveStage = 'Aprendizaje Etapa Productiva-COL',
  lectiveStage = 'Aprendizaje Etapa Lectiva-COL',
}
export enum CatalogoConstratoPER {
  undefinedTerm = 'Término Indefinido Perú-PER',
  fixedTerm = 'Término Fijo-PER',
  internshipPre = 'Convenio Formativo Laboral Pre-PER',
  internshipPro = 'Convenio Formativo Laboral Pro-PER',
  undefinedTermPeru = 'Término Indefinido-PER',
}

export enum requiredMessage {
  requiredDocument = 'Este campo es obligatorio',
}

export enum requiredMessageDocs {
  requiredUpload = 'El documento es obligatorio.',
}
export const modalsTexts = {
  invalidPDF: [
    'El formato del archivo debe ser .pdf',
    'El tamaño máximo del archivo debe ser de 10MB',
  ],
  identificationDocument: [
    'Tu cédula debe estar ampliada al 150%.',
    'Ubica ambas caras en una sola hoja.',
    'El formato del archivo debe ser .pdf',
    'El tamaño máximo del archivo debe ser de 10MB',
  ],
  degreeCertificate: [
    'Agrega una copia del acta que acredita tu último grado de escolaridad. Si tienes más de un título afín a tu rol cargar un archivo para cada uno',
    'El formato del archivo debe ser .pdf',
    'El tamaño máximo del archivo debe ser de 10MB',
  ],
  presentationLetter: [
    'Agrega tu carta de presentación, esta debe ser emitida por tu institución educativa (SENA o Universidad) y debe estar debidamente firmada.',
    'El formato del archivo debe ser .pdf',
    'El tamaño máximo del archivo debe ser de 10MB',
  ],
  presentationLetterPeru: [
    'Agrega tu carta de presentación, esta debe ser emitida por tu institución educativa y debe estar debidamente firmada.',
    'El formato del archivo debe ser .pdf',
    'El tamaño máximo del archivo debe ser de 10MB',
  ],
  invalidSize: ['El tamaño máximo del archivo debe ser de 7MB'],
};

export enum regex {
  onlyNumbers = '^[0-9]+',
}
export const regexPatterns = {
  removePoints: /\./g,
  threeDigitsNotAtEnd: /(\d{3}(?!$))/g,
  replacementWithDot: '$1.',
};
export const PrimitiveTypeNumber = 'number';
export const descriptionLink = 'www.ruaf.sispro.gov.co';
export const link = 'ruaf.sispro.gov.co/Default.aspx';
export const descriptionText =
  'Si no conoces el estado de tus afiliaciones o no sabes a que entidades estas afiliado puedes ingresar a la página';
export const descriptionVerificar = ' y verificar.';

export enum panel {
  personalInformationTitle = 'Información personal',
  contactInformationTitle = 'Información de contacto',
  compesationInformationTitle = 'Información laboral y de compensación',
  organizationalInformationTitle = 'Información organizacional',
  academicInformationTitle = 'Información académica',
  secondNamePanelCompleted = 'Segundo nombre: ',
  firstNamePanelCompleted = 'Primer nombre: ',
  firstLastNamePanelCompleted = 'Primer apellido: ',
  secondLastNamePanelCompleted = 'Segundo apellido: ',
  typeDirectionPanel = 'Tipo de dirección: ',
  directionPanelCompleted = 'Dirección:',
  directionComplementedPanelCompleted = 'Complemento de dirección: ',
  contactNumberPanelCompleted = 'Número de contacto: ',
  emailPanelCompleted = 'correo personal: ',
  nominaPanelCompleted = 'Componente de nómina:',
  birthdatePanelCompleted = 'Fecha de nacimiento: ',
  genderPanelCompleted = 'Género: ',
  civilStatusPanelCompleted = 'Estado civil: ',
  documentTypePanelCompleted = 'Tipo de identificación: ',
  documentNumberPanelCompleted = 'Número de identificación: ',
  bloodGroupPanelCompleted = 'Grupo sanguíneo: ',
  preferredNamePanelCompleted = 'Nombre de preferencia: ',
  shirtSizePanelCompleted = 'Talla de camiseta: ',
  countryOfResidencePanelCompleted = 'País de residencia: ',
  departamentOfResidencePanelCompleted = 'Departamento de residencia: ',
  cityOfResidencePanelCompleted = 'Ciudad de residencia: ',
  contractTypePanelCompleted = 'Tipo de contrato: ',
  positionPanelCompleted = 'Posición: ',
  startDatePanelCompleted = 'Fecha de inicio: ',
  compensationInformationTitlePanelCompleted = 'Información de compensación: ',
  payrollComponentPanelCompleted = 'Componente de nómina: ',
  salaryBasePanelCompleted = 'Salario devengado: ',
  departmentPanelCompleted = 'Departamento: ',
  chapterOnePanelCompleted = 'Chapter 1: ',
  chapterTwoPanelCompleted = 'Chapter 2: ',
  technologyOnePanelCompleted = 'Tecnología 1: ',
  technologyTwoPanelCompleted = 'Tecnología 2: ',
  technicalLevelOnePanelCompleted = 'Nivel técnico 1: ',
  technicalLevelTwoPanelCompleted = 'Nivel técnico 2: ',
  referredByPanelCompleted = 'Referido por: ',
  professionalTitlePanelCompleted = 'Título profesional: ',
  professionalCardNumberPanelCompleted = 'Número de tarjeta profesional: ',
  cvPanelCompleted = 'Hoja de vida: ',
}

export const isTrue: boolean = true;
export const isFalse: boolean = false;
export enum times {
  time400 = 400,
  time2000 = 2000
}
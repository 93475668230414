import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  selectRestorePasswordForm,
  setConfirmationCodeTimer,
  setFormType,
  setRestorePasswordForm,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useRestorePasswordMutation } from 'services/talento';
import { IRestorePasswordForm } from 'utils/interfaces';
import { restorePasswordFormSchema } from 'utils/schemas';
import { AButton, AImage } from 'components/atoms';
import './RestorePasswordForm.scss';
import { errorIconParagraph } from 'utils/assets';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';


export const RestorePasswordForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const [restorePassword, { error, isSuccess }] = useRestorePasswordMutation();
  const { email } = useAppSelector(selectRestorePasswordForm);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid, dirtyFields },
  } = useForm<IRestorePasswordForm>({
    mode: 'onChange',
    defaultValues: { email },
    resolver: yupResolver(restorePasswordFormSchema),
  });

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) =>
    dispatch(setRestorePasswordForm({ name, value }));

  const onSubmit: SubmitHandler<IRestorePasswordForm> = async ({ email }) => {
    await restorePassword({ email });
  };

  const filterMessageUserNotFound = (message: string) => {
    let messageFilter = ''
    if (message.includes(t('userNotFound'))) {
      messageFilter = t('userNotFound')
      return messageFilter
    }
    else {
      return message
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setFormType('confirmationCode'));
      dispatch(setConfirmationCodeTimer(150000));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid, error]);

  return (
    <form className="restore-password-form" onSubmit={handleSubmit(onSubmit)}>
      <div className='input-text'>
        <input
          className={
            `input-text__element 
              ${errors?.email && 'input-text__element--error'} 
              ${error && 'input-text__element--error'} 
              ${isValid && dirtyFields?.email && error == undefined && 'input-text__element--isValid'}
              `}
          id="email"
          value={email}
          type="email"
          placeholder="Correo electrónico"
          autoComplete="off"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('email', { onChange: handleChange })}
        />


        {
          errors?.email &&
          <p className='error-form'>
            <AImage
              role='error'
              className='error-icon'
              url={errorIconParagraph}
              alt='close'
            />
            {errors.email.message}
          </p>
        }

        {
          error && 'data' in error &&
            <p className='error-form'>
              <AImage
                role='error'
                className='error-icon'
                url={errorIconParagraph}
                alt='close'
              />
              {filterMessageUserNotFound(error?.data.message)}
            </p>
        }
      </div>

      <div className="bttn__container">
        <AButton
          tag='button'
          type='submit'
          className='button-primary--white bttn'
          disabled={!isValid}
        >
          Enviar
        </AButton>
      </div>
      
      <p className="secondary-color text-align-center restore-password-form__remember">
        {t('rememberPassword')}
        <AButton
          tag='button'
          className='link__text restore-password-form__button-link'
          type='button'
          handleClick={() => navigate('/login')}
        >
          Iniciar sesión
        </AButton>
      </p>
    </form>
  );
};

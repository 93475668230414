import { AButton } from 'components/atoms'
import { PropsWithChildren } from 'react';
import './Tab.scss';


type _TabProps = {
  name?: string;
  to: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<any>>;
  isActive: boolean;
  children?: JSX.Element | JSX.Element[];
  classStyle?: string;
  disabled?: boolean;
}

export type TabProps = PropsWithChildren<_TabProps>

export const Tab = ({ name, to, setCurrentTab, isActive, children, classStyle, disabled }: TabProps) => (
  <>
    <AButton
      tag='button'
      type='button'
      handleClick={() => setCurrentTab(to)}
      className={`tab ${isActive && 'tab--active'}  ${disabled && 'tab--lock'} ${classStyle}`}
      dataTestId="buttonRedirect"
      disabled={disabled}
    >
      <p className={`tab__text ${isActive && 'tab__text--active'} ${disabled && 'tab__text--lock'} ${isActive && classStyle && 'tab__text--' + classStyle}`}>{(name) ? name : children}</p>
      <div className={`${isActive && 'tab--active-line'} ${disabled && 'tab--active-line--lock'} ${isActive && classStyle && 'tab--active-line--' + classStyle}`}></div>
    </AButton>


  </>
);

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectStages } from 'store';
import { useAppSelector } from 'store/hooks';
import { Back } from 'components/atoms/Back/Back.components';
import { AImage } from 'components/atoms';
import './ActivityHeader.scss';

interface IProps {
  idStage: number;
  scheduleDone: boolean;
}

export const ActivityHeader = ({ idStage, scheduleDone }: IProps) => {
  const navigate = useNavigate();
  const { stages } = useAppSelector(selectStages);
  const currentActivity = stages.find(stage => stage.id === idStage);
  const [{ activityName, activityIcon }, setData] = useState({ activityName: '', activityIcon: '' });

  // If idStage not found redirect to Home
  useEffect(() => {
    if (currentActivity) {
      const { name, icons } = currentActivity;
      const icon = icons.find(icon => icon.identifier === 'normal')?.urlIcon || '';
      setData({ activityName: name, activityIcon: icon });
    } else {
      navigate('/');
    }
  }, [currentActivity]);


  return (
    <section className="activityHeader">
      <div className="activityHeader__nav">
        <Back className="activityHeader__button" icon handleBack={() => { navigate(-1) }} />
        {
          scheduleDone ? <p className="activityHeader__nav__title">Confirmación entrevista</p>
          : <p className="activityHeader__nav__title">{activityName}</p>
        }
      </div>
      
      <div className="activityHeader__icon">
        <AImage
          url={activityIcon}
          className='activityHeader__icon--img'
          width={100}
          height={80}
          alt='icon Activity'
        />
      </div>
    </section>
  )
};

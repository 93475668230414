import { AButton } from 'components/atoms'
import { ContentNotFound } from 'components/atoms/ContentNotFound/ContentNotFound.components'
import { useWindowMobile } from 'hooks/useWindowMobile';
import './ErrorBoundarySide.scss'

interface Iprops{
    code?: string;
}
const ErrorBoundarySide = ({code}: Iprops) => {
    
    const { isMobile } = useWindowMobile(743);

    return (
        isMobile ?
            <section className='error-boundary--bg' data-testid="error-mobile">
                <div className='error-boundary-side__container'>

                    <div className='error-boundary-side__span-container'>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                        <span className='error-boundary-side__span'></span>
                    </div>

                    <ContentNotFound error={"Error"} code={code} />
                    <AButton className='button-primary--white error-boundary__button error-boundary-side__button' tag='a' url='/'>Recarga la página</AButton>
                </div>
            </section>
        :
            <div className='error-boundary-side__container' data-testid="error-desktop">

                <div className='error-boundary-side__span-container'>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                    <span className='error-boundary-side__span'></span>
                </div>

                <ContentNotFound error={"Error"} code={code} />
            <AButton className='button-primary--white error-boundary__button error-boundary-side__button' tag='a' url='/'>Recarga la página</AButton>
        </div>
    )
}

export default ErrorBoundarySide
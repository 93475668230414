import './Loader.scss';
import Lottie from 'lottie-react-web';
import { AnimationLoader, AnimationLoaderPurple } from 'utils/assets';

interface IProps{
  className?: string
  full?:boolean
  otherClassName?: string
}

export const Loader = ({ className, full, otherClassName} : IProps) => (
  <div className={`loader ${otherClassName}`} data-testid="container__loader">
    <div className={full ? 'loader__ellipsis loader__elilipsis__full' : 'loader__ellipsis'}>
      
      {className==="loader__purple" ?
        <Lottie 
          options={{
            animationData: AnimationLoaderPurple
          }}
        />
        :  
        <Lottie 
          options={{
            animationData: AnimationLoader
          }}
        />
        }
    </div>
  </div>
);

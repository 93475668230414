import './ProfileCircleCard.scss'

export const ProfileCirlceCard = ({ name, secondName, header = false }: any) => (

    <div className='profile-card'>
        <div className={`profile-card__circle ${header ? "profile-card__header" : ""}`}
            data-testid="profile">
            {name?.toUpperCase()}{secondName?.toUpperCase()}
            
        </div>
    </div>
)

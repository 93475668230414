import styles from './AiconNotification.module.scss'
import { ReactComponent as Bell } from 'utils/assets/images/Bell.svg';
import {states} from 'utils/constants/Notifications/indexConstants'
type NotificationState = states.default | states.received | states.read | states.completed | states.rejected;
interface IconWithStatesProps  {
    currentState: NotificationState;
    onClick: () => void;
};
export const AiconNotification = ({ currentState, onClick }:IconWithStatesProps) => {
    let iconClass = styles.defaultIcon; 

    if (currentState === states.received) {
      iconClass = styles.receivedIcon; 
    } else if (currentState === states.read) {
      iconClass = styles.readIcon; 
    }
  
    return (
      <button role='button' className={`${styles.iconNotification} ${iconClass}`} onClick={onClick}> 
        {currentState === states.received && (
          <div className={styles.animation}></div>
        )}
        <Bell 
            className={`${styles.bellFill}`}
        />
      </button>
    );
};
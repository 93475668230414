import { PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { upperCaseFirstchart } from 'utils/helpers/upperCaseFirstChart';
import { ILinkAlert } from 'utils/interfaces';
import { AImage } from '../AImage/AImage.components';
import './TextIcon.scss';
import { selectCurrentActivity, selectStages } from '../../../store/user/user.slice';

type _TextIcon = {
  date?: string;
  time?: string;
  interviewer?: string;
  candidate?: string;
  name?: string;
}

export type TextIconProps = PropsWithChildren<_TextIcon>

type _TextProps = {
  alt?: string;
  tag: string;
  text: TextIconProps[] | any;
  urlIcon: string;
  title?: string;
  textBold?: string;
  className?: string;
  link?: ILinkAlert;
}

interface slice {
  text?: string;
  isBold?: boolean;
  isLink?: boolean;
  linkTitle?: string;
  linkUrl?: string;
}

type Islice = [
  slice,
  slice,
  slice
]

export type TextProps = PropsWithChildren<_TextProps>

export const TextIcon = ({ alt = '', tag, text, urlIcon, title, textBold, className, link }: TextProps) => {

  const [slices, setSlices] = useState<Islice>()
  const [linkCurrentActivity, setLinkCurrentActivity] = useState('')

  const { idActivity: idCurrentActivity } = useAppSelector(selectCurrentActivity)
  const { stages } = useAppSelector(selectStages)

  useEffect(() => {
    const linkCurrentActivity = stages.find(({ id }) => id.toString() === idCurrentActivity)?.url;
    setLinkCurrentActivity(linkCurrentActivity || '')
  }, [idCurrentActivity, stages])


  useEffect(() => {

    if (text[0]?.name) {
      const isSlice = text[0]?.name.includes('$1')
      const isSliceLink = text[0]?.name.includes('$2')

      if (isSlice) {

        const splitText = text[0]?.name.split('$1')

        const slice: Islice = [
          { text: splitText[0] },
          { text: textBold, isBold: true },
          { text: splitText[1] },
        ]

        setSlices(slice)
      } else if (isSliceLink) {
        const splitText = text[0]?.name.split('$2')

        const slice: Islice = [
          { text: splitText[0] },
          { linkTitle: link?.title, linkUrl: link?.url, isLink: true },
          { text: splitText[1] },
        ]

        setSlices(slice)
      }

    }

  }, []);

  return (
    <div className={`textIcon textIcon--${tag}`} data-testid='textIcon'>
      <AImage
        alt={alt}
        className={`textIcon__img textIcon__img--mobile ${className && className}`}
        height={14}
        url={urlIcon}
        width={14}
      />
      {
        tag === 'link' && <Link data-testid="link" className='textIcon__link' target="_blank" to={text[0]?.name || ''}>{text[0]?.name}</Link>
      }
      {
        tag === 'text' &&
        <div className={`textIcon__container__text`}>
          {
            text && text[0]?.date && text[0]?.time &&
            <>
              <p key={text[0]?.date} className="textIcon__text">{upperCaseFirstchart(text[0]?.date.replace('#', '').replace(',', ''))}</p>
              <p key={text[0]?.time} className="textIcon__text">{text[0]?.time.replace('#', '')} - <span className='textIcon__text-bolder'>Hora Colombia</span></p>
            </>
          }
          {
            text && text[0]?.interviewer && text[0]?.candidate &&
            <>
              <p className="textIcon__text" data-testid="text"> Invitados</p>
              <p key={text[0]?.interviewer} className="textIcon__text">{text[0]?.interviewer} - Profesional TH</p>
              <p key={text[0]?.candidate} className="textIcon__text">{text[0]?.candidate} - Candidato</p>
            </>
          }
          {
            text && text[0]?.name &&
            <p key={text[0]?.name} className="textIcon__text">{text[0]?.name}</p>
          }
        </div>
      }
      {(tag == "info" || tag == "warning") && title && (textBold || link) &&
        <div className={`textIcon__container__text`} data-testid="container__text">
          <p className={`textIcon__title ${tag == "info" && 'textIcon__title--white'}`}>{title}</p>
          {
            text && text[0]?.name && !(slices !== undefined) ?
              <div>
                <p key={text[0]?.name} className={`textIcon__text ${tag == "info" ? 'textIcon__info--white' : 'textIcon__info'}`}>
                  {text[0]?.name}
                  <span key={textBold} className={`textIcon__text--bold textIcon__info ${tag == "info" && 'textIcon__info--white'}`}>
                    {textBold}
                  </span>
                </p>

              </div>

              : <div>
                {slices && slices.map(({ text, isBold, isLink, linkTitle, linkUrl = '' }, index) => {
                  if (isBold) {
                    return (
                      <span
                        key={index}
                        className={`textIcon__text--bold textIcon__info ${tag == "info" && 'textIcon__info--white'}`}>
                        {text}
                      </span>
                    )
                  } else if (isLink) {
                    return (
                      <a key={index} className='textIcon__text textIcon__text--link' href={linkCurrentActivity ? linkCurrentActivity : linkUrl} rel='noreferrer noopener' target='_blank'>{linkTitle}</a>
                    )
                  } else {
                    return (
                      <p
                        key={index}
                        className={`textIcon__text ${tag == "info" ? 'textIcon__info--white' : 'textIcon__info'}`}>
                        {text}
                      </p>
                    )
                  }
                })}
              </div>
          }
        </div>
      }
    </div>
  )
}

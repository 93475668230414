import { Dispatch, useEffect, useState } from 'react';
import { Label } from 'components/atoms';
import './UploadImage.scss';
import { IFiles } from 'utils/interfaces';
import { iconClick } from 'utils/assets';
import AInputImage from 'components/atoms/AInputImage/AInputImage.component';

interface IProps {
  name: string;
  setModal: (state: IModal) => void;
  disabled?: boolean;
  secondary?: boolean;
  setFiles?: (state: any[]) => void;
  files?: IFiles[];
  keyInputs?: number;
  url?: string;
  onDelete?: IFiles[];
}

interface IModal {
  message: string;
  type: 'success' | 'error' | 'inProgress';
  isOpen: boolean;
}

export const UploadImage = ({
  disabled,
  name,
  setModal,
  secondary,
  setFiles,
  files,
  keyInputs = 0,
  onDelete,
}: IProps) => {
  const acceptedTypes = 'image/jpg, image/jpeg, image/png';
  return (
    <div className="upload-container">
      <Label
        icon={true}
        url={iconClick}
        text={name}
        htmlFor="upload_file"
        secondary={secondary}
        className={`label-upload ${
          !secondary ? 'label-upload--primary' : 'label-upload--secondary'
        } ${
          disabled && !secondary
            ? 'label-upload--primary--disabled'
            : disabled && secondary && 'label-upload--secondary--disabled'
        }`}
      />

      {
        <AInputImage
          setModal={setModal}
          disabled={disabled}
          setFiles={setFiles}
          files={files}
          accept={acceptedTypes}
          keyInputs={keyInputs}
          onDelete={onDelete}
        />
      }
    </div>
  );
};

export default UploadImage;

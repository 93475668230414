
import { selectFormType } from 'store/authentication/authentication.slice';
import { useAppSelector } from 'store/hooks';
import {
  RestorePasswordPage,
  ConfirmationCodePage,
  ConfirmNewPasswordPage,
} from 'pages/Authentication';
import './AuthenticationPage.scss';

export const AuthenticationPage: React.FC = () => {
  const formType = useAppSelector(selectFormType);

  return (
    <div className="background-color-cuaternary min-height-with-navbar">
      {formType === 'restorePassword' && <RestorePasswordPage />}
      {formType === 'confirmationCode' && <ConfirmationCodePage />}
      {formType === 'confirmNewPassword' && <ConfirmNewPasswordPage tittle='Restablecer mi contraseña' isFirstPassword={false}/>}
    </div>
  );
};
import { useState } from 'react';

const DEFAULT_TEXT = 'Loading...';
export const useTranslation = (translations: object | any, isLoading: boolean) => {
    const [isError, setIsError] = useState(false);

    const replaceDynamicVariables = (value: string, args: object | any): string => {
        let retorno = value;
        // (retorno.match(/\$[1-9]/g))?.length  validacion extra
        for (let index = 1; index <= Object.keys(args).length; index += 1) {
            retorno = retorno.replace(`$${index}`, args[`$${index}`]);
        }
        return retorno
    }

    const searchTextById = (idText: string, options?: object): string => {
        const isFoundText = Object.keys(translations).some((id) => id === idText);
        if (!isFoundText) {
            setIsError(true)
            return 'Error'
        }

        return (options ? replaceDynamicVariables(translations[`${idText}`], options) : translations[`${idText}`])
    }

    const t = (idText: string, options?: object) => {
        if (isLoading) return DEFAULT_TEXT;
        return searchTextById(idText, options)
    }

    return { t, isError }
}
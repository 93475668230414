import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useGetGendersQuery } from 'services/sap';
import { catalogoPersonal, catalogoLenguage } from 'utils/helpers/dictionaries';

interface CitySelectProps extends Omit<SelectProps, 'options'> {
  department: string;
  onExternalCodeChange?: (externalCode: string) => void;
}

export default function CitySelect({
  department,
  onExternalCodeChange,
  ...rest
}: Readonly<CitySelectProps>) {
  const { data } = useGetGendersQuery<{
    data: { externalCode: string; label_es_ES: string }[];
  }>({
    catalogName: catalogoPersonal.MUNICIPALITY_COL,
    languages: catalogoLenguage.ESyUSyISOyId,
    father: department,
  });

  const options = data?.map((city) => ({
    value: city.label_es_ES,
    label: city.label_es_ES,
  })) ?? [{ label: 'cargando', value: 'cargando' }];

  useEffect(() => {
    const { value } = rest;

    if (!value || !onExternalCodeChange) return;

    const { label } = value;

    const selected = data?.find((city) => city.label_es_ES === label);

    if (onExternalCodeChange)
      onExternalCodeChange(selected?.externalCode ?? '');
  }, [data, rest.value, onExternalCodeChange]);

  return (
    <Select
      options={options}
      {...rest}
      handleChange={(value) => {
        if (onExternalCodeChange) {
          const city = data?.find((country) => country.label_es_ES === value);
          onExternalCodeChange(city?.externalCode ?? '');
        }
        rest.handleChange(value);
      }}
    />
  );
}

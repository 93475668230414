import { useState, useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectVacantInfo } from 'store';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { REMOVE_WORDS_REGEX, EXTRA_SPACES_REGEX } from 'utils/constants/generalsConstans';
import { Numbers } from 'utils/helpers/dictionaries';

const useGetName = () => {
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const { vacant, isLoadingVacant } = useAppSelector(selectVacantInfo);
  
  const [fullName, setFullName] = useState('');
  const [vacantName, setVacantName] = useState('');
  const [firstLetterName, setFirstLetterName] = useState('');
  const [firstLetterSecondName, setFirstLetterSecondName] = useState('');

  useEffect(() => {
    if (!isLoadingVacant && vacant) {
      const { fullName, vacant: nameVacant } = vacant || {};
      setFullName(fullName);
      setVacantName(nameVacant);
      const auxName = fullName?.replace(REMOVE_WORDS_REGEX, ' ');
      const splitedName = auxName?.replace(EXTRA_SPACES_REGEX, ' ').trim();
      const splitedNameArray = splitedName ? splitedName?.split(' ') : [];
      const firstName = splitedNameArray.length > Numbers.zero 
      ? splitedNameArray[Numbers.zero].charAt(Numbers.zero).toUpperCase() + splitedNameArray[Numbers.zero].slice(Numbers.one)
      : '';
      setFirstLetterName(firstName.charAt(Numbers.zero).toUpperCase());
      const secondNameLetter = splitedNameArray.length > Numbers.one
        ? splitedNameArray[splitedNameArray.length - Numbers.one].charAt(Numbers.zero).toUpperCase()
        : '';
      setFirstLetterSecondName(secondNameLetter);
    }
  }, [vacant, isLoadingVacant]);

  return {
    t,
    fullName,
    vacantName,
    isLoadingVacant,
    firstLetterName,
    firstLetterSecondName
  };
};

export default useGetName;

import { Fragment } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { IStage } from 'utils/interfaces/store/user.interfaces';
import TempElement from 'components/molecules/TempElement/TempElement';
import { StagesRender } from '../StagesRender/StagesRender.component';
import { useTranslation } from 'hooks/useTranslation';
import './StagesActives.scss';

interface IProps {
  activeStages: IStage[];
  currentStage: number;
  disabled?: boolean;
};

export const StagesActives = ({ activeStages, currentStage, disabled }: IProps) => {
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);

  const stageActives = activeStages.filter(activeStage => activeStage.stage === currentStage && activeStage.status !== '5');
  const stagesNext = activeStages.filter(activeStage => activeStage.stage > currentStage || (activeStage.stage === currentStage && activeStage.status === '5'));
  let renderStages = [
    { id: 'activeStages', stages: stageActives },
    { id: 'nextStages', stages: stagesNext },
  ];

  return (
    <div>
      {
        stageActives.length === 0 && stagesNext.length === 0 ? <TempElement /> :
          renderStages.map(({ stages, id }) => (
            <Fragment key={id}>
              {stages.length !== 0 &&
                <div className='stagesActives'>
                  <h2 className={`stagesActives__title ${disabled && 'stagesActives__title--lock'}`}>{t(id ? id : '')}</h2>
                  <StagesRender stages={stages} />
                </div>
              }
            </Fragment>
          ))
      }
    </div>
  );
}

import { Dispatch } from 'react';
import { AInputFile, Label } from 'components/atoms'
import './UploadFile.scss'
import { useAppSelector } from 'store/hooks';
import { selectCurrentActivity } from 'store';
import { IFiles } from 'utils/interfaces';
import { iconClick } from 'utils/assets';
import { activities } from 'utils/helpers';
interface IProps {
    name: string;
    setModal: (state: IModal) => void;
    disabled?: boolean;
    secondary?: boolean;
    setFiles?: (state: any[]) => void;
    files?: IFiles[];
    keyInputs?: number;
    url?: string;
}

interface IModal {
    message: string;
    type: 'success' | 'error' | 'inProgress';
    isOpen: boolean;
}

export const UploadFile = ({ disabled, name, setModal, secondary, setFiles, files, keyInputs = 0 }: IProps) => {

    const { idActivity } = useAppSelector(selectCurrentActivity);
    let acceptedTypes: string = ''

    if (idActivity === activities.essay) {
        acceptedTypes = 'application/pdf';
    } else {
        acceptedTypes = 'application/x-zip-compressed, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint'
    }

    return (
        <div className='upload-container'>

            <Label
                icon={idActivity === activities.approachTheRol ? true : false}
                url={iconClick}
                text={name}
                htmlFor='upload_file'
                secondary={secondary}
                className={`label-upload ${!secondary ? 'label-upload--primary' : 'label-upload--secondary'} ${(disabled && !secondary) ? 'label-upload--primary--disabled' : (disabled && secondary) && 'label-upload--secondary--disabled'}`}
            />

            {
                <AInputFile
                    setModal={setModal}
                    disabled={disabled}
                    setFiles={setFiles}
                    files={files}
                    accept={acceptedTypes}
                    keyInputs={keyInputs}
                />
            }
        </div>
    )
}

export default UploadFile
export interface ILabelTab {
  label: string;
  amount?: number;
}
export type PageNumber = number | string;

export const pendingQuery: number = 0;

export const completedQuery: number = 1;

export const one: number = 1;

export const tablePropIdentifier: string = 'email';

export const firsTableCols: number = 3;

export const pendingText: string = 'Pendientes';

export const completedText: string = 'Completados';

export const minLengthFilter: number = 3;

export const minPageCount: number = 2;

export const divider: string = '...';

export const labelTabs: ILabelTab[] = [
  { label: 'Pendientes' },
  { label: 'Completados' },
];

export const pageSizes = [
  { label: '10 por página', value: 10 },
  { label: '25 por página', value: 25 },
  { label: '50 por página', value: 50 },
];

export const headersPending = [
  { label: 'Pragmático', prop: 'fullName' },
  { label: 'Fecha de ingreso', prop: 'dateStart' },
  { label: 'Rol', prop: 'role' },
];

export const headersCompleted = [
  { label: 'Pragmático', prop: 'fullName' },
  { label: 'Fecha de ingreso', prop: 'dateStart' },
  { label: 'Rol', prop: 'role' },
  { label: 'Fecha de cierre proceso', prop: 'closeDate' },
  { label: 'Cerrado por', prop: 'qualifierName' },
];

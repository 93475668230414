import React from 'react'
import './AlertToast.scss'
import { AImage } from '../../atoms';
import { checkToast } from 'utils/assets';
import { useEffect } from 'react';

interface IProps {
    isOpen: boolean;
    text: string;
    modifier?: string;
}

export const AlertToast = ({ isOpen, text, modifier}:IProps) => {

    useEffect(() => {
        let toast = document.getElementById('toast');
        if(isOpen){
            modifier ?
                toast?.classList.replace('alert-toast__toast', `alert-toast__toast--show-${modifier}`)
            :
            toast?.classList.replace('alert-toast__toast', 'alert-toast__toast--show')
        }else{
            modifier ? 
                toast?.classList.replace(`alert-toast__toast--show-${modifier}`, 'alert-toast__toast')
            :
            toast?.classList.replace('alert-toast__toast--show', 'alert-toast__toast')
        }        
    }, [isOpen]);

    return (
        <div className={`alert-toast__wrapper ${modifier && 'alert-toast__wrapper--'+modifier}`} data-testid="wrapper-test">
            <div className={`alert-toast__toast ${modifier && 'alert-toast__toast--'+modifier}`} id='toast' data-testid="toast-test">
                <AImage url={checkToast} alt='checkToast' className='alert-toast__icon'/>
                <p className={`alert-toast__text ${modifier && 'alert-toast__text--'+modifier}`} data-testid="text-test">{text}</p>
            </div>
        </div>
    )
}
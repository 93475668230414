import { Auth } from 'aws-amplify';
import { logout as logoutCleanState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TabbarItem, AImage, AButton } from 'components/atoms';
import { userIcon, logoutIcon } from 'utils/assets';
import './Tabbar.scss';
import { useWindowMobile } from 'hooks/useWindowMobile';
import { TabbarItemPath, tabbarItems } from 'utils/constants';
import React from 'react';

interface IProps {
  hiddenItems?: TabbarItemPath[];
}

export const Tabbar = ({ hiddenItems }: IProps) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowMobile(1279);

  const handleClick = async () => {
    await Auth.signOut();
    dispatch(logoutCleanState());
  };

  const { isActive } = useAppSelector((state) => state.user.user);

  return (
    <>
      {!isMobile && (
        <nav className="tabbar">
          <ul className="tabbar__list">
            {tabbarItems.map(({ title, path, icon, isDisabled }) => {
              if (hiddenItems && hiddenItems.includes(path))
                return <React.Fragment key={path}></React.Fragment>;

              return (
                <li key={path}>
                  <TabbarItem
                    path={path}
                    title={title}
                    icon={icon}
                    isDisabled={
                      !isActive && path === tabbarItems[2].path
                        ? true
                        : isDisabled
                    }
                  />
                </li>
              );
            })}
          </ul>
          <div className="tabbar__logout">
            <div className="tabbar__imageUserBox">
              <AImage className="tabbar__imageUser" url={userIcon} alt="" />
            </div>
            <div className="tabbar__logoutBox">
              <AImage className="tabbar__imageLogout" url={logoutIcon} alt="" />
              <AButton
                dataTestId="logout-button"
                tag="button"
                className="tabbar__buttonLogout"
                handleClick={handleClick}
              >
                Cerrar sesión
              </AButton>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

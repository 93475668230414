import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AButton,
  Accordion,
  AImage,
  ACalendar,
  Input,
  Loader,
  Select,
  Label,
} from 'components/atoms';
import { ModalAlert } from 'components/molecules';
import { ChangePasswordForm } from 'components/organisms';
import { selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { useGetProfileDataQuery } from 'services/talento';
import {
  useGetCountriesQuery,
  useGetDepartmentsByCountryQuery,
  useGetCitiesByDepartmentQuery,
} from 'services/general';
import { useUpdateProfileDataMutation } from 'services/talento';
import { getProfileFormSchema } from 'utils/schemas';
import { errorIconParagraphDark, padlock } from 'utils/assets';
import {
  IResponseProfile,
  IResponseCountries,
  IResponseDepartments,
  IResponseCities,
  IProfileForm,
  ICountry,
  IDepartment,
  IModal,
  ICity,
} from 'utils/interfaces';
import './ProfileForm.scss';
import { ProfileCirlceCard } from 'components/molecules/ProfileCircleCard/ProfileCircleCard.components';
import { useTranslation } from 'hooks/useTranslation';
import { selectTranslations } from 'store/translation/translation.slice';
import moment from 'moment';
import { formatCountryOrState, prepareDataCountries } from 'utils/helpers';
import { RHList, bloodTypeList, identificationTypeList } from 'utils/constants';

interface IProps {
  isDisabled: boolean;
  changeState: () => void;
  closeSesion: () => void;
  setMarginForCalendar: (state: number) => void;
}

const INITIAL_DATA = {
  email: '',
  phoneNumber: '',
  city: undefined,
  department: undefined,
  country: undefined,
  phoneCode: undefined,
  idPhone: undefined,
  direction: '',
  bloodType: '',
  rh: '',
  idType: '',
  numberId: '',
  bornDate: '',
};

export const PerfilForm = ({
  isDisabled,
  changeState,
  closeSesion,
  setMarginForCalendar,
}: IProps) => {
  type formTypes =
    | 'email'
    | 'phoneNumber'
    | 'country'
    | 'department'
    | 'city'
    | 'phoneCode'
    | 'idPhone'
    | 'direction'
    | 'bloodType'
    | 'rh'
    | 'idType'
    | 'numberId'
    | 'bornDate';

  const [localDepartments, setLocalDepartments] = useState<IDepartment[]>([]);
  const [localCities, setLocalCities] = useState<ICity[]>([]);
  const [showPassForm, setShowPassForm] = useState<boolean>(false);
  const [isOriginData, setIsOriginData] = useState<boolean>(false);
  const [showModalError, setShowModalError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const [modal, setModal] = useState<IModal>({
    message: '',
    type: 'success',
    isOpen: false,
    handleClick: () => {},
  });
  const [hasGritInformation, setHasGritInformation] = useState<boolean>(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const [formValues, setFormValues] = useState<IProfileForm>(INITIAL_DATA);
  const userInfo = useAppSelector(selectUserInfo);
  const { email } = userInfo;
  const { vacant } = useAppSelector(selectVacantInfo);
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const profileFormSchema = getProfileFormSchema(hasGritInformation);

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    refetch: refetchProfile,
    isFetching: isFetchingProfile,
  } = useGetProfileDataQuery<IResponseProfile>({ email });

  const { data: countries, isLoading: isLoadingCountries } =
    useGetCountriesQuery<IResponseCountries>();

  const { data: departments } =
    useGetDepartmentsByCountryQuery<IResponseDepartments>(formValues.country, {
      skip: !formValues.country,
    });

  const { data: cities } = useGetCitiesByDepartmentQuery<IResponseCities>(
    formValues.department,
    { skip: !formValues.department }
  );

  const [
    updateProfileData,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useUpdateProfileDataMutation();

  const cancelProcess = () => {
    clearErrors();
    changeState();
    setFormValues(formValues);
    const currentCountry = countries?.find(
      (country) => country.name === profileData.country
    );
    if (departments) {
      setLocalDepartments(
        currentCountry?.paisId !== formValues.country ? [] : [...departments]
      );
    }
    setIsOpenCalendar(false);
  };

  const changeData = async ({
    name,
    value,
  }: {
    name: formTypes;
    value?: number | string;
  }) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValue(name, value, { shouldValidate: true });
  };

  const formatCity = (str: string) => {
    let arrayToFill: string[] = [];
    const wordToArray = str.split(' ');
    wordToArray.forEach((item) => {
      const initialItem = item.substring(0, 1);
      const restItem = item.substring(1);
      let itemFormat = item.replace(initialItem, initialItem.toUpperCase());
      itemFormat = itemFormat.replace(restItem, restItem.toLowerCase());
      arrayToFill.push(itemFormat);
    });

    let wordFormat = arrayToFill.toString();
    wordFormat = wordFormat.replaceAll(',', ' ');

    return wordFormat;
  };

  const fillDefaultData = () => {
    if (profileData) {
      !formValues.email && changeData({ name: 'email', value: email });

      let celPhone = profileData.phoneNumber?.split(
        `+${profileData.phoneCode}`
      )[1];

      !formValues.phoneNumber &&
        changeData({
          name: 'phoneNumber',
          value: celPhone === undefined ? profileData.phoneNumber : celPhone,
        });
      !formValues.phoneCode &&
        changeData({ name: 'phoneCode', value: profileData.phoneCode });

      if (countries && !formValues.country) {
        const currentCountry = countries?.find(
          (country) =>
            country.name === formatCountryOrState(profileData.country!)
        );
        changeData({ name: 'country', value: currentCountry?.paisId });
      }

      if (!formValues.idPhone) {
        const currentPhoneCode = countries?.find(
          (country) => country.phonecode === parseInt(profileData.phoneCode!)
        );
        changeData({ name: 'idPhone', value: currentPhoneCode?.paisId });
      }

      const currentDeparment = localDepartments?.find(
        (department) =>
          department.name === formatCountryOrState(profileData.state!)
      );
      changeData({ name: 'department', value: currentDeparment?.id });

      const currentCity = localCities?.find(
        (city) => city.name === profileData.city!
      );
      changeData({ name: 'city', value: currentCity?.id });

      !formValues.direction &&
        changeData({ name: 'direction', value: profileData.direction });

      !formValues.bloodType &&
        changeData({ name: 'bloodType', value: profileData.bloodType });

      !formValues.rh && changeData({ name: 'rh', value: profileData.rh });

      !formValues.idType &&
        changeData({ name: 'idType', value: profileData.idType });

      !formValues.numberId &&
        changeData({ name: 'numberId', value: profileData.numberId });

      !formValues.bornDate &&
        changeData({ name: 'bornDate', value: profileData.bornDate });
    }
  };

  const saveData = () => {
    const {
      email,
      phoneNumber,
      country,
      department,
      city,
      idPhone,
      direction,
      bloodType,
      rh,
      idType,
      numberId,
      bornDate,
    } = getValues();

    const finalData = {
      email,
      phoneNumber,
      phoneCode: `+${
        countries.find((opt) => opt.paisId === idPhone)?.phonecode
      }`,
      country: countries.find((opt) => opt.paisId === country)?.name || '',
      state: departments.find((opt) => opt.id === department)?.name || '',
      city: cities.find((opt) => opt.id === city)?.name || '',
      direction,
      bloodType,
      rh,
      idType,
      numberId,
      bornDate,
    };
    updateProfileData(finalData);
    setModal({ message: '¡Cambios guardados!', type: 'success', isOpen: true });
  };

  const checkOriginData = () => {
    const {
      phoneNumber,
      country,
      department,
      city,
      idPhone,
      direction,
      bloodType,
      rh,
      idType,
      numberId,
      bornDate,
    } = getValues();

    const validPhone = profileData?.phoneNumber === `${phoneNumber}`;
    const currentCountry = countries?.find((opt) => opt.paisId == country);
    const validCountry = profileData?.country === currentCountry?.name;
    const currentDepartment = departments?.find((opt) => opt.id == department);
    const validDepartment = profileData?.state === currentDepartment?.name;
    const currentCity = cities?.find((opt) => opt.id == city);
    const validCity = profileData?.city === currentCity?.name;
    const validPhoneCode = profileData?.phoneCode === `${idPhone}`;
    const validDirection = profileData?.direction === direction;
    const validBloodType = profileData?.bloodType === bloodType;
    const validRh = profileData?.rh === rh;
    const validIdType = profileData?.idType === idType;
    const validNumberId = profileData?.numberId === numberId;
    const validBornDate = profileData?.bornDate === bornDate;
    setIsOriginData(
      validPhone &&
        validCountry &&
        validDepartment &&
        validCity &&
        validPhoneCode &&
        validDirection &&
        validBloodType &&
        validRh &&
        validIdType &&
        validNumberId &&
        validBornDate
    );
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
    clearErrors,
  } = useForm<IProfileForm>({
    mode: 'onChange',
    resolver: yupResolver(profileFormSchema),
  });

  useEffect(() => {
    if (!profileData) return;

    const { bloodType, bornDate, direction, idType, numberId, rh } =
      profileData;
    const HAS_GRIT_INFORMATION =
      Boolean(bloodType) &&
      Boolean(bornDate) &&
      Boolean(direction) &&
      Boolean(idType) &&
      Boolean(numberId) &&
      Boolean(rh);

    setHasGritInformation(HAS_GRIT_INFORMATION);
  }, [profileData]);

  useEffect(() => {
    checkOriginData();
  }, [formValues]);

  useEffect(() => {
    departments && setLocalDepartments(departments);
  }, [departments]);

  useEffect(() => {
    cities && setLocalCities(cities);
  }, [cities]);

  useEffect(() => {
    if (!isSelected) {
      fillDefaultData();
    }
  }, [profileData, countries, localDepartments, localCities]);

  useEffect(() => {
    if (!isLoadingUpdate && isSuccessUpdate) {
      changeState();
      refetchProfile();
    }
    if (errorUpdate) {
      setMessageError(
        'Ha ocurrido un error inesperado en la actualización del perfil.'
      );
      setShowModalError(true);
    }
  }, [isLoadingUpdate, isSuccessUpdate, errorUpdate]);

  useEffect(() => {
    !showModalError && setMessageError('');
  }, [showModalError]);

  const regex =
    /(\bde\b|\blos\b|\blas\b|\bdel\b)/gi; /* Filtra las palabras que no deseamos en los nombres compuestos */
  const auxName = vacant?.fullName.replace(regex, ' ');
  const splitedName = auxName?.replace(/\s{2,}/g, ' ');
  const splitedNameArray = splitedName?.split(' ');
  let firstLetterName: string = splitedNameArray?.[0]?.[0];
  let firstLetterSecondName: string;
  if (splitedNameArray?.length <= 2) {
    firstLetterSecondName =
      splitedNameArray?.[splitedNameArray.length - 1]?.[0];
  } else {
    firstLetterSecondName =
      splitedNameArray?.[splitedNameArray.length - 2]?.[0];
  }

  if (
    isLoadingProfile ||
    isLoadingCountries ||
    isLoadingUpdate ||
    isFetchingProfile
  ) {
    return (
      <div className="container-loader" data-testid="loader">
        <Loader className="loader__purple" />
      </div>
    );
  }

  return (
    <div className="container-form" data-testid="form">
      <ModalAlert
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        confirmButton={true}
        toggleModal={(isOpen: boolean) =>
          setModal((state) => ({ ...state, isOpen }))
        }
        handleClick={modal.handleClick}
      />
      <ProfileCirlceCard
        name={firstLetterName}
        secondName={firstLetterSecondName}
      />
      <AButton
        className={`anchor center ${!isDisabled ? 'disabled' : ''}`}
        tag="a"
        handleClick={changeState}
        disabled={!isDisabled}
      >
        Editar
      </AButton>
      <form className="form" onSubmit={handleSubmit(saveData)}>
        <section className="section-form">
          <aside className="section-form__aside">
            <Input
              id="email"
              label="E-mail"
              type="email"
              placeholder="usuario@gmail.com"
              value={formValues.email}
              disabled
              register={register}
            />
            <div className="section-form__aside--doble-side">
              <div className="section-form__aside--doble-side-A">
                <Select
                  selected={formValues.idPhone}
                  textLabel={'Celular'}
                  htmlForLabel={'phoneCode'}
                  disabled={isDisabled || !countries}
                  options={countries && prepareDataCountries(countries, true)}
                  handleChange={(value) => {
                    changeData({ name: 'idPhone', value });
                  }}
                  placeholder={'Selecciona ...'}
                  error={!!errors.idPhone}
                  indicative
                />
              </div>

              <div className="section-form__aside--doble-side-B">
                <Input
                  id="phoneNumber"
                  label=""
                  placeholder=""
                  type="tel"
                  value={formValues.phoneNumber}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    changeData({ name: 'phoneNumber', value });
                  }}
                  disabled={isDisabled}
                  register={register}
                  error={!!errors?.phoneNumber}
                />
              </div>
            </div>
          </aside>

          <aside className="section-form__aside">
            <article className="section-form__article">
              <Select
                htmlForLabel={'country'}
                selected={formValues.country}
                disabled={isDisabled || !countries}
                handleChange={(value) => {
                  changeData({ name: 'country', value });
                  changeData({ name: 'department', value: '' });
                  changeData({ name: 'city', value: '' });
                }}
                options={countries && prepareDataCountries(countries)}
                textLabel={'País de residencia'}
                placeholder={'Selecciona ...'}
                error={!!errors.country}
              />
              {!!errors?.country && (
                <p
                  className={`${
                    isDisabled || !countries
                      ? 'section-form__error-paragraph--disabled'
                      : 'section-form__error-paragraph'
                  }`}
                >
                  <AImage
                    className="section-form__error-icon"
                    url={errorIconParagraphDark}
                    alt="errorIconParagraph"
                  />
                  {t('invalidCountry')}
                </p>
              )}
            </article>
            <article className="section-form__article">
              <Select
                htmlForLabel={'department'}
                selected={formValues.department}
                disabled={isDisabled || !departments || !formValues.country}
                handleChange={(value) => {
                  setIsSelected(true);
                  changeData({ name: 'department', value });
                  changeData({ name: 'city', value: '' });
                }}
                options={departments?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                textLabel={'Departamento de residencia'}
                placeholder={'Selecciona ...'}
                error={!!errors.department}
              />
              {!!errors?.department && (
                <p
                  className={`${
                    isDisabled || !departments || !formValues.country
                      ? 'section-form__error-paragraph--disabled'
                      : 'section-form__error-paragraph'
                  }`}
                >
                  <AImage
                    className="section-form__error-icon"
                    url={errorIconParagraphDark}
                    alt="errorIconParagraph"
                  />
                  {t('invalidState')}
                </p>
              )}
            </article>
          </aside>

          <aside className="section-form__aside__column">
            <Select
              htmlForLabel={'city'}
              selected={formValues.city}
              disabled={isDisabled || !cities || !formValues.department}
              handleChange={(value) => {
                changeData({ name: 'city', value });
              }}
              options={cities?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              textLabel={'Ciudad de residencia'}
              placeholder={'Selecciona...'}
              error={!!errors.city}
            />
            {!!errors?.city && (
              <p
                className={`${
                  isDisabled || !cities || !formValues.department
                    ? 'section-form__error-paragraph--disabled'
                    : 'section-form__error-paragraph'
                }`}
              >
                <AImage
                  className="section-form__error-icon"
                  url={errorIconParagraphDark}
                  alt="errorIconParagraph"
                />
                {t('invalidCity')}
              </p>
            )}
          </aside>
          {hasGritInformation && (
            <>
              <aside className="section-form__aside__column">
                <Input
                  id="direction"
                  label="Dirección de residencia"
                  placeholder=""
                  value={formValues.direction}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    changeData({ name: 'direction', value });
                  }}
                  disabled={isDisabled}
                  register={register}
                  error={!!errors?.direction}
                />
              </aside>

              <aside className="section-form__aside">
                <div className="section-form__aside--doble-side">
                  <div className="section-form__aside--doble-side-A">
                    <Select
                      htmlForLabel={'bloodType'}
                      selected={formValues.bloodType}
                      disabled={isDisabled}
                      handleChange={(value) =>
                        changeData({ name: 'bloodType', value })
                      }
                      options={bloodTypeList.map((bloodType) => ({
                        value: bloodType,
                        label: bloodType,
                      }))}
                      textLabel={'Grupo sanguíneo'}
                      placeholder={'Selecciona ...'}
                      error={!!errors.bloodType}
                    />
                    {!!errors?.bloodType && (
                      <p
                        className={`${
                          isDisabled
                            ? 'section-form__error-paragraph--disabled'
                            : 'section-form__error-paragraph'
                        }`}
                      >
                        <AImage
                          className="section-form__error-icon"
                          url={errorIconParagraphDark}
                          alt="errorIconParagraph"
                        />
                        {t('invalidBloodType')}
                      </p>
                    )}
                  </div>
                  <div className="section-form__aside--doble-side-B">
                    <Select
                      htmlForLabel={'rh'}
                      selected={formValues.rh}
                      disabled={isDisabled}
                      handleChange={(value) =>
                        changeData({ name: 'rh', value })
                      }
                      options={RHList.map((rh) => ({ value: rh, label: rh }))}
                      textLabel={'RH'}
                      placeholder={'Selecciona ...'}
                      error={!!errors.rh}
                    />
                    {!!errors?.rh && (
                      <p
                        className={`${
                          isDisabled
                            ? 'section-form__error-paragraph--disabled'
                            : 'section-form__error-paragraph'
                        }`}
                      >
                        <AImage
                          className="section-form__error-icon"
                          url={errorIconParagraphDark}
                          alt="errorIconParagraph"
                        />
                        {t('invalidRH')}
                      </p>
                    )}
                  </div>
                </div>

                <Select
                  htmlForLabel={'idType'}
                  selected={formValues.idType}
                  disabled={isDisabled}
                  handleChange={(value) =>
                    changeData({ name: 'idType', value })
                  }
                  options={identificationTypeList?.map(
                    (identificationType) => ({
                      value: identificationType,
                      label: identificationType,
                    })
                  )}
                  textLabel={'Tipo de documento de identificación'}
                  placeholder={'Selecciona ...'}
                  error={!!errors.idType}
                />
                {!!errors?.idType && (
                  <p
                    className={`${
                      isDisabled
                        ? 'section-form__error-paragraph--disabled'
                        : 'section-form__error-paragraph'
                    }`}
                  >
                    <AImage
                      className="section-form__error-icon"
                      url={errorIconParagraphDark}
                      alt="errorIconParagraph"
                    />
                    {t('invalidIdentificationType')}
                  </p>
                )}
              </aside>

              <aside className="section-form__aside">
                <article className="section-form__article">
                  <Input
                    id="numberId"
                    label="Número de documento de identificación"
                    placeholder=""
                    value={formValues.numberId}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      changeData({ name: 'numberId', value });
                    }}
                    disabled={isDisabled}
                    register={register}
                    error={!!errors?.numberId}
                  />
                </article>

                <article className="section-form__article">
                  <Label text={'Fecha de nacimiento'} htmlFor="bornDate" />
                  <ACalendar
                    birthdateForm={moment(formValues.bornDate)}
                    updateBirthdate={(date) =>
                      changeData({ name: 'bornDate', value: date })
                    }
                    disabled={isDisabled}
                    setMarginForCalendar={setMarginForCalendar}
                    isOpenCalendar={isOpenCalendar}
                    setIsOpenCalendar={setIsOpenCalendar}
                  />
                </article>
              </aside>
            </>
          )}
        </section>

        {!isDisabled && (
          <section className="section-form__buttons">
            <div className="section-form__buttons--left">
              <AButton
                type="button"
                tag="button"
                className="button-secondary btn-profile"
                dataTestId="cancel-button"
                handleClick={cancelProcess}
              >
                Cancelar
              </AButton>
            </div>

            <div className="section-form__buttons--right">
              <AButton
                type="submit"
                tag="button"
                className="button-primary--dark btn-profile"
                disabled={isOriginData || !isValid}
              >
                Guardar
              </AButton>
            </div>
          </section>
        )}
      </form>

      {isDisabled && (
        <section className="section-password-form">
          <Accordion
            title="Cambiar contraseña"
            htmlForLabel="account"
            icon={padlock}
            styleName="password"
            accordionState={showPassForm}
            classStyle={'modifier-accordion'}
            handleAccordion={setShowPassForm}
            forPassword
          >
            {
              <ChangePasswordForm
                handleBack={() => setShowPassForm(false)}
                profilePage={true}
              />
            }
          </Accordion>
        </section>
      )}
      {showModalError && (
        <ModalAlert
          toggleModal={setShowModalError}
          isOpened={showModalError}
          alertType="error"
          alertText={messageError}
        />
      )}
    </div>
  );
};

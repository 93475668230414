import './ACalendar.scss';
import { arrowDown, chevronUp, showMoreIcon, chevronUpError } from 'utils/assets';
import { AImage } from 'components/atoms';
import { useEffect, useState } from 'react';
import {
  DayObject,
  MonthObject,
  Views,
  YearObject,
} from 'utils/interfaces/calendar.interface';
import moment from 'moment';
import 'moment/locale/es';
import { calendarConstant } from 'utils/helpers/dictionaries';

export type ACalendarProps = {
  name?: string;
  birthdateForm?: moment.Moment;
  updateBirthdate: (date: string) => void;
  disabled?: boolean;
  setMarginForCalendar: (state: number) => void;
  isOpenCalendar: boolean;
  setIsOpenCalendar: (state: boolean) => void;
  error?: boolean;
};

export const ACalendar = ({
  name,
  birthdateForm,
  updateBirthdate,
  disabled = false,
  setMarginForCalendar,
  isOpenCalendar,
  setIsOpenCalendar,
  error,
}: ACalendarProps) => {
  const [currencyView, setCurrencyView] = useState<Views>('days');
  const [calendar, setCalendar] = useState<DayObject[][]>([]);
  const [selectedDay, setSelectedDay] = useState<moment.Moment | undefined>();
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>();
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [days, setDays] = useState<string[]>([
    'Do',
    'Lu',
    'Ma',
    'Mi',
    'Ju',
    'Vi',
    'Sa',
  ]);
  const [months, setMonths] = useState<MonthObject[]>([]);
  const [years, setYears] = useState<YearObject[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [currentViewYear, setCurrentViewYear] = useState<
    moment.Moment | undefined
  >();
  const [birthdateFormState, setBirthdateFormState] = useState<
    moment.Moment | undefined
  >(undefined);

  const changeView = () => {
    setCurrencyView('years');
  };

  const isSelectedDay = (date: moment.Moment) => {
    if (selectedDay?.isSame(date)) {
      return 1;
    }

    return 0;
  };
  const isdisableDay = (date: moment.Moment) => {
    if (date.month() !== currentDate.getMonth()) {
      return true;
    }

    if (date.isSameOrAfter(moment().startOf('day'))) {
      return true;
    }

    return false;
  };

  const isSelectedDate = (
    date: moment.Moment,
    type: moment.unitOfTime.StartOf
  ) => {
    if (type === 'month') {
      return date.month() === selectedMonth;
    } else {
      return date.year() === selectedYear;
    }
  };

  const isDisabledDate = (
    date: moment.Moment,
    type: moment.unitOfTime.StartOf
  ) => {
    if (type === 'month') {
      if (date.month() > moment().month() && date.year() === moment().year())
        return true;
      else return false;
    } else {
      if (date.year() > moment().year()) return true;
      else return false;
    }
  };

  const createCalendar = () => {
    setCalendar([]);

    const startDate = moment(currentDate)
      .startOf('month')
      .startOf('week')
      .toDate();

    const endDate = moment(currentDate).endOf('month').endOf('week').toDate();

    let date = moment(startDate);
    while (date.isSameOrBefore(endDate)) {
      const week: DayObject[] = [];

      for (let i = 0; i < 7; i++) {
        week.push({
          disabled: isdisableDay(moment(date)),
          date: moment(date),
          dayOfMonth: moment(date).date(),
          selected: isSelectedDay(moment(date)),
        });

        date.add(1, 'day');
      }

      setCalendar((state) => [...state, week]);
    }
  };

  const createCalendarMonth = () => {
    setMonths(
      Array.from({ length: 12 }, (item, index): MonthObject => {
        let date = moment(currentDate).month(index);
        return {
          date: date,
          selected: isSelectedDate(date, 'month'),
          disabled: isDisabledDate(date, 'month'),
        };
      })
    );
  };

  const createCalendarYear = () => {
    const decadeStart = Math.floor(currentDate.getFullYear() / 10) * 10;

    setYears(
      Array.from({ length: 12 }, (item, index): YearObject => {
        let date = moment(currentDate).year(decadeStart + index);

        return {
          date: date,
          selected: isSelectedDate(date, 'year'),
          disabled: isDisabledDate(date, 'year'),
        };
      })
    );
  };

  const previousDate = (amount: number, type: 'month' | 'year' | 'years') => {
    setCurrentDate(moment(currentDate).subtract(amount, type).toDate());
  };

  const nextDate = (amount: number, type: 'month' | 'year' | 'years') => {
    setCurrentDate(moment(currentDate).add(amount, type).toDate());
  };

  const selectDay = (day: DayObject) => {
    setSelectedDay(day.date);
    updateBirthdate(day.date.format(calendarConstant.dayMonthYear));
    setIsOpenCalendar(false);
  };

  const selectADate = (date: MonthObject | YearObject, view: Views) => {
    setCurrentDate(date.date.toDate());
    setCurrencyView(view);

    if (view === 'months') {
      setSelectedYear(date.date.year());
    } else if (view === 'days') {
      setSelectedMonth(date.date.month());
      setCurrentViewYear(date.date);
    }
  };

  const formatToPascalCase = (text: string) => {
    return text
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => match.toUpperCase());
  };

  const detectHeightCalendar = () => {
    const heightCalendar =
      document.getElementById('calendar-profile')?.offsetHeight;
    setMarginForCalendar(heightCalendar ? heightCalendar : 0);
  };

  useEffect(() => {
    detectHeightCalendar();
  });

  useEffect(() => {
    moment.updateLocale('es', { week: { dow: 0 } });
    moment.locale('es');
  }, []);

  useEffect(() => createCalendar(), []);

  useEffect(() => {
    if (
      birthdateForm?.isValid() &&
      birthdateFormState?.date() !== birthdateForm.date()
    ) {
      setBirthdateFormState(birthdateForm);
      setCurrentDate(birthdateForm.toDate());
      setSelectedDay(birthdateForm);
      setSelectedMonth(birthdateForm.month());
      setSelectedYear(birthdateForm.year());
      setCurrentViewYear(birthdateForm);
    } else if (!birthdateForm?.isValid()) {
      setSelectedMonth(moment().month());
      setSelectedYear(moment().year());
      setCurrentViewYear(moment());
    }
  }, [birthdateForm]);
  useEffect(() => {
    if (currencyView === 'days') createCalendar();
    else if (currencyView === 'months') createCalendarMonth();
    else if (currencyView === 'years') createCalendarYear();
  }, [currentDate, currencyView]);
  
  return (
    <div className="a_calendar-wrap">
      <div
        className={`a_date ${disabled ? 'a_date--disabled' : ''}`}
        onClick={() => {
          if (!disabled) {
            setIsOpenCalendar(!isOpenCalendar);
            createCalendar();
          }
        }}
      >
        <AImage
          className={`a_date__figure ${
            isOpenCalendar ? 'a_date__figure--open' : ''
          } ${disabled ? 'a_date__figure--disabled' : ''}`}
          url={error ? chevronUpError: chevronUp}
          alt="Botón para abrir calendario"
        />
        <input
          name={name ? name : 'calendar'}
          type="text"
          placeholder={calendarConstant.calendarPlaceholder}
          value={selectedDay?.isSame(moment(), 'day') ? '' : selectedDay?.format(calendarConstant.dayMonthYear)}
          disabled={disabled}
          className={`a_date__input ${
            disabled ? 'a_date__input--disabled' : ''
          } ${error ? 'a_date__input--error' : ''}`}
          readOnly={true}
        />
      </div>
      {isOpenCalendar && currencyView === 'days' && (
        <section className="calendar" id="calendar-profile">
          <header className="calendar__header">
            <button
              className="calendar__button calendar__button--left"
              onClick={() => previousDate(1, 'month')}
              role="button"
              type="button"
            >
              <AImage
                className="calendar__button__figure"
                url={showMoreIcon}
                alt="Botón para ir al mes anterior"
              />
            </button>
            <h2 className="calendar__text" onClick={changeView}>
              {formatToPascalCase(
                moment(currentDate)
                  .locale('es')
                  .format(calendarConstant.MMMM)
                  .replace('de', '')
              )}
              <AImage
                className=""
                url={showMoreIcon}
                alt="Botón para expandir años"
              />
            </h2>

            {moment(currentDate).locale('es').format(calendarConstant.MMMM) !==
            moment().locale('es').format(calendarConstant.MMMM) ? (
              <button
                className="calendar__button calendar__button--right"
                onClick={() => nextDate(1, 'month')}
                role="button"
                type="button"
              >
                <AImage
                  className="calendar__button__figure"
                  url={showMoreIcon}
                  alt="Botón para ir al mes siguiente"
                />
              </button>
            ) : (
              <span></span>
            )}
          </header>

          <div className="calendar__body">
            <div className="calendar__row">
              {days.map((day, index) => (
                <div className="calendar__name-day" key={index}>
                  {day}
                </div>
              ))}
            </div>

            {calendar.map((week, index) => (
              <div className="calendar__row" key={index}>
                {week.map((day, index) => (
                  <div
                    key={index}
                    className={`calendar__day-container 
                              ${day.disabled ? 'disabled' : ''} 
                              ${day.selected === 1 ? 'selected--only' : ''} 
                            `}
                    onClick={() => {
                      !day.disabled && selectDay(day);
                    }}
                  >
                    <span className="calendar__day">{day.dayOfMonth}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
      )}

      {isOpenCalendar && currencyView === 'months' && (
        <section className="calendar">
          <header className="calendar__header">
            <button
              className="calendar__button calendar__button--left"
              onClick={() => previousDate(1, 'year')}
              role="button"
              type="button"
            >
              <AImage
                className="calendar__button__figure"
                url={showMoreIcon}
                alt="Botón para ir al año anterior"
              />
            </button>
            <h2 className="calendar__text calendar__text--decade">
              {formatToPascalCase(
                moment(currentDate)
                  .locale('es')
                  .format(calendarConstant.MMMM)
                  .replace('de', '')
              )}
              <AImage
                className=""
                url={showMoreIcon}
                alt="Botón para expandir años"
              />
            </h2>

            {moment(currentDate).locale('es').format(calendarConstant.MMMM) !==
            moment().locale('es').format(calendarConstant.MMMM) ? (
              <button
                className="calendar__button calendar__button--right"
                onClick={() => nextDate(1, 'year')}
                role="button"
                type="button"
              >
                <AImage
                  className="calendar__button__figure"
                  url={showMoreIcon}
                  alt="Botón para ir al año siguiente"
                />
              </button>
            ) : (
              <span></span>
            )}
          </header>
          <div className="calendar__body calendar__body--months">
            {months.map((month, index) => (
              <div
                key={index}
                className={`calendar__item 
                      ${month.selected ? 'selected__item' : ''} 
                      ${month.disabled ? 'calendar__item--disabled' : ''}
                    `}
                onClick={() => {
                  !month.disabled && selectADate(month, 'days');
                }}
              >
                {formatToPascalCase(
                  moment(month.date).locale('es').format(calendarConstant.MMM2).replace('.', '')
                )}
              </div>
            ))}
          </div>
        </section>
      )}

      {isOpenCalendar && currencyView === 'years' && (
        <section className="calendar">
          <header className="calendar__header">
            <button
              className="calendar__button calendar__button--left"
              onClick={() => previousDate(10, 'years')}
              role="button"
              type="button"
            >
              <AImage
                className="calendar__button__figure"
                url={showMoreIcon}
                alt="Botón para ir al año anterior"
              />
            </button>
            <h2 className="calendar__text calendar__text--decade">
              {formatToPascalCase(
                moment(currentViewYear)
                  .locale('es')
                  .format(calendarConstant.MMMM)
                  .replace('de', '')
              )}
              <AImage
                className=""
                url={showMoreIcon}
                alt="Botón para expandir años"
              />
            </h2>

            {!years.some((year) => year.date.year() === moment().year()) ? (
              <button
                className="calendar__button calendar__button--right"
                onClick={() => nextDate(10, 'years')}
                role="button"
                type="button"
              >
                <AImage
                  className="calendar__button__figure"
                  url={showMoreIcon}
                  alt="Botón para ir al año siguiente"
                />
              </button>
            ) : (
              <span></span>
            )}
          </header>
          <div className="calendar__body calendar__body--years">
            {years.map((year, index) => (
              <div
                key={index}
                className={`calendar__item 
                      calendar__item--year 
                      ${year.selected ? 'selected__item' : ''} 
                      ${year.disabled ? 'calendar__item--disabled' : ''}
                    `}
                onClick={() => {
                  !year.disabled && selectADate(year, 'months');
                }}
              >
                {formatToPascalCase(
                  moment(year.date).locale('es').format(calendarConstant.YYYY)
                )}
              </div>
            ))}
          </div>
        </section>
      )}
      <div className="subtext">
        <pre className={`${error ? 'subtext-error' : 'input-subtext'}`}></pre>
      </div>
    </div>
  );
};

export default ACalendar;

import * as yup from 'yup';

export const changePasswordFormSchema = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .required('Todos los campos son requeridos.')
    .min(8, 'La contraseña debe tener mínimo 8 caracteres.')
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-ZÑ])(?=.*[a-zñ])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-Ññ]{8,256}$/,
      'Las contraseñas deben incluir una combinación de al menos ocho letras mayúsculas, minúsculas, números y un carácter especial.'
    )
    .test('no-ñ', 'La contraseña no debe contener la letra Ñ', value => !/[ñÑ]/.test(value || '')),
  confirmNewPassword: yup
    .string()
    .trim()
    .required('Todos los campos son requeridos.')
    .oneOf([yup.ref('newPassword'), null], 'Las contraseñas no coinciden'),
  currentPassword: yup
    .string()
    .trim()
    .required('Todos los campos son requeridos.')
});
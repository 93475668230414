export enum Css {
  buttonConfirm = 'buttonConfirm',
  buttonConfirmActive = 'buttonConfirm buttonConfirmActive',
  activeImage = 'activeImage',
}

export enum Parameters {
  active = 'active',
  text = 'Creacion en sap',
  type = 'text',
}

export enum formTypesContactInfo {
  countryResidence = 'countryResidence',
  departmentResidence = 'departmentResidence',
  cityResidence = 'cityResidence',
  addressType = 'addressType',
  address = 'address',
  complementAddress = 'complementAddress',
  phone = 'phone',
  email = 'email',
}

export enum CountryResidence {
  placeholder = 'Selecciona un pais',
  htmlForLabel = 'countryResidence',
  textLabel = 'País de residencia',
}

export enum CityResidence {
  placeholder = 'Selecciona una ciudad',
  htmlForLabel = 'cityResidence',
  textLabel = 'Ciudad/Provincia de residencia',
}

export enum DepartmentResidence {
  placeholder = 'Selecciona un departamento',
  htmlForLabel = 'departmentResidence',
  textLabel = 'Departamento de residencia',
}

export enum AddressType {
  placeholder = 'Selecciona uno',
  htmlForLabel = 'addressType',
  textLabel = 'Tipo de dirección',
}

export enum Address {
  id = 'address',
  placeholder = 'Ej: Cra 15b #48-15',
  textLabel = 'Dirección',
}

export enum Phone {
  id = 'phone',
  textLabel = 'Número de contacto',
  placeholder = 'Ej: +57 3XX XXX XXXX',
  subtext = 'Ingresa el número de indicativo',
}

export enum ComplementAddress {
  id = 'complementAddress',
  placeholder = 'Ej: Apto 405',
  textLabel = 'Complemento dirección (opcional)',
}

export enum Email {
  id = 'email',
  placeholder = 'Email',
  textLabel = 'Correo personal',
}

export enum ContactType {
  placeholder = 'Selecciona un contrato',
  htmlForLabel = 'contactType',
  textLabel = 'Tipo de contrato',
}

export enum Position {
  id = 'position',
  placeholder = 'Ej: 10102356',
  textLabel = 'Posición',
}

export enum Role {
  id = 'role',
  placeholder = 'Ej: Desarrollador java',
  textLabel = 'Rol',
}

export enum StartDate {
  id = 'startDate',
  textLabel = 'Fecha de inicio',
}

export enum PayrollComponent {
  placeholder = 'Selecciona una',
  htmlForLabel = 'payrollComponent',
  textLabel = 'Componente de nómina',
}

export enum Currency {
  placeholder = 'Selecciona',
  htmlForLabel = 'currency',
  textLabel = 'Moneda',
}

export enum SalaryBase {
  id = 'salaryBase',
  textLabel = 'Salario devengado',
  placeholder = '$0',
}

export enum Opcions {
  opcion1 = 'opcion1',
  opcion2 = 'opcion2',
  opcion3 = 'opcion3',
  opcion4 = 'opcion4',
  opcion5 = 'opcion5',
}

export enum StyleCss {
  buttonConfirm = 'buttonConfirm',
  botonVerde = 'botonVerde',
  botonRojo = 'botonRojo',
  botonMorado = 'botonMorado',
  botonVerdeDischeck = 'botonVerdeDischeck',
  botonRojoDischeck = 'botonRojoDischeck',
  banner1 = 'banner1',
  CenterText = 'CenterText',
  bannerText = 'bannerText',
  container = 'container',
  IconLogin = 'IconLogin',
  bgGrey = 'bgGrey',
  banner2 = 'banner2',
  banner2titlecontainer = 'banner2__title-container',
  banner2title = 'banner2__title',
  banner2subtitle = 'banner2__subtitle',
  banner3 = 'banner3',
  accessButtons = 'accessButtons',
  adminForm = 'adminForm',
}

import { useEffect, useState } from 'react';
import { selectStages, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { IStage, TTabStage } from 'utils/interfaces/store/user.interfaces';
import useViewport from '../../../utils/hooks/useViewport';
import { Loader } from 'components/atoms';
import { StagesActives } from '../StagesActives/StagesActives.component';
import { StagesCompleted } from '../StagesCompleted/StagesCompleted.component';
import './StagesList.scss';
import { Numbers, NumbersText } from 'utils/helpers/dictionaries';
import { stagesWithActivities } from 'utils/helpers/stageState';

interface IProps {
  currentTab: TTabStage;
  disabled?: boolean;
}

export const StagesList = ({ currentTab, disabled }: IProps) => {
  const { isLoadingStages, stages } = useAppSelector(selectStages);
  const { isLoadingVacant, vacant } = useAppSelector(selectVacantInfo);
  const [activeStages, setActiveStage] = useState<IStage[]>([]);
  const [completedStages, setCompletedStage] = useState<IStage[]>([]);

  // filter stages by *completed*
  useEffect(() => {
    if (!isLoadingStages && stages) {
      setCompletedStage(
        stages.filter(
          (stage) =>
            stage.status === NumbersText.fourText ||
            stage.status === NumbersText.sixText
        )
      );

      if (stagesWithActivities.some((stage) => stage === vacant?.idStage)) {
        setActiveStage(
          stages.filter(
            (stage) =>
              stage.status !== NumbersText.fourText &&
              stage.status !== NumbersText.sixText
          )
        );
      }
    }
  }, [isLoadingStages, stages]);

  if (isLoadingStages || isLoadingVacant || !vacant) {
    return (
      <div className="stagesList__loader">
        <Loader />
      </div>
    );
  }

  return (
    <div
      data-testid="stages"
      className={`stagesList__container ${
        currentTab !== 'active' && 'stagesList__container--completed'
      } ${
        completedStages.length > 0 && currentTab !== 'active'
          ? 'stagesList__container--completed--withStages'
          : ''
      } ${
        activeStages.length === 0 && currentTab === 'active'
          ? 'stagesList__container--whitOutStages'
          : ''
      }`}
    >
      <div
        className={`stagesList ${
          currentTab !== 'active' && 'stagesList--completed'
        }`}
      >
        {currentTab === 'active' ? (
          disabled ? (
            <StagesActives
              activeStages={activeStages}
              currentStage={vacant!.preStage}
              disabled
            />
          ) : (
            <StagesActives
              activeStages={activeStages}
              currentStage={vacant!.preStage}
            />
          )
        ) : (
          <StagesCompleted
            completedStages={completedStages}
            activeStages={activeStages}
            currentStage={vacant!.preStage}
          />
        )}
      </div>
    </div>
  );
};

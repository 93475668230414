import { Amplify } from 'aws-amplify';
import amplifyConfig from 'aws-exports';
import { selectLanguageCode } from 'store/translation/translation.slice';
import { useAppSelector } from 'store/hooks';
import { useGetTextInSelectLanguaje } from 'hooks/useGetTextInSelectLanguaje';
import { AppRoutes } from 'routes/AppRoutes';
amplifyConfig.oauth.redirectSignIn = `${window.location.origin}/admin`;
amplifyConfig.oauth.redirectSignOut = `${window.location.origin}/admin`;
Amplify.configure(amplifyConfig);

const App = () => {
  const languageCode = useAppSelector(selectLanguageCode);
  const { isError } = useGetTextInSelectLanguaje(languageCode);

  return (
    <div className="app">
      <AppRoutes />
    </div>
  );
};

export default App;

import { useEffect, useState } from 'react';
import { FileLoading, ModalAlert } from 'components/molecules';
import { typeAlert } from 'components/molecules/ModalAlert/ModalAlert.components';
import './ImageUpload.scss';
import { AButton } from 'components/atoms';
import { IAFileUpload, IFiles, IModal } from 'utils/interfaces';
import { linkIcon } from 'utils/assets';
import UploadImage from 'components/molecules/UploadImage/UploadImage.component';

interface IImageUploadProps extends IAFileUpload {
  onFilesChange?: (files: IFiles[]) => void;
}

export const ImageUpload: React.FC<IImageUploadProps> = ({
  title,
  linkUploaded = false,
  titleLinkButton = '',
  onFilesChange,
}: IImageUploadProps) => {
  const [modal, setModal] = useState<IModal>({
    message: '',
    type: 'success',
    isOpen: false,
    closeIcon: false,
    confirmButton: false,
    title: undefined,
    cancelButton: false,
  });
  const [filesDone, setFilesDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<IFiles[]>([]);
  const [keyInputs, setKeyInputs] = useState(Date.now());
  const [linkUrl, setLinkUrl] = useState<string | undefined>('');
  const [filesToDelete, setFilesToDelete] = useState<IFiles[]>([]);

  const handleModalAndAdvanceStage = (type: typeAlert) => {
    setModal((state) => ({ ...state, isOpen: false }));
    if (type === 'error') {
      setFilesDone(false);
      setKeyInputs(Date.now());
      setLinkUrl(undefined);
    }
  };

  if (onFilesChange) {
    onFilesChange(files);
  }

  const checkContainHttp = () => {
    const contieneHttp = linkUrl?.match('http');
    let url = linkUrl;
    if (!contieneHttp) {
      url = `https://${linkUrl}`;
    }
    return url;
  };

  return (
    <>
      <ModalAlert
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        closeIcon={modal.closeIcon}
        confirmButton={modal.confirmButton}
        toggleModal={() => handleModalAndAdvanceStage(modal.type)}
        cancelButton={modal.cancelButton}
        cancelFunction={() => {
          setModal((state) => ({ ...state, isOpen: false }));
          setLinkUrl(undefined);
        }}
        setLinkUrl={setLinkUrl}
      />

      <div className="fileUpload__loading">
        {linkUrl && (
          <FileLoading
            key={keyInputs}
            link
            nameFile={linkUrl}
            url={checkContainHttp()}
            setLinkUrl={setLinkUrl}
          />
        )}

        {files.map((item, index) => (
          <>
            <div key={index} className="fileWrapper">
              <FileLoading
                url={item.urlFile}
                nameFile={item.nameFile}
                setIsLoading={setIsLoading}
                fileSize={item.fileSize}
                files={files}
                setFiles={setFiles}
                setKeyInputs={setKeyInputs}
                setFilesToDelete={setFilesToDelete}
              />
            </div>
          </>
        ))}
      </div>

      <div className="fileUpload__buttons">
        {files.length < 2 && (
          <UploadImage
            name={files.length > 0 ? 'Adjuntar otra imagen' : title}
            setModal={setModal}
            secondary={files.length > 0 || !!linkUrl}
            setFiles={setFiles}
            files={files}
            keyInputs={keyInputs}
            onDelete={filesToDelete}
          />
        )}

        {linkUploaded && !linkUrl && (
          <div
            className={`containerLink ${
              isLoading && 'containerLink--disabled'
            }`}
            onClick={
              !isLoading
                ? () =>
                    setModal({
                      message: '',
                      isOpen: true,
                      confirmButton: true,
                      type: 'adjuntLink',
                      closeIcon: false,
                      cancelButton: true,
                      inputText: false,
                    })
                : () => {}
            }
          >
            <img className="containerLink__imgLink" src={linkIcon} />
            <AButton tag="button" className="button-third" disabled={isLoading}>
              {titleLinkButton}
            </AButton>
          </div>
        )}
      </div>
    </>
  );
};

export default ImageUpload;

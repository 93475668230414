import { useEffect, useState } from 'react';

import { FileLoading, ModalAlert, UploadFile } from 'components/molecules';
import { typeAlert } from 'components/molecules/ModalAlert/ModalAlert.components';
import './FileUpload.scss';
import { AButton } from 'components/atoms';
import {
  IAFileUpload,
  IDataFilesRequest,
  IDataToSend,
  IFiles,
  IModal,
} from 'utils/interfaces';
import { useGetStagesQuery, useSendFileMutation } from 'services/talento';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectCurrentActivity,
  selectUserInfo,
  setStageCompleted,
} from 'store';
import {
  selectStages,
  selectVacantInfo,
  setActivityStatus,
} from '../../../store/user/user.slice';
import { useNavigate } from 'react-router-dom';
import { useWindowMobile } from 'hooks/useWindowMobile';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { linkIcon } from 'utils/assets';
import { activityStates } from 'utils/helpers';
import { EmptyString, Numbers, NumbersText } from 'utils/helpers/dictionaries';
import { APPROACH_THE_ROL } from './constans';

export const FileUpload = ({
  title, 
  linkUploaded = false,
  titleLinkButton = EmptyString.emptyString,
}: IAFileUpload) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { translations, isLoading: isLoadingTranslations } =
    useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoadingTranslations);

  const { idActivity, nameActivity } = useAppSelector(selectCurrentActivity);
  const { userState } = useAppSelector(selectUserInfo);
  const { vacant } = useAppSelector(selectVacantInfo);
  const { stages } = useAppSelector(selectStages);
  const { email } = useAppSelector(selectUserInfo);

  const { refetch: getStages, status } = useGetStagesQuery({ email });
  const { isMobile } = useWindowMobile(1279);

  const [modal, setModal] = useState<IModal>({
    message: EmptyString.emptyString,
    type: 'success',
    isOpen: false,
    closeIcon: false,
    confirmButton: false,
    title: undefined,
    cancelButton: false,
  });
  const [sendFile, { isLoading: isLoadingSendFile, isError }] =
    useSendFileMutation();
  const [filesDone, setFilesDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendFileDone, setSendFileDone] = useState(false);
  const [files, setFiles] = useState<IFiles[]>([]);
  const [keyInputs, setKeyInputs] = useState(Date.now());
  const [linkUrl, setLinkUrl] = useState<string | undefined>(
    EmptyString.emptyString
  );

  const handleModalAndAdvanceStage = (type: typeAlert) => {
    setModal((state) => ({ ...state, isOpen: false }));
    if (type === 'error') {
      setFilesDone(false);
      setFiles([]);
      setKeyInputs(Date.now());
      setLinkUrl(undefined);
    }

    if (type === 'success') {
      dispatch(setStageCompleted({ id: idActivity }));
      dispatch(setActivityStatus({ status: activityStates.completed }));
      if (isMobile) {
        navigate('/'); // Redirect HOME in mobile viewport
      }
      if (nameActivity === APPROACH_THE_ROL) {
        window.location.reload()
      }
    }
  };

  const convertFileToBase64 = () => {
    let updateFiles = files.map((element, index) => {
      let nameUser = vacant!['fullName'];
      let newName = EmptyString.emptyString as string;
      let extension = [];
      let reader = new FileReader();

      if (idActivity === '4') {
        newName = `Ensayo-${nameUser.replace(
          / /g,
          EmptyString.emptyString
        )}.pdf`;
        newName = newName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, EmptyString.emptyString);
        element.nameFile = newName;
      } else {
        extension = element.nameFile.split('.');
        newName = `PruebaRol${index + 1}-${nameUser.replace(
          / /g,
          EmptyString.emptyString
        )}.${extension[extension.length - 1].toLowerCase()}`;
        newName = newName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, EmptyString.emptyString);
        element.nameFile = newName;
      }

      const currentFile: any = element.file;
      reader.readAsDataURL(currentFile);
      reader.onload = function () {
        let base64 = reader.result!.toString().split(',')[1];
        element.base64 = base64;
      };

      return element;
    });

    setFiles(updateFiles);

    if (idActivity === '4') {
      setModal({
        message: EmptyString.emptyString,
        type: 'loading',
        isOpen: true,
        confirmButton: false,
        closeIcon: false,
        title: t('sendEssay'),
      });
    } else {
      setModal({
        message: EmptyString.emptyString,
        type: 'loading',
        isOpen: true,
        confirmButton: false,
        closeIcon: false,
        title: t('sendTest'),
      });
    }

    setTimeout(() => {
      setFilesDone(true);
    }, 1500);
  };

  const handleUploadFiles = () => {
    const listFiles = files.map((item) => {
      return {
        fileBase64: item.base64,
        filename: item.nameFile,
      };
    });

    const data: IDataToSend = {
      files: listFiles,
      url: linkUrl ? linkUrl : EmptyString.emptyString,
    };

    sendFile({
      email,
      data,
      idStage: stages[3].stage,
      idVacant: vacant!['idVacant'],
      idActivity: idActivity,
      activityName: nameActivity,
    })
      .then(({ data }: any) => {
        if (data.statusCode === 200) {
          getStages();
        }
      })
      .catch((e) => {
        setModal({
          message: t('failedConnectServer'),
          type: 'error',
          isOpen: true,
          closeIcon: true,
        });
      })
      .finally(() => setSendFileDone(true));
  };

  useEffect(() => {
    filesDone && handleUploadFiles();
  }, [filesDone]);

  useEffect(() => {
    if (status === 'fulfilled' && sendFileDone) {
      if (idActivity === NumbersText.fourText) {
        setModal({
          message: 'Hemos recibido tu ensayo',
          type: 'success',
          isOpen: true,
          confirmButton: true,
          closeIcon: false,
        });
      } else {
        setModal({
          message: 'Hemos recibido tu prueba',
          type: 'success',
          isOpen: true,
          confirmButton: true,
          closeIcon: false,
        });
      }
    }
  }, [status]);

  const checkContainHttp = () => {
    const contieneHttp = linkUrl?.match('http');
    let url = linkUrl;
    if (!contieneHttp) {
      url = `https://${linkUrl}`;
    }
    return url;
  };

  return (
    <>
      <ModalAlert
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        closeIcon={modal.closeIcon}
        confirmButton={modal.confirmButton}
        toggleModal={() => handleModalAndAdvanceStage(modal.type)}
        cancelButton={modal.cancelButton}
        cancelFunction={() => {
          setModal((state) => ({ ...state, isOpen: false }));
          setLinkUrl(undefined);
        }}
        setLinkUrl={setLinkUrl}
      />

      <div className="fileUpload__loading">
        {linkUrl && (
          <FileLoading
            key={keyInputs}
            link
            nameFile={linkUrl}
            url={checkContainHttp()}
            setLinkUrl={setLinkUrl}
          />
        )}

        {files.map((item, index) => (
          <>
            <FileLoading
              key={index}
              url={item.urlFile}
              nameFile={item.nameFile}
              setIsLoading={setIsLoading}
              fileSize={item.fileSize}
              files={files}
              setFiles={setFiles}
              setKeyInputs={setKeyInputs}
            />
          </>
        ))}
      </div>

      <div className="fileUpload__buttons">
        
        {userState === Numbers.five ? (
          <UploadFile // Renderiza el componente UploadFile con diferentes propiedades
            name={files.length > Numbers.zero ? 'Cargar otro' : title}
            setModal={setModal}
            disabled={isLoading}
            setFiles={setFiles}
            files={files}
            keyInputs={keyInputs}
          />
        ) : // De lo contrario...
        idActivity === NumbersText.fourText ? ( // Verifica si la variable idActivity es igual a '4'
          files.length < Numbers.one &&
          !linkUrl && ( // Si se cumple, verifica si files.length es igual a 0 y linkUrl es falso
            <UploadFile // Renderiza el componente UploadFile con ciertas propiedades
              name={title}
              setModal={setModal}
              disabled={isLoading}
              secondary={false}
              setFiles={setFiles}
              files={files}
              keyInputs={keyInputs}
            />
          )
        ) : (
          files.length < Numbers.five && ( // Si no se cumple la condición anterior y files.length es menor que 5...
            <UploadFile // Renderiza el componente UploadFile con diferentes propiedades
              name={files.length > Numbers.zero ? 'Cargar otro archivo' : title}
              setModal={setModal}
              disabled={files.length > Numbers.one || isLoading}
              secondary={files.length > Numbers.zero || !!linkUrl}
              setFiles={setFiles}
              files={files}
              keyInputs={keyInputs}
            />
          )
        )}

        {linkUploaded && !linkUrl && (
          <div
            className={`containerLink ${
              isLoading && 'containerLink--disabled'
            }`}
            onClick={
              !isLoading
                ? () =>
                    setModal({
                      message: EmptyString.emptyString,
                      isOpen: true,
                      confirmButton: true,
                      type: 'adjuntLink',
                      closeIcon: false,
                      cancelButton: true,
                      inputText: false,
                    })
                : () => {}
            }
          >
            <img className="containerLink__imgLink" src={linkIcon} />
            <AButton
              tag="button"
              className="button-third"
              disabled={
                linkUrl !== undefined && linkUrl !== EmptyString.emptyString
              }
            >
              {titleLinkButton}
            </AButton>
          </div>
        )}

        {(files.length > Numbers.zero || linkUrl) && (
          <AButton
            className="button-primary--dark button__send"
            tag="button"
            handleClick={convertFileToBase64}
          >
            Enviar
          </AButton>
        )}
      </div>
    </>
  );
};

export default FileUpload;

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function () {
      resolve(reader.result!.toString().split(',')[1]);
    };

    reader.readAsDataURL(file);
  });
};

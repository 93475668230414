import { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { IAdminPanelForm } from '../AdminPanelForm.schema';
import { ACalendar, Input, Label } from 'components/atoms';
import styles from './PersonalDataSection.module.scss';
import { useUpdatePersonalInfoHiringMutation } from 'services/talento';
import CatalogSelect from 'components/molecules/CatalogSelect/CatalogSelect';
import { catalogoPersonal, Numbers, isTrue, isFalse } from 'utils/helpers/dictionaries';
import { DocumentTypeSelect } from 'components/molecules/DocumentTypeSelect/DocumentTypeSelect';
import moment from 'moment';
import useCalendar from 'hooks/useCalendar';
import CivilStatusSelect from './CivilStatusSelect';
import { useParams } from 'react-router-dom';

export default function PersonalDataSection() {
  const { control, getValues, setValue } = useFormContext<IAdminPanelForm>();
  const [updatePersonalInformation] = useUpdatePersonalInfoHiringMutation();
  const country = useWatch({ control, name: 'countryOfResidence' });
  const birthdateCalendar = useCalendar();
  const { email } = useParams();
  const [dataReady, setDataReady] = useState(isFalse);
  const [retryCount, setRetryCount] = useState(Numbers.zero)
  function handleCalendarCloseOnClick() {
    birthdateCalendar.setIsCalendarOpen(isFalse);
  }

  async function submitData() {
    const data = getValues();
    if (data.names && data.lastname){
       await updatePersonalInformation({
        ...data,
        email: email ?? '',
        names: data.names,
        lastname: data.lastname,
        secondLastname: data.secondLastname,
      }).unwrap(); 
    }
    
  }
  
  useEffect(() => {
    const checkDataReady = async () => {
      const data = getValues();
      if (data.names && data.lastname) {
        setDataReady(isTrue);
      } else {
        if (retryCount < Numbers.five) { 
          setRetryCount(retryCount + Numbers.one);
          setTimeout(() => {
            checkDataReady();
          }, Numbers.timeOutMin); 
        }
      }
    };
    checkDataReady();
  }, [getValues, retryCount]);
  
  return (
    <div className={styles.PersonalDataSection}>
      <h2 className={styles.PersonalDataSection__Title}>
        Información personal
      </h2>
      <div className={styles.PersonalDataSection__Grid}>
        <Controller
          control={control}
          name="names"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onClick={handleCalendarCloseOnClick}
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              id="names"
              label="Primer nombre"
              {...field}
              error={error !== undefined}
              success={isTouched && error === undefined}
              subtext={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="secondName"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onClick={handleCalendarCloseOnClick}
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              error={error !== undefined}
              id="secondName"
              label="Segundo nombre (opcional)"
              success={isTouched && error === undefined}
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="lastname"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onClick={handleCalendarCloseOnClick}
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              error={error !== undefined}
              id="lastname"
              label="Primer apellido"
              success={isTouched && error === undefined}
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="secondLastname"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onClick={handleCalendarCloseOnClick}
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              error={error !== undefined}
              id="secondLastname"
              label="Segundo apellido (opcional)"
              success={isTouched && error === undefined}
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <div>
          <Label text="Fecha de nacimiento" htmlFor="birthdate" />
          <Controller
            control={control}
            name="birthdate"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <ACalendar
                birthdateForm={moment(value)}
                updateBirthdate={(value) => {
                  onChange(value);
                  onBlur();
                  submitData();
                }}
                setMarginForCalendar={birthdateCalendar.setMarginForCalendar}
                isOpenCalendar={birthdateCalendar.isCalendarOpen}
                setIsOpenCalendar={birthdateCalendar.setIsCalendarOpen}
                error={fieldState.error !== undefined}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="gender"
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <CatalogSelect
              onClick={handleCalendarCloseOnClick}
              catalog={catalogoPersonal.gender}
              value={{
                value,
                label: value,
              }}
              placeholder={'Seleccione uno'}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              error={error !== undefined}
              textLabel="Género"
              htmlForLabel="gender"
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="civilStatus"
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <CivilStatusSelect
              onClick={handleCalendarCloseOnClick}
              value={{
                value,
                label: value,
              }}
              handleChange={({ value, valueEn }) => {
                onChange(value);
                setValue('civilStatusEn', valueEn);
                submitData();
              }}
              error={error !== undefined}
              textLabel="Estado civil"
              htmlForLabel="civilStatus"
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="bloodGroup"
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <CatalogSelect
              onClick={handleCalendarCloseOnClick}
              catalog={catalogoPersonal.bloodGroup}
              value={{
                value,
                label: value,
              }}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              error={error !== undefined}
              textLabel="Grupo sanguíneo"
              htmlForLabel="bloodGroup"
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="documentType"
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <DocumentTypeSelect
              onClick={handleCalendarCloseOnClick}
              country={country}
              value={{
                value,
                label: value,
              }}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              error={error !== undefined}
              textLabel="Tipo de documento de identificación"
              htmlForLabel="documentType"
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="documentNumber"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onClick={handleCalendarCloseOnClick}
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              error={error !== undefined}
              id="documentNumber"
              label="Número de documento"
              success={isTouched && error === undefined}
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="shirtSize"
          render={({
            field: { onChange, value, ...field },
            fieldState: { error },
          }) => (
            <CatalogSelect
              onClick={handleCalendarCloseOnClick}
              catalog={catalogoPersonal.tallaCamisa}
              value={{
                value,
                label: value,
              }}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              error={error !== undefined}
              textLabel="Talla de camiseta"
              htmlForLabel="shirtSize"
              subtext={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="preferredName"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onClick={handleCalendarCloseOnClick}
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              error={error !== undefined}
              id="preferredName"
              label="Nombre preferido"
              success={isTouched && error === undefined}
              subtext={error?.message}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
}

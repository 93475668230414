import {
  createApi,
  BaseQueryFn,
  fetchBaseQuery,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';

import { ICustomError } from 'utils/interfaces';
import { prepareHeaders } from 'lib/headers';

export const talentoAPI = createApi({
  reducerPath: 'talentoAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TALENTO_ABSOLUTE_URL,
    prepareHeaders: async (headers, { getState }) =>
      await prepareHeaders(headers, getState),
  }) as BaseQueryFn<string | FetchArgs, unknown, ICustomError, {}>,
  endpoints: () => ({}),
});

import {
  logoPragmaBanner,
  IconClose
} from 'utils/assets';
import styles from './AdminPanelForm.module.scss';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { catalogoLenguage } from 'utils/helpers/dictionaries';
export default function HeaderAdmin() {
  const [isVisibleClose, setisVisibleClose] = useState(false);
  const [iniciales, setIniciales] = useState('');
  useEffect(() => {
    const obtenerIniciales = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const correo = user.attributes.email;
      const partes = correo.split(catalogoLenguage.signal)[0].split(catalogoLenguage.point);
      const iniciales = partes
        .map((part: string[]) => part[0].toUpperCase())
        .join('');
      setIniciales(iniciales);
    };
    obtenerIniciales();
  }, []);
  const handlerSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) { }
  };
  const HandleHola = () => {
    if (isVisibleClose) {
      setisVisibleClose(false);
    } else {
      setisVisibleClose(true);
    }
  };
  return (
    <header className={styles.header}>
      <div className={styles.header__purpleLine}> 
        <div className={styles.header__purpleLine__shadow}/>
        <div className={styles.header__purpleLine__shadow}/>
      </div>
      <section className={styles.header__content}>
        <section className={styles.header__content__logo}>
          <img src={logoPragmaBanner} />
        </section>
        <div className={styles.header__content__title}>
          <h1 className={styles.header__content__title__text}>Nuevos Pragmáticos</h1>
        </div>
        <div className={styles.header__content__user}>
          <p onClick={HandleHola}>{iniciales}</p>
          <ul className={styles.menu}>
            <li className={styles.menu__menuItem}>
              <button
                className={isVisibleClose ? styles.visibleButton : styles.noVisibleButton}
                disabled={false}
                onClick={handlerSignOut}
              >
                <img src={IconClose}></img>
                Cerrar Sesión
              </button>
            </li>
          </ul>
        </div>

      </section>
    </header>
  )
}

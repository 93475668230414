export const formatCountryOrState = (str: string) => {
  const word = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  let arrayToFill: string[] = []
  const wordToArray = word.split(' ')
  wordToArray.forEach(item => {
    const initialItem = item.substring(0, 1);
    const restItem = item.substring(1);
    let itemFormat = '';
    switch (item) {
      case 'De':
        itemFormat = item.replace(initialItem, initialItem.toLowerCase())
        break;
      case 'de':
        itemFormat = item
        break;
      case 'Del':
        itemFormat = item.replace(initialItem, initialItem.toLowerCase())
        break;
      case 'del':
        itemFormat = item
        break;
      case 'Y':
        itemFormat = item.replace(initialItem, initialItem.toLowerCase())
        break;
      case 'y':
        itemFormat = item
        break;
      case 'And':
        itemFormat = item.replace(initialItem, initialItem.toUpperCase())
        break;
      case 'and':
        itemFormat = item.replace(initialItem, initialItem.toLowerCase())
        break;
      default:
        itemFormat = item.replace(initialItem, initialItem.toUpperCase())
    }
    itemFormat = itemFormat.replace(restItem, restItem.toLowerCase())
    arrayToFill.push(itemFormat)
  })

  let wordFormat = arrayToFill.toString()
  wordFormat = wordFormat.replaceAll(',', ' ')

  return wordFormat
}
import { AButton, AImage, ATextItem } from 'components/atoms';
import MobileNoCurrentActivity from 'components/organisms/MobileNoCurrentActivity/MobileNoCurrentActivity.component';
import { useState } from 'react';
import { relojUpsIcon, showMoreIcon } from 'utils/assets';
import './notificationPopUp.scss'

interface IProps  {
    title?: string;
    showMoreData?: any;
    showMoreStatus?: any;
    showMoreText?: string;
    warningNotification?: boolean;
    dangerNotification?: boolean;
    help?: boolean;
    noActivity?: boolean;
}

export const NotificationPopUp = ({ title, showMoreData, showMoreStatus, showMoreText, warningNotification, dangerNotification, help, noActivity }: IProps) => {

    const [showMore, setShowMore] = useState<boolean>(false);
    const [showMoreActive, setShowMoreActive] = useState<boolean>(false);

    return (
        <section className='notificationPopUp'>   

            <div className={`notificationPopUp__container-image ${warningNotification && 'notificationPopUp__container-image--warning'} ${dangerNotification && 'notificationPopUp__container-image--danger'}`}>
                <AImage
                    className="notificationPopUp__image"
                    url={relojUpsIcon}
                    alt="notificationPopUp"
                />
            </div>

            <div className={`${!noActivity ? 'notificationPopUp__texts-container' : 'notificationPopUp__texts-container notificationPopUp__texts-container--noText'}`}>
                
                <p className='notificationPopUp__title'>
                    {title}
                </p>
               

                { (!showMoreStatus && !showMoreData && showMoreText && showMore && help) ? 
                    <ATextItem className='notificationPopUp__showMore-text' text={showMoreText} item='' help/>
                : (!showMoreStatus && !showMoreData && showMoreText && showMore) &&
                    <p className='notificationPopUp__showMore-text'>{showMoreText}</p>
                }
            </div>
            
            

            { noActivity &&(
                <AButton
                    className="notificationPopUp__showMore"
                    tag="a"
                    handleClick={() => {
                        setShowMoreActive(true);
                    }}
                >
                    Ver más
                </AButton>
            )}

            { (showMoreStatus || showMoreData) && showMoreActive && (
                <MobileNoCurrentActivity
                    handleClick={() => {
                        setShowMoreActive(false);
                    }}
                    dataStatus={showMoreStatus}
                    dataWaiting={showMoreData}
                />
            )}  

            <div className='notificationPopUp__arrow-wrap'>
                {showMoreText &&
                    <AButton
                        tag="a"
                        className="notificationPopUp__arrowDown-button"
                        handleClick={() => {
                            setShowMore(!showMore);
                        }}
                    >
                        <AImage
                            className={`${!showMore ? 'notificationPopUp__arrowIcon' : 'notificationPopUp__arrowIcon notificationPopUp__arrowIcon--up'}`}
                            url={showMoreIcon}
                            alt="Botón para expandir"
                        />
                    </AButton>
                }
            </div>

        </section>
    )
}
import { RestorePasswordForm } from 'components/organisms';
import { LogoPragma, AImage } from 'components/atoms';
import './RestorePasswordPage.scss';
import { lock } from 'utils/assets';
import { selectTranslations } from 'store/translation/translation.slice';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'hooks/useTranslation';


export const RestorePasswordPage: React.FC = () => {
    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);
    
    return(
    <main className="template-authentication">
        <div className='template-authentication__wrapper'>
            <div className='template-authentication__container'>
                <div className="template-authentication__logo-pragma">
                    <LogoPragma />
                </div>

                <div className="template-authentication__wrapper__icon">
                <AImage
                    role='lock'
                    className='template-authentication__icon'
                    url={lock}
                    alt='lock'
                />
                </div>

                <p className="template-authentication__title">
                    {t("titleRecoverPassword")}
                </p>


                <p className="template-authentication__subtitle">
                  {t('recoverPassword')}
                </p>
                <RestorePasswordForm />
            </div>
        </div>
    </main>
    )
};

import React, { useMemo, createContext, useContext } from 'react';

const externalCodeContext = createContext({
  countryOfResidence: '',
  departmentOfResidence: '',
  cityOfResidence: '',
  countryOfBirth: '',
  departmentOfBirth: '',
  setCountryOfResidence: (country: string) => {},
  setDepartmentOfResidence: (department: string) => {},
  setCityOfResidence: (city: string) => {},
  setCountryOfBirth: (country: string) => {},
  setDepartmentOfBirth: (department: string) => {},
});

export function useExternalCodeContext() {
  return useContext(externalCodeContext);
}

export function ExternalCodesProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [countryOfResidence, setCountryOfResidence] = React.useState('');
  const [departmentOfResidence, setDepartmentOfResidence] = React.useState('');
  const [cityOfResidence, setCityOfResidence] = React.useState('');
  const [countryOfBirth, setCountryOfBirth] = React.useState('');
  const [departmentOfBirth, setDepartmentOfBirth] = React.useState('');

  const value = useMemo(
    () => ({
      countryOfResidence,
      departmentOfResidence,
      cityOfResidence,
      countryOfBirth,
      departmentOfBirth,
      setCountryOfResidence,
      setDepartmentOfResidence,
      setCityOfResidence,
      setCountryOfBirth,
      setDepartmentOfBirth,
    }),
    [
      countryOfResidence,
      departmentOfResidence,
      cityOfResidence,
      countryOfBirth,
      departmentOfBirth,
    ]
  );

  return (
    <externalCodeContext.Provider value={value}>
      {children}
    </externalCodeContext.Provider>
  );
}

import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useGetListDocumentTypeQuery } from 'services/talento';

interface DocumentTypeSelectProps extends Omit<SelectProps, 'options'> {
  country: string;
}

export function DocumentTypeSelect({
  country,
  ...rest
}: Readonly<DocumentTypeSelectProps>) {
  const { data } = useGetListDocumentTypeQuery<{
    data: { data: { id: number; value: string }[] };
  }>({ country });

  const options = data?.data?.map((documentType) => ({
    value: documentType.value,
    label: documentType.value,
  }));

  if (!options) return <Select options={[]} {...rest} />;

  return <Select options={options} {...rest} />;
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AuthenticationState } from 'utils/interfaces';

export const initialStateAuth: AuthenticationState = {
  formType: 'restorePassword',
  loginForm: {
    email: '',
    password: ''
  },
  restorePasswordForm: {
    email: '',
  },
  confirmationCodeForm: {
    confirmationCode: '',
  },
  confirmNewPasswordForm: {
    newPassword: '',
    confirmNewPassword: '',
  },
  helpCommentForm: {
    textArea: '',
  },
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialStateAuth,
  reducers: {
    setFormType(
      state,
      action: PayloadAction<
        'login' | 'restorePassword' | 'confirmationCode' | 'confirmNewPassword' | 'helpCommentForm'
      >
    ) {
      state.formType = action.payload;
    },
    setLoginForm(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      const { name, value } = action.payload;
      state.loginForm = {
        ...state.loginForm,
        [name]: value,
      };
    },
    resetLoginForm(state) {
      state.loginForm = {
        ...state.loginForm,
        password: '',
      };
    },
    resetLoginFormFull(state) {
      state.loginForm = {
        ...state.loginForm,
        email: '',
        password: ''
      }
    },

    setRestorePasswordForm(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      const { name, value } = action.payload;
      state.restorePasswordForm = {
        ...state.restorePasswordForm,
        [name]: value,
      };
    },
    resetRetorePasswordForm(state) {
      state.restorePasswordForm = initialStateAuth.restorePasswordForm;
    },

    setConfirmationCodeForm(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      const { name, value } = action.payload;
      state.confirmationCodeForm = {
        ...state.confirmationCodeForm,
        [name]: value,
      };
    },
    resetConfirmationCodeForm(state) {
      state.confirmationCodeForm = initialStateAuth.confirmationCodeForm;
    },

    setConfirmNewPasswordForm(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      const { name, value } = action.payload;
      state.confirmNewPasswordForm = {
        ...state.confirmNewPasswordForm,
        [name]: value,
      };
    },
    resetConfirmNewPasswordForm(state) {
      state.confirmNewPasswordForm = initialStateAuth.confirmNewPasswordForm;
    },

    setHelpCommentForm(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      const { name, value } = action.payload;
      state.helpCommentForm = {
        ...state.helpCommentForm,
        [name]: value,
      };
    },
    resetHelpCommentForm(state) {
      state.helpCommentForm = initialStateAuth.helpCommentForm;
    },

  },
});

export const {
  setFormType,
  setLoginForm,
  resetLoginForm,
  resetLoginFormFull,
  setRestorePasswordForm,
  resetRetorePasswordForm,
  setConfirmationCodeForm,
  resetConfirmationCodeForm,
  setConfirmNewPasswordForm,
  resetConfirmNewPasswordForm,
  setHelpCommentForm,
  resetHelpCommentForm,
} = authenticationSlice.actions;

export const selectLoginForm = (state: RootState) =>
  state.authentication.loginForm;
export const selectRestorePasswordForm = (state: RootState) =>
  state.authentication.restorePasswordForm;
export const selectConfirmationCodeForm = (state: RootState) =>
  state.authentication.confirmationCodeForm;
export const selectConfirmNewPasswordForm = (state: RootState) =>
  state.authentication.confirmNewPasswordForm;
export const selectHelpCommentForm = (state: RootState) =>
  state.authentication.helpCommentForm;
export const selectFormType = (state: RootState) =>
  state.authentication.formType;

export default authenticationSlice.reducer;

import { SPECIFICATIONS_APROX } from 'utils/constants';

import './Tooltip.scss';

interface IProps {
  icon: JSX.Element[] | JSX.Element;
  text: string;
  variant?: 'white' | 'purple';
}

export const Tooltip = ({ icon, text, variant = 'white' }: IProps) => {
  const formattedText = text.replace(/,/g, '<br/>');
  const tooltipText =
    variant === 'white'
      ? `<strong>${SPECIFICATIONS_APROX}</strong> ${formattedText}`
      : formattedText;

  return (
    <div
      className="tooltip__container"
      role="tooltip"
      data-testid="tooltip__container"
    >
      {icon}
      <div
        className={`tooltip__text tooltip__text--${variant}`}
        dangerouslySetInnerHTML={{ __html: tooltipText }}
      />
    </div>
  );
};

import { Navigate } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

interface IProps {
  children: JSX.Element | JSX.Element[];
  isLogged: boolean;
}

export const PublicRoutes: React.FC<IProps> = ({
  children,
  isLogged,
}: IProps) => (
  <>
    {isLogged ? <Navigate to={ROUTES.DASHBOARD_ROUTES.HOME.ROUTE} /> : children}
  </>
);

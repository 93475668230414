import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { RootState } from 'store/store';
import { ICity, ICountry, ICustomError, IDepartment } from 'utils/interfaces';
import COUNTRIES_FAILING from './simulator-jsons/countries-failing.json';
import { getToken } from './token';

const url = process.env.REACT_APP_BITACORA_URL || '';

export const generalApi = createApi({
  reducerPath: 'generalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: async (headers, { getState }) => {
      // Set AuthToken
      try {
        const authToken = await getToken();
        headers.set('Authorization', authToken);
      } catch (error) {}

      // Set languageCode
      const languageCode =
        (getState() as RootState).translations.languageCode || 'es';
      headers.set('Accept-language', `${languageCode}`);

      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, ICustomError, {}>,
  endpoints: (builder) => ({
    getCountries: builder.query<ICountry[], void>({
      query: () => `/administrador-catalogo/pais`,
      transformResponse: (response: { dato: ICountry[] }) => {
        return response.dato.filter(
          (item) => item.sortname && !COUNTRIES_FAILING.includes(item.sortname)
        );
        // return response.dato
      },
    }),
    getDepartmentsByCountry: builder.query<IDepartment[], number | undefined>({
      query: (countryId) =>
        `/administrador-catalogo/departamentos?paisId=${countryId}`,
      transformResponse: (response: { dato: IDepartment[] }) => response.dato,
    }),
    getCitiesByDepartment: builder.query<ICity[], number | undefined>({
      query: (stateId) => `/administrador-catalogo/ciudad?stateId=${stateId}`,
      transformResponse: (response: { dato: ICity[] }) => response.dato,
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetDepartmentsByCountryQuery,
  useGetCitiesByDepartmentQuery,
} = generalApi;

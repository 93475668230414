import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import useCatalog from 'hooks/useCatalogs/useCatalogs';
import { useGetGendersQuery } from 'services/sap';
import { catalogoLenguage, catalogoPersonal } from 'utils/helpers/dictionaries';

interface DistrictSelectProps extends Omit<SelectProps, 'options'> {
  city: string;
  country: string;
}

export default function DistrictSelect({
  city,
  ...rest
}: Readonly<DistrictSelectProps>) {
  const { data } = useGetGendersQuery<{
    data: { externalCode: string; label_es_ES: string }[];
  }>({
    catalogName: catalogoPersonal.DISTRITO_PER,
    languages: catalogoLenguage.ESyUSyISOyId,
    father: city,
  });

  const options = data?.map((city) => ({
    value: city.label_es_ES,
    label: city.label_es_ES,
  }));

  if (!options) {
    return <Select {...rest} disabled options={[]} />;
  }

  return <Select options={options} {...rest} />;
}

import axios from "axios";
import { typeAlert } from "components/molecules/ModalAlert/ModalAlert.components";
import { ERROR_SAP_HIRING, ERROR_UPDATING_SAP_HIRING } from "utils/constants/generalsConstans";
import { catalogoLenguage, catalogoPersonal } from "utils/helpers/dictionaries";
import { GetEntityResponse } from "utils/interfaces/sap.interfaces";

export async function fetchEmployeeInfo(idNumber: string) {
    const { data } = await axios.get<GetEntityResponse[]>(`${process.env.REACT_APP_SAP_URL}`, {
        params: {
            value: 'EmpEmployment',
            filter: 'name',
            select: `personNav/nationalIdNav/nationalId eq '${idNumber}' and userNav/status in 't','f'`,
            expand: 'userNav',
        },
    });
    
    const employeeInfo = {
        originalStartDateMilliseconds: undefined as number | undefined,
        externalCode: undefined as string | undefined,
    }
    if (data.length !== 0) {
        const originalStartDateMilliseconds = data[0].originalStartDate.match(/\d+/g);
        employeeInfo.originalStartDateMilliseconds = originalStartDateMilliseconds ? parseInt(originalStartDateMilliseconds[0]) : 0;
        employeeInfo.externalCode = data[0].personIdExternal;
    }

    return employeeInfo;
}

export async function validUser(email: string) {
    const userEmail = `userId eq '${email?.split('@')[0]}'`
    const baseUrl = process.env.REACT_APP_SAP_URL;
    const url = `${baseUrl}?value=${catalogoPersonal.EmpEmployment}&filter=${catalogoLenguage.name}&select=${userEmail}`;
    const opciones = { method: 'GET' };
    const query = await fetch(url, opciones);
    const response = await query.json();
    return response.length != 0
};


export interface ModalOptions {
    message: string,
    type: typeAlert,
    isOpen: boolean,
    confirmButton: boolean,
    cancelButton: boolean,
    isLoading?: boolean,
}

interface UpdateStatusSapHiringProps {
    email: string;
    attempts: number;
    qualifierName: string;
    newModalOptions: (value: ModalOptions) => Promise<void>;
    updateStatusSapHiring: Function;
}

interface UpdateStatusHiringProps {
    email: string;
    attempts: number;
    updateStatusHiring: Function;
    qualifierName: string;
    newModalOptions: (value: ModalOptions) => Promise<void>;
}


export async function UpdateStatusSapHiring({ email, attempts, qualifierName, newModalOptions, updateStatusSapHiring }: UpdateStatusSapHiringProps) {
    for (let i = 0; i < attempts; i++) {
        try {
            if (await validUser(email)) return true;
            await updateStatusSapHiring({ email, qualifierName }).unwrap();
        } catch (error: any) {
            console.error(ERROR_SAP_HIRING, error);
            if (error?.data?.message?.includes('DUPLICATE_USERNAME ')) return true;
            if (error.status == 504) {
                if (await validUser(email)) return true;
            } else {
                await newModalOptions({
                    message: ERROR_UPDATING_SAP_HIRING,
                    type: 'error',
                    isOpen: true,
                    confirmButton: true,
                    cancelButton: false,
                    isLoading: false,
                });
                return false;
            }
        }
    }
    
    await newModalOptions({
        message: ERROR_UPDATING_SAP_HIRING,
        type: 'error',
        isOpen: true,
        confirmButton: true,
        cancelButton: false,
        isLoading: false,
    });
    return false;
}

export async function updateStatusHiring({ email, attempts, updateStatusHiring, qualifierName, newModalOptions }: UpdateStatusHiringProps){
    let errorMessage = '';
    for (let i = 0; i < attempts; i++) {
        try {
            await updateStatusHiring({ email, qualifierName }).unwrap();
            return true
        } catch (error: any) {
            errorMessage = error.data.message;
        }
    }
    await newModalOptions({
        message: errorMessage,
        type: 'error',
        isOpen: true,
        confirmButton: true,
        cancelButton: false,
        isLoading: false,
    });
    return false;
}
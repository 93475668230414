import { useEffect, useState } from 'react';
import { selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { AImage, AButton } from 'components/atoms';
import './Header.scss';
import { ProfileCirlceCard } from 'components/molecules/ProfileCircleCard/ProfileCircleCard.components';
import { SideMenu } from 'components/molecules/SideMenu/SideMenu.components';
import pragmaCircleLogo from 'utils/assets/images/pragmaCircleLogo.svg';
import { useWindowMobile } from 'hooks/useWindowMobile';
import { selectStages, selectUserInfo } from '../../../store/user/user.slice';
import { InfoVacant } from 'components/molecules';
import { useGetAdmissionDateQuery } from 'services/talento';
import { userStates } from 'utils/helpers';
import { ROUTES, TabbarItemPath } from 'utils/constants';
import { useLocation } from 'react-router-dom';
import { NotificationsPanel } from '../NotificationsPanel/NotificationsPanel.components';
import useGetName from 'hooks/useGetName';
interface IProps {
  hiddenItems?: TabbarItemPath[]; 
}

export const OHeader = ({ hiddenItems}: IProps) => {
  const { isMobile } = useWindowMobile(1279);
  const { t, fullName, vacantName, isLoadingVacant, firstLetterName, firstLetterSecondName} = useGetName();
  const [openInfoVacant, setOpenInfoVacant] = useState<boolean>(false);
  const { isLogged, userState, email } = useAppSelector(selectUserInfo);
  const { isLoadingStages } = useAppSelector(selectStages);
  const location = useLocation();
  const isHomeHiringPath = location.pathname.startsWith(
    ROUTES.DASHBOARD_ROUTES.HOME_HIRING.ROOT
  );
  const firstName: string | null = fullName?.split(' ')[0];
  const { data: admissionDateData, refetch: refetchAdmissionData } =
    useGetAdmissionDateQuery({ email: email });

  useEffect(() => {
    if (userState === userStates.hire && !admissionDateData) {
      refetchAdmissionData();
    }
  }, [userState]);

  

  useEffect(() => {
    if (isLogged && !isLoadingStages && !isLoadingVacant && vacantName) {
      setTimeout(() => {
        document
          .getElementById('header_logo')
          ?.classList.add('header_logo--show');
      }, 400);

      setTimeout(() => {
        document
          .getElementById('header__logo-background')
          ?.classList.add('header__logo-background--show');
      }, 1250);

      setTimeout(() => {
        document
          .getElementById('header__container')
          ?.classList.add('header__container--show');
      }, 1250);

      setTimeout(() => {
        document
          .getElementById('header__container--info')
          ?.classList.add('header__container--info--show');
      }, 1250);

      setTimeout(() => {
        document
          .getElementById('header__container--border')
          ?.classList.add('header__container--border--show');
      }, 1250);

      setTimeout(() => {
        document
          .getElementById('header__container--right')
          ?.classList.add('header__container--show');
      }, 1250);
    }
  }, [isLogged, isLoadingStages, isLoadingVacant, vacantName]);

  return (
    <>
      <InfoVacant
        isOpen={openInfoVacant}
        close={setOpenInfoVacant}
        vacantName={vacantName}
      />
      <div className="header">
        <section
          className="header__container header__container--left"
          id="header__container"
        ></section>
        <div className="header_logo" id="header_logo">
          <div
            className="header__logo-background"
            id="header__logo-background"
          ></div>

          {!isMobile ? (
            <>
              <AImage
                className="header_logo__image"
                url={pragmaCircleLogo}
                alt=""
                dataTestid="desktop-logo"
              />
              <p className="header_logo__text">
                Ingreso
                <span className="header_logo__text--modifier">Pragma</span>
              </p>
            </>
          ) : (
            <SideMenu hiddenItems={hiddenItems} />
          )}
        </div>

        <section
          className="header__container--info"
          id="header__container--info"
        >
          <header className="home__header">
            <div className="home__header__info">
              <h2 className="home__header__info__greetings">
                {t('greetings', { $1: firstName ?? fullName })}
              </h2>
              <p className="home__header__info__position">
                {admissionDateData?.data.admissionDate &&
                admissionDateData?.data.admissionHour ? (
                  <span
                    className={`home__header__info__position__link`}
                  >
                    Nos veremos el {admissionDateData.data.admissionDate} a las{' '}
                    {admissionDateData.data.admissionHour}
                  </span>
                ) : (
                  <>
                    {`${t('youAreOnYourWay')} `}
                    <AButton
                      tag="button"
                      className="home__header__info__position__link underline"
                      handleClick={() => {
                        setOpenInfoVacant(true);
                      }}
                    >
                      {isLoadingVacant ? 'loading...' : vacantName}
                    </AButton>
                  </>
                )}
              </p>
            </div>
            <ProfileCirlceCard
              name={firstLetterName}
              secondName={firstLetterSecondName}
              header
            />
            <NotificationsPanel />
          </header>
        </section>
        <section
          className="header__container header__container--right"
          id="header__container--right"
        ></section>
        <div
          className="header__container--border"
          id="header__container--border"
        ></div>
      </div>
    </>
  );
};

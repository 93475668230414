import useCatalog from 'hooks/useCatalogs/useCatalogs';
import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { catalogoLenguage, catalogoPersonal } from 'utils/helpers/dictionaries';

interface CatalogSelectProps extends SelectProps {
  catalog: catalogoPersonal;
  language?: catalogoLenguage[];
  filter?: catalogoLenguage[];
}

export default function CatalogSelect({
  catalog,
  language,
  filter,
  ...rest
}: Readonly<CatalogSelectProps>) {
  const { data, loading } = useCatalog(catalog, language, filter);
  const options: SelectProps['options'] = loading
    ? [{ label: 'Cargando', value: 'Cargando' }]
    : data.map((value) => ({ value, label: value }));

  return <Select options={options} {...rest} />;
}

import { Navigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

interface IProps {
  isLoggedAdmin: boolean;
  children: JSX.Element | JSX.Element[];
  isLogged: boolean;
}

export const PrivateRoutes: React.FC<IProps> = ({
  isLoggedAdmin,
  children,
  isLogged,
}: IProps) => (
  <>
    {isLoggedAdmin ? (
      <Navigate
        to={
          ROUTES.APP_ROUTES.ADMIN.ROOT +
          ROUTES.APP_ROUTES.ADMIN.NEW_PRAGMA_CANDIDATES
        }
      />
    ) : isLogged ? (
      children
    ) : (
      <Navigate to={ROUTES.APP_ROUTES.LOGIN} />
    )}
  </>
);

import { AButton, AImage } from 'components/atoms';
import { plus } from 'utils/assets';
import classNames from 'classnames/bind';
import styles from './AddButton.module.scss';

const cn = classNames.bind(styles);

interface AddButtonProps {
  onClick?: () => void;
  withAnimation?: boolean;
  expanded?: boolean;
}

export function AddButton({
  onClick,
  withAnimation = true,
  expanded
}: Readonly<AddButtonProps>) {
  return (
    <button
      data-testid="addButton"
      className={cn('AddButton', {
        'AddButton--withAnimation': withAnimation,
        'AddButton--expanded': expanded
      })}
      onClick={onClick}
    >
      <AImage url={plus} alt="Plus icon" />
      <span>Agregar otro</span>
    </button>
  );
}

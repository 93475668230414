
import { ConfirmNewPasswordForm, ChangeForcePassword } from 'components/organisms';
import './ConfirmNewPasswordPage.scss';

interface ICurrentUser {
  cognitoUser?: [];
  requiredAttributes?: [];
}
interface IProps {
  tittle: string;
  isFirstPassword: boolean;
  user?: ICurrentUser;
}

export const ConfirmNewPasswordPage = ({ tittle, isFirstPassword, user }: IProps) => (
  <main className={!isFirstPassword ? "template-authentication" : "template-authentication--changePass"}>
      {
        isFirstPassword ?
          <ChangeForcePassword tittle={tittle} user={user} />
        :
        <ConfirmNewPasswordForm tittle={tittle} user={user} />
      }
    </main>
);

import { AButton, AImage } from 'components/atoms';
import { useGetNoCurrentActivityQuery } from 'services/talento';
import { selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { relojUpsIcon } from 'utils/assets';
import './MobileNoCurrentActivity.scss';
import { Numbers } from 'utils/helpers/dictionaries';

interface IProps {
  handleClick?: () => {} | void;
  dataStatus?: {
    state?: number;
    texts?: {};
  };
  dataWaiting?: any;
}

export const MobileNoCurrentActivity = ({
  handleClick,
  dataStatus,
  dataWaiting,
}: IProps) => {
  const { vacant } = useAppSelector(selectVacantInfo);
  const { email } = useAppSelector(selectUserInfo);

  return (
    <section className="mobileNoCurrentActivity--bg">
      <div
        className="mobileNoCurrentActivity"
        data-testid="noCurrentActivityMobile"
      >
        {dataStatus &&
        dataStatus.state &&
        dataStatus.state > 3 &&
        dataStatus.state < 9
          ? dataStatus.texts instanceof Array &&
            dataStatus.texts.map((item) => {
              const actionObject = JSON.parse(item.action);
              if (item.tab == 'title') {
                const { text } = actionObject;
                return (
                  <p key={item.id} className="mobileNoCurrentActivity__title">
                    {text}
                  </p>
                );
              }
            })
          : dataWaiting?.data &&
            dataWaiting?.data instanceof Array &&
            dataWaiting?.data.map((item: any) => {
              const actionObject = JSON.parse(item.action);
              if (item.tab == 'title') {
                const { text } = actionObject;
                return (
                  <h2 key={item.id} className="mobileNoCurrentActivity__title">
                    {text}
                  </h2>
                );
              }
            })}

        {dataStatus &&
        dataStatus.state &&
        dataStatus.state > 3 &&
        dataStatus.state < 9 ? (
          <></>
        ) : (
          <AImage
            className="mobileNoCurrentActivity__image"
            url={relojUpsIcon}
            alt="noCurrentActivity"
          />
        )}

        {dataStatus &&
        dataStatus.state &&
        dataStatus.state > 3 &&
        dataStatus.state < 9
          ? dataStatus &&
            dataStatus.texts instanceof Array &&
            dataStatus.texts.map((item) => {
              const actionObject = JSON.parse(item.action);
              if (item.tab == 'description') {
                const { text } = actionObject;
                return (
                  <p key={item.id} className="mobileNoCurrentActivity__text">
                    {text}
                  </p>
                );
              }
            })
          : dataWaiting?.data &&
            dataWaiting?.data instanceof Array &&
            dataWaiting?.data.map((item: any) => {
              const actionObject = JSON.parse(item.action);
              if (item.tab == 'description') {
                const { text } = actionObject;
                return (
                  <p key={item.id} className="mobileNoCurrentActivity__text">
                    {text}
                  </p>
                );
              }
            })}

        {dataStatus && dataStatus.state === 6 && (
          <>
            {dataStatus.texts instanceof Array &&
            dataStatus.texts[0].parkedVip === null ? (
              <div className="mobileNoCurrentActivity__study">
                {dataStatus.texts[0].icons.map((item: any) => (
                  <div
                    key={item.id}
                    className="mobileNoCurrentActivity__wrapperStudy"
                  >
                    <AImage
                      className="mobileNoCurrentActivity__imageStudy"
                      url={item.urlIcon}
                      alt="pragmaUniverse"
                    />
                    <a
                      className="mobileNoCurrentActivity__link"
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {item.name}
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <a
                  className="buttonParked"
                  href={
                    dataStatus.texts instanceof Array &&
                    dataStatus.texts[Numbers.zero].parkedVip
                      ? dataStatus.texts[Numbers.zero].parkedVip
                      : ''
                  }
                >
                  Conoce más
                </a>
              </div>
            )}
            <AButton
              className="button-primary--white mobileNoCurrentActivity__button"
              tag="a"
              handleClick={handleClick}
            >
              Volver al inicio
            </AButton>
          </>
        )}
      </div>
    </section>
  );
};

export default MobileNoCurrentActivity;

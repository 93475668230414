import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useGetGendersQuery } from 'services/sap';
import { catalogoPersonal, catalogoLenguage } from 'utils/helpers/dictionaries';

interface CivilStatusSelectProps extends Omit<SelectProps, 'handleChange'> {
  handleChange: (value: { value: string; valueEn: string }) => void;
}

const allowedCivilStatus = ['Soltero/a', 'Casado/a', 'Cohabitación'];

export default function CivilStatusSelect({
  handleChange,
  ...rest
}: Readonly<CivilStatusSelectProps>) {
  const { data: civilStatus, isLoading } = useGetGendersQuery<{
    data: {
      label_en_US: string;
      label_es_ES: string;
    }[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.ecMaritalStatus,
    languages: catalogoLenguage.ESyUS,
  });

  const options = civilStatus
    ?.filter(({ label_es_ES }) => allowedCivilStatus.includes(label_es_ES))
    .map(({ label_es_ES }) => ({
      value: label_es_ES,
      label: label_es_ES,
    }));

  useEffect(() => {
    if (isLoading) return;

    const currentSelectedStatus = rest.value?.value;

    const matchedCivilStatus = civilStatus.find(
      ({ label_es_ES }) => label_es_ES === currentSelectedStatus
    );

    handleChange({
      value: currentSelectedStatus ?? '',
      valueEn: matchedCivilStatus?.label_en_US ?? '',
    });
  }, [isLoading]);

  return (
    <Select
      handleChange={(value) => {
        const selectedContract = civilStatus?.find(
          ({ label_es_ES }) => label_es_ES === value
        );
        return handleChange({
          value: value?.toString() ?? '',
          valueEn: selectedContract?.label_en_US ?? '',
        });
      }}
      disabled={isLoading}
      options={options}
      {...rest}
    />
  );
}

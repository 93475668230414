import React from 'react';
import { RouteProps, Outlet, Navigate } from 'react-router-dom';

import { ROUTES } from 'utils/constants';

interface IPrivateRouteProps extends RouteProps {
  redirectPath: string;
  isAuthenticated: boolean;
}

const PrivateRoutesAdmin: React.FC<IPrivateRouteProps> = ({
  redirectPath = ROUTES.APP_ROUTES.ADMIN.ROOT,
  isAuthenticated,
}) => {
  if (isAuthenticated) return <Outlet />;

  return <Navigate to={redirectPath} />;
};

export default PrivateRoutesAdmin;

import { Title } from "components/atoms/Title/Title";
import { Description } from "components/atoms/Description/Description";
import style from "./FieldSet.module.scss"
interface Props {
    children: React.ReactNode
    title?: string
    description?: string
}
export const FieldSet = ({children, title, description}: Props) => {
    return <fieldset className={style.FieldSet}>
            {title && <Title>{title}</Title>}
            {description && <Description description={description} />}
            {children}
        </fieldset>;
}
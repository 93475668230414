import { AImage } from 'components/atoms';
import './InactiveUser.scss';
import { Numbers } from 'utils/helpers/dictionaries';

interface IProps {
  data: {
    state?: number;
    texts?: {};
  };
}

export const InactiveUser = ({ data }: IProps) => {
  return (
    <article className="inactiveUser">
      <div className="inactiveUser__info">
        {data.texts instanceof Array &&
          data.texts.map((item) => {
            const actionObject = JSON.parse(item.action);
            if (item.tab == 'title') {
              const { text } = actionObject;
              return (
                <p
                  data-testid="p__title"
                  key={item.id}
                  className="inactiveUser__title"
                >
                  {text}
                </p>
              );
            }
          })}
        {data.texts instanceof Array &&
          data.texts.map((item) => {
            const actionObject = JSON.parse(item.action);
            if (item.tab == 'description') {
              const { text } = actionObject;
              return (
                <p
                  data-testid="p__text"
                  key={item.id}
                  className={
                    data.texts instanceof Array &&
                    data.texts[Numbers.zero].parkedVip === null
                      ? 'inactiveUser__text'
                      : 'inactiveUser__textParkedVip'
                  }
                >
                  {text}
                </p>
              );
            }
          })}
      </div>
      {data.state === Numbers.six && (
        <>
          {data.texts instanceof Array &&
          data.texts[Numbers.zero].parkedVip === null ? (
            <div className="inactiveUser__study">
              {data.texts instanceof Array &&
                data.texts[Numbers.zero].icons.map((item: any) => {
                  return (
                    <div key={item.id} className="inactiveUser__wrapperStudy">
                      <AImage
                        className="inactiveUser__image"
                        url={item.urlIcon}
                        alt="pragmaUniverse"
                      />
                      <a
                        className="inactiveUser__link"
                        href={item.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {item.name}
                      </a>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div>
              <a
                className="buttonParked"
                href={
                  data.texts instanceof Array &&
                  data.texts[Numbers.zero].parkedVip
                    ? data.texts[Numbers.zero].parkedVip
                    : ''
                }
              >
                Conoce más
              </a>
            </div>
          )}
        </>
      )}
    </article>
  );
};

import {
  FC,
  InputHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  forwardRef,
} from 'react';
import { Label } from 'components/atoms';
import { ReactComponent as WarningIcon } from 'utils/assets/images/warningIcon.svg';
import classnames from 'classnames/bind';
import styles from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  register?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  subtext?: string;
}

const cx = classnames.bind(styles);

type Ref = HTMLInputElement;

export const Input: FC<InputProps> = forwardRef<Ref, InputProps>(function Input(
  {
    id,
    label,
    onChange,
    onBlur,
    register,
    success,
    error = false,
    disabled = false,
    subtext,
    ...rest
  },
  ref
) {
  const inputClasses = cx({
    'input-error': error,
    'input-success': success,
    disabled: disabled,
  });

  return (
    <div
      className={`${styles['input-wrapper']} ${rest.className}`}
      data-testid="container__input"
    >
      <Label text={label} htmlFor={id} />
      <input
        id={id}
        data-testid="testIdInput"
        className={inputClasses}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        {...rest}
        {...(register && {
          ...register(id, { onChange: onChange, onBlur: onBlur }),
        })}
      />

      <div className={styles.subtext}>
        <pre
          className={`${
            error ? styles['subtext-error'] : styles['input-subtext']
          }`}
        >
          <WarningIcon />

          {subtext}
        </pre>
      </div>
    </div>
  );
});

import { Modal } from 'components/organisms';
import './LoaderCataloge.scss';
import Lottie from 'lottie-react-web';
import { AnimationLoader, AnimationLoaderPurple } from 'utils/assets';

interface IProps {
  textContent: string;
}

export const LoaderCataloge = ({ textContent }: IProps) => (
  <div className="modalFont">
    <div className="modal-large">
      <div className="Cataloge">
        <div className="catalogeLoading">
          <Lottie
            options={{
              animationData: AnimationLoader,
            }}
          ></Lottie>
        </div>
        <div className="catalogeText">
          <p>{textContent}</p>
        </div>
      </div>
    </div>
  </div>
);

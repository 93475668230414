import {
  selectConfirmationCodeTimer,
  reduceConfirmationCodeTimer,
  setConfirmationCodeTimer,
} from 'store/timer/timer.slice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectRestorePasswordForm } from '../../../store/authentication/authentication.slice';

import { useResendCodeMutation } from 'services/talento';

import { AButton, CounterDown } from 'components/atoms';

import './ReSendEmail.scss'

export const ReSendEmail: React.FC = () => {
  const dispatch = useAppDispatch();
  const [resendCode, { error }] = useResendCodeMutation();
  const { value } = useAppSelector(selectConfirmationCodeTimer);
  const { email } = useAppSelector(selectRestorePasswordForm);

  const handleClick = async () => {
    if (value === 0) {
      dispatch(setConfirmationCodeTimer(150000));
      await resendCode({ email });
    }
  };

  return (
    <div className="resend-email">

      <AButton
        className={`button-secondary--white ${value > 0 ? 'button-dis' : 'button'}`}
        tag='button'
        type="button"
        handleClick={handleClick}
      >
        <p>Reenviar código  {value > 0 && (
            <CounterDown
              timer={value}
              reduceTimerMethod={reduceConfirmationCodeTimer}
            />
          )}
        </p>
      </AButton>
    </div>
  );
};

import React from 'react'
import { ContentNotFound } from 'components/atoms/ContentNotFound/ContentNotFound.components'
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import './SupportBoundary.scss';
interface Error {
    support?: boolean
}

const SupportBoundary = ({ support }: Error) => {

    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);

    return (

        <div className='support-boundary' data-testid="container__error">
            <div className='support-boundary__content'>

                <div className='support-boundary__span-container'>
                    <span className='support-boundary__span'>
                    </span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                    <span className='support-boundary__span'></span>
                </div>


                <div className='support-boundary__eMantenimiento'>
                    <ContentNotFound error={t("maintenanceMessage")} />
                </div>

            </div>
        </div>

    )


}

export default SupportBoundary
import { useTranslation } from 'hooks/useTranslation';
import { useState, useEffect, Fragment } from 'react';
import { selectUserInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { IATextWithAtoms } from 'utils/interfaces';
import { AButton } from '../AButton/AButton.components';
import { AImage } from '../AImage/AImage.components';
import { AText } from '../AText/AText.components';
import './ATextWithAtoms.scss';

const findAtom = (slice: string, list: []): any => {
  const idToSearch = slice.slice(1, slice.length)
  const element = list.find((el: any) => el.id == idToSearch) || {}
  return element
}

export const ATextWithAtoms = ({ text, links = [], icons = [], item, className }: IATextWithAtoms) => {

  const [slices, setSlices] = useState<string[]>([])
  const { email } = useAppSelector(selectUserInfo);
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  
  useEffect(() => {
    setSlices(text.split(' '))
  }, [setSlices]);

  return (
    <p className={`activityAtomTextWithAtoms ${className}`}>
      { item && item }
      {
        slices.map((slice, index) => {
          if (slice[0] === '$') {
            const { url = '--', title = '--' } = findAtom(slice, links)
            return (
              <Fragment key={index}>
                <AButton tag='a' url={url} title={title} target='_blank' rel='noopener noreferrer' />
                &nbsp;
              </Fragment>
            )
          } else if (slice[0] === '#') {
            const { url = '' } = findAtom(slice, icons)
            return (
              <Fragment key={index}>
                <AImage alt='' role='presentation' url={url} height={20} width={20} />
                &nbsp;
              </Fragment>
            )
          } else if (slice[0] === '€') {
            return (
              <Fragment key={index}>
                <span>{email}</span>
              </Fragment>
            )
          }else if(slice[0] === '%'){
            const emailHelp = t('emailHelp');

            return(
              <Fragment key={index}>
                <span className='activityAtomTextWithAtoms__emailHelp'>{emailHelp}</span>
              </Fragment>
            )
          }
          else {
            return slice + ' '
          }
        })
      }
    </p>
  )
}

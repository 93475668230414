import { createPortal } from 'react-dom';
import './Modal.scss';
import { ReactComponent as CloseIconModal } from 'utils/assets/images/closeIconModal.svg';

interface IProps {
  isVisible: boolean;
  width?: string;
  children: React.ReactNode;
}

export const Modal = ({ isVisible, width, children }: IProps) => {
  if (!isVisible) return <></>;

  const container = document.body;

  return createPortal(
    <div className="modal__backdrop">
      <div></div>
      <div
        className="modal"
        style={{ rowGap: '0', width: width }}
      >
        {children}
      </div>
    </div>,
    container
  );
};

import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env;


if (
  !REACT_APP_FIREBASE_API_KEY ||
  !REACT_APP_FIREBASE_AUTH_DOMAIN ||
  !REACT_APP_FIREBASE_PROJECT_ID ||
  !REACT_APP_FIREBASE_STORAGE_BUCKET ||
  !REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
  !REACT_APP_FIREBASE_APP_ID
) {
  console.error("Missing environment variables for Firebase configuration");
}

const firebaseConfig: FirebaseOptions = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
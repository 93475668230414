import React from 'react';
import { ContentNotFound } from 'components/atoms/ContentNotFound/ContentNotFound.components';
import { AButton } from 'components/atoms';
import './ErrorBoundary.scss';

interface IIProps {
  children?: JSX.Element | JSX.Element[];
}

interface Istate {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IIProps, Istate> {

  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
      return { hasError: true };
  }

  render() {if (this.state.hasError) {
      return (
        <div className='error-boundary' data-testid="container__error">
          <div className='error-boundary__content'>

            <div className='error-boundary__span-container'>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
              <span className='error-boundary__span'></span>
            </div>


            
            <ContentNotFound error={"Ha ocurrido un error"} />
            <AButton className='button-primary--white error-boundary__button' tag='a' url='/login'>Volver al inicio</AButton>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

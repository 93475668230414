import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import './TempElement.scss';

function TempElement() {

    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);

    return (
        <div className='wrapper-card' data-testid="wrapper">
            <h2 className='wrapper-card__title'>{t('cardTitleCool')}</h2>
            <p className='wrapper-card__comment'>{t('finishStages')}</p>
        </div>
    )
}

export default TempElement
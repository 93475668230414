export enum buttons {
  buttonEditar = 'Seguir editando',
  exit = 'Salir',
  aceptar = 'Aceptar',
  enviar = 'Enviar',
  cancelar = "Cancelar"
}

export enum modal {
  ups = '¡Ups!',
  errorConfirm = 'No fue posible confirmar tus datos, intentalo de nuevo más tarde.',
  super = '¡Súper!',
  exitConfirm = '¡Has personalizado tu kit!',
  sendInformation = 'Enviando tu información',
  reloadPage = 'Ha ocurrido un error, por favor recarga la página.',
  OPTION_SALARIAL_COMPLETED = 'Has elegido la',
  OPTION_SALARIAL_COMPLETED_FINAL = ' Podrás consultar los detalles en cualquier momento '
}

export const MODAL_FIXED_SALARY_OPTION_DESCRIPTION = 'opción salarial';

export const IN_REVIEW = 'En revisión';
export const LETS_GO = 'Vamos';

export const ERROR_SAP_HIRING = 'Error updating sap hiring';

export const ERROR_UPDATING_SAP_HIRING =
  'Error al intentar actualizar el estado de la contratación en SAP';

export const MODAL_SALARY_MESSAGE = 'A continuación encontrarás todos los detalles para que puedas revisarlos y elegir la opción que mejor se adapte a tus expectativas.';

export const MODAL_SALARY_ALERT_MESSAGE = 'No olvides elegir una opción para continuar tu proceso';

export const MODAL_TITLE_REJECTED = '¿Deseas rechazar la oferta?';

export const MODAL_DESCRIPTION_REJECTED = "Le comunicaremos al equipo de talento tu decisión para que te contacten"

export const INFO_OUTLINED_ICON = 'https://d30vbcfgen7dgz.cloudfront.net/static-files/general-icons/info-outlined.svg';

export const PENCIL_EDIT_FILLED_ICON = 'https://d30vbcfgen7dgz.cloudfront.net/static-files/general-icons/pencil-edit-filled.svg';

export const X_RED_FILLED_ICON = 'https://d30vbcfgen7dgz.cloudfront.net/static-files/general-icons/x-red-filled.svg';

export const PREVIOUS_LOCATION = -1;

export enum SIGN_TEXT  {
  title="¿Aun tienes Preguntas?",
  subtitle='Si tienes dudas o necesitas mas informacion, contacta a el lider de tu proceso',
  setSignError="Hubo un error al obtener la firma"
}

export const typeVideo= "video/mp4"

export const altLider = "imagen del lider de proceso"

export const REMOVE_WORDS_REGEX = /(\bde\b|\blos\b|\blas\b|\bdel\b)/gi;

export const EXTRA_SPACES_REGEX = /\s{2,}/g;

export const NON_DIGIT_REGEX = /\D/g;

export const NON_ALPHANUMERIC_OR_SPECIAL_REGEX = /[^A-Za-z0-9+/=]/g;

export const DATA_URL_PREFIX = 'data:image/png;base64,';
import { useNavigate } from 'react-router-dom';

import { BorderStageSelected2, checkContract, lockCircle } from 'utils/assets';
import { activities } from 'utils/helpers';
import { IN_REVIEW, LETS_GO } from 'utils/constants/generalsConstans';
import { STATES } from 'utils/helpers/dictionaries';

import { AButton } from '../AButton/AButton.components';
import { AImage } from '../AImage/AImage.components';

import './StageCardLarge.scss';

interface StageCardLarge {
  icon: string;
  title: string;
  text?: string;
  link: string;
  isDisabled?: boolean;
  status?: string;
  activityType?: string;
  key?: number;
  isClicked?: boolean;
  contract?: string;
}

export const StageCardLarge = ({
  icon,
  title,
  text,
  link,
  isDisabled,
  status,
  activityType,
  key,
  isClicked,
  contract,
}: StageCardLarge) => {
  const navigate = useNavigate();
  const tiposDeContrato: string[] = [
    'Término Fijo-COL',
    'Término Indefinido-COL',
    'Aprendizaje Etapa Productiva-COL',
    'Aprendizaje Etapa Lectiva-COL',
  ];
  const validContract = contract ? contract : '';
  const isValidContract = tiposDeContrato.includes(validContract);
  return (
    <div
      className={
        isDisabled
          ? 'stage-card-large stage-card-large__disabled'
          : 'stage-card-large'
      }
    >
      {!isDisabled && isClicked && (
        <>
          <img
            src={BorderStageSelected2}
            alt="stage-border"
            className="stage--selected-span stage--selected-span--1"
          />
          <img
            src={BorderStageSelected2}
            alt="stage-border"
            className="stage--selected-span stage--selected-span--2"
          />
          <img
            src={BorderStageSelected2}
            alt="stage-border"
            className="stage--selected-span stage--selected-span--3"
          />
          <img
            src={BorderStageSelected2}
            alt="stage-border"
            className="stage--selected-span stage--selected-span--4"
          />
        </>
      )}
      <div className="stage-card-large__icon">
        <img src={icon} alt={title} />
      </div>
      <div className="stage-card-large__info">
        <h2 className="stage-card-large__info-title">{title}</h2>
        {text && <p className="stage-card-large__info-text">{text}</p>}
      </div>
      {(activityType == activities.essay ||
        activityType == activities.psychological_interview ||
        (isValidContract && activityType == activities.activity_seven)) && (
        <div className="stage-card-large__button">
          <AButton
            tag="button"
            disabled={status === STATES.THREE ? true : isDisabled}
            className={
              isDisabled || status === STATES.THREE
                ? 'button-secondary disabled-inverse'
                : 'button-primary button-primary--white'
            }
            handleClick={() => {
              navigate(link);
            }}
          >
            {status === STATES.THREE ? IN_REVIEW : LETS_GO}
          </AButton>
        </div>
      )}
      <div>
        {isDisabled && (
          <AImage
            alt="calendarIcon"
            url={
              status === STATES.FOUR
                ? checkContract
                : isDisabled
                ? lockCircle
                : ''
            }
            className="icon"
          />
        )}
      </div>
    </div>
  );
};

export default StageCardLarge;

export const epsList = [
  { value: 'SURA', label: 'SURA' },
  { value: 'ALIANSALUD', label: 'ALIANSALUD' },
  { value: 'SALUD TOTAL', label: 'SALUD TOTAL' },
  { value: 'CAFESALUD', label: 'CAFESALUD' },
  { value: 'SANITAS', label: 'SANITAS' },
  { value: 'COMPENSAR', label: 'COMPENSAR' },
  { value: 'COMFENALCO VALLE', label: 'COMFENALCO VALLE' },
  { value: 'SALUDCOOP', label: 'SALUDCOOP' },
  { value: 'COOMEVA', label: 'COOMEVA' },
  { value: 'FAMISANAR', label: 'FAMISANAR' },
  { value: 'S.O.S', label: 'S.O.S' },
  { value: 'CRUZ BLANCA', label: 'CRUZ BLANCA' },
  { value: 'SALUDVIDA', label: 'SALUDVIDA' },
  { value: 'NUEVA EPS', label: 'NUEVA EPS' },
  { value: 'SAVIA SALUD', label: 'SAVIA SALUD' },
  { value: 'MEDIMAS', label: 'MEDIMAS' },
  { value: 'UNIVERSIDAD DEL ATLANTICO', label: 'UNIVERSIDAD DEL ATLANTICO' },
  { value: 'UNIVERSIDAD DE SANT', label: 'UNIVERSIDAD DE SANT' },
  { value: 'UNIVERSIDAD DEL VALLE', label: 'UNIVERSIDAD DEL VALLE' },
  {
    value: 'UNIVERSIDAD NACIONAL DE COLOMBIA',
    label: 'UNIVERSIDAD NACIONAL DE COLOMBIA',
  },
  { value: 'UNIVERSIDAD CAUCA', label: 'UNIVERSIDAD CAUCA' },
  { value: 'UNIVERSIDAD DE ANTIOQUIA', label: 'UNIVERSIDAD DE ANTIOQUIA' },
  { value: 'UNIVERSIDAD DE CORDOBA', label: 'UNIVERSIDAD DE CORDOBA' },
  { value: 'UNIVERSIDAD DE NARIÑO', label: 'UNIVERSIDAD DE NARIÑO' },
  { value: 'UPTC', label: 'UPTC' },
  { value: 'UNIVERSIDAD DE CARTAGENA', label: 'UNIVERSIDAD DE CARTAGENA' },
  { value: 'COMFACOR', label: 'COMFACOR' },
  { value: 'COMPEN CHOCO', label: 'COMPEN CHOCO' },
  { value: 'COMF HUILA', label: 'COMF HUILA' },
  { value: 'COMFA NARIÑO', label: 'COMFA NARIÑO' },
  { value: 'COMFACUNDI', label: 'COMFACUNDI' },
  { value: 'CAJACOPI', label: 'CAJACOPI' },
  { value: 'CONVIDA', label: 'CONVIDA' },
  { value: 'CAFESALUD', label: 'CAFESALUD' },
  { value: 'CAPRESOCA', label: 'CAPRESOCA' },
  { value: 'CAPITAL SALUD', label: 'CAPITAL SALUD' },
  { value: 'DUSAKAWI', label: 'DUSAKAWI' },
  { value: 'INDIGENA MANEXKA', label: 'INDIGENA MANEXKA' },
  { value: 'A.I.C', label: 'A.I.C' },
  { value: 'MALLAMAS', label: 'MALLAMAS' },
  { value: 'PIJAOSALUD', label: 'PIJAOSALUD' },
  { value: 'EMDISALUD', label: 'EMDISALUD' },
  { value: 'MTUAL SER', label: 'MTUAL SER' },
  { value: 'EMSSANAR', label: 'EMSSANAR' },
  { value: 'COOSALUD', label: 'COOSALUD' },
  { value: 'ASMET SALUD', label: 'ASMET SALUD' },
  { value: 'AMBUQ', label: 'AMBUQ' },
  { value: 'ECOOPSOS', label: 'ECOOPSOS' },
  { value: 'COM GUAJIRA', label: 'COM GUAJIRA' },
  { value: 'COMFASUCRE', label: 'COMFASUCRE' },
  { value: 'ANAS WAYUU', label: 'ANAS WAYUU' },
  { value: 'COMFABOY', label: 'COMFABOY' },
  { value: 'NUEVA EPS MOVILIDAD', label: 'NUEVA EPS MOVILIDAD' },
  { value: 'COOSALUD', label: 'COOSALUD' },
  { value: 'SALUDVIDA', label: 'SALUDVIDA' },
  { value: 'COMFAORIENTE', label: 'COMFAORIENTE' },
  { value: 'MEDIMAS', label: 'MEDIMAS' },
  { value: 'SALUD MIA', label: 'SALUD MIA' },
  { value: 'FOSYGA', label: 'FOSYGA' },
];

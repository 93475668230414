import { PropsWithChildren, useEffect, useState } from 'react'
import './WelcomeModal.scss';
import { AButton, Loader } from '../../atoms';
import { useGetStagesQuery } from 'services/talento';
import { useAppSelector } from 'store/hooks';
import { selectUserInfo } from 'store';
import { IResponseStages } from 'utils/interfaces';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';

type _WelcomeModalProps = {
    setIsWelcome: (state: boolean) => void;
}

export type WelcomeModalProps = PropsWithChildren<_WelcomeModalProps>

export const WelcomeModal = ({ setIsWelcome }: WelcomeModalProps) => {
    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);
    const { email } = useAppSelector(selectUserInfo);
    const { data = {} as IResponseStages, isLoading: isLoadingStages } = useGetStagesQuery({ email });
    const descriptionCut = t("welcomeModalDescription").split('~')

    const [name, setName] = useState('')
    const [vacant, setVacant] = useState('')

    useEffect(() => {
        if (!isLoadingStages) {
            setName(data.data.fullName.split(' ')[0])
            setVacant(`vacante ${data.data.vacant}`)
        }
    }, [isLoadingStages])

    if (isLoadingStages) {
        return <Loader/>
    }

    return (
        <section className='welcome-modal__wrapper'>
            <div className='welcome-modal__header'></div>
            <div className='welcome-modal__information'>
                <div className='information__texts'>
                    <h2 className='information__title'>{`Hola ${name}`}</h2>
                    <p className='information__description'>{descriptionCut[0]}<span className='information__bold'>{vacant}</span>{descriptionCut[1]}</p>
                    <p className='information__indication'>{t("indicationWelcomeModal")}</p>
                </div>
                <div className='information__button-wrapper'>
                    <AButton 
                        tag={'button'}
                        className='button-primary welcome-modal__button'
                        handleClick={() => setIsWelcome(false)}
                    >
                        Continuar
                    </AButton>
                </div>
            </div>
        </section>
    )
}

export default WelcomeModal
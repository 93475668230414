import { AImage, AButton, Label } from 'components/atoms';
import { PropsWithChildren, useState } from 'react';
import { showMoreIcon } from 'utils/assets';
import './Accordion.scss';

type _AccordionProps = {
  title?: string | number;
  icon?: string;
  isDisabled?: boolean;
  textLabel?: string;
  htmlForLabel?: string;
  classStyle?: string;
  accordionState: boolean;
  styleName?: string;
  handleAccordion: (state: boolean) => void;
  placeholder?: string;
  forPassword?: boolean;
  error?: boolean;
};

export type AccordionProps = PropsWithChildren<_AccordionProps>;

export const Accordion = ({
  title,
  icon,
  isDisabled = false,
  textLabel,
  classStyle,
  htmlForLabel,
  accordionState,
  handleAccordion,
  children,
  placeholder,
  forPassword = false,
  error = false,
  styleName,
}: AccordionProps) => (
  <div
    className={`accordion ${isDisabled ? 'disabled' : ''} ${styleName}`}
    data-testid="accordion"
  >
    {textLabel && htmlForLabel && (
      <Label
        text={textLabel}
        htmlFor={htmlForLabel}
        className={`accordion__label ${forPassword ? 'forPassword' : ''}`}
      />
    )}

    <AButton
      className={`accordion__input ${isDisabled ? 'disabled' : ''} ${
        forPassword ? 'for-password for-password--modifier' : ''
      } ${accordionState ? 'non-for-password non-for-password' : ''} ${
        error ? 'input-error' : ''
      }`}
      disabled={isDisabled}
      tag="a"
      handleClick={() => handleAccordion(!accordionState)}
    >
      {icon && (
        <AImage
          className={`accordion__input__icon ${
            forPassword ? 'for-password' : ''
          }`}
          url={icon}
          alt="icono accordion"
        />
      )}
      <p
        className={`accordion__input__text ${!title && 'empty'} ${classStyle}`}
      >
        {title || placeholder}
      </p>
      <div className="iconContainer">
        <AImage
          className={`accordion__input__arrow  ${
            forPassword ? 'for-password help' : ''
          }  ${accordionState ? 'non-for-icon' : ''}`}
          url={showMoreIcon}
          alt="Botón para expandir el acordeon"
        />
      </div>
    </AButton>
    {accordionState && (
      <div aria-expanded={accordionState} data-testid="accordion-content">
        {' '}
        {children}{' '}
      </div>
    )}
  </div>
);

import { AImage, AButton } from 'components/atoms';
import { backIcon, backIconPurple } from 'utils/assets';
import {
  PREVIOUS_LOCATION
} from 'utils/constants/generalsConstans';
import './Back.scss';
import { useNavigate } from 'react-router-dom';

interface IProps {
  handleBack?: () => void;
  onlyMobile?: boolean;
  className?: string;
  icon?: boolean;
}

export const Back = ({ handleBack, className, icon }: IProps) => {
  const navigate = useNavigate();
  
  const handleBackDefault = () => {
    navigate(PREVIOUS_LOCATION);
  };

  return (
    <AButton
      tag="button"
      type="button"
      handleClick={handleBack ? handleBack : handleBackDefault}
      className={`backButton ${className ? className : ''}`}
    >
      <AImage
        url={icon ? backIconPurple : backIcon}
        className={'backButton__img'}
        width={9}
        height={14}
        alt=""
      />
    </AButton>
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    selectUi,
    setShowPassword,
    selectConfirmNewPasswordForm,
    setConfirmNewPasswordForm,
    setUserInfo,
    selectUserInfo,
} from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import { IConfirmNewPasswordForm, IResponseStages, IToast } from 'utils/interfaces';
import { confirmNewPasswordFormSchema } from 'utils/schemas';

import { AButton, AImage } from 'components/atoms';
import { Back } from 'components/atoms/Back/Back.components';
import { passwordHideIcon, passwordShowIcon, lockSuccess, warningIcon, errorIconParagraph } from 'utils/assets';
import { selectTranslations } from 'store/translation/translation.slice';
import './ChangeForcePassword.scss';
import { Auth } from 'aws-amplify';
import { AlertToast, WelcomeModal } from 'components/molecules';

interface ICurrentUser {
    cognitoUser?: [];
    requiredAttributes?: [];
}
interface IProps {
    tittle: string;
    user?: ICurrentUser;
}

export const ChangeForcePassword = ({ tittle, user }: IProps) => {
    const dispatch = useAppDispatch();
    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);
    const { showNewPassword, showConfirmNewPassword } = useAppSelector(selectUi);
    const userInfo = useAppSelector(selectUserInfo);
    const { newPassword, confirmNewPassword } = useAppSelector(
        selectConfirmNewPasswordForm
    );
    const [modal, setModal] = useState<IToast>({ isOpen: false, text: "" })
    const [isDesktop, setIsDesktop] = useState<boolean>(false)
    const [isWelcome, setIsWelcome] = useState(true)

    const {
        register,
        handleSubmit,
        getValues,
        clearErrors,
        setError,
        formState: { errors, isValid, dirtyFields },
    } = useForm<IConfirmNewPasswordForm>({
        mode: 'onChange',
        defaultValues: { newPassword, confirmNewPassword },
        resolver: yupResolver(confirmNewPasswordFormSchema),
    });
    const handleChange = ({
        currentTarget: { name, value },
    }: React.FormEvent<HTMLInputElement>) =>
        dispatch(setConfirmNewPasswordForm({ name, value }));

    const onSubmit: SubmitHandler<IConfirmNewPasswordForm> = async (values) => {
        Auth.completeNewPassword(user?.cognitoUser, values.newPassword)
            .then(() => {
                setModal({ isOpen: true, text: t('messageChangePassSuccess') })

                setTimeout(() => {
                    dispatch(setUserInfo({ ...userInfo, email: userInfo.email, isLogged: true }))
                }, 2000)
            }).catch(e => {});
    };

    useEffect(() => {
        if (screen.width >= 1280) {
            setIsDesktop(true)
        } else {
            setIsDesktop(false)
        }
    }, []);

    window.addEventListener("resize", () => {
        if (screen.width >= 1280){
            setIsDesktop(true)
        }else{
            setIsDesktop(false)
        }
    });

    useEffect(() => {
        const values = getValues();
        if (isValid) { clearErrors() } else {
            if (values.newPassword !== values.confirmNewPassword) {
                setError('confirmNewPassword', { type: 'custom', message: 'Las contraseñas no coinciden' })
            }
        }
    }, [isValid]);

    return (
        <>
            <AlertToast
                isOpen = {modal.isOpen}
                text = {modal.text}
            />
            { isWelcome ?
                <WelcomeModal setIsWelcome={setIsWelcome}/>
                :
                <main className='change-pass__main' data-testid='main'>
                    <div className='change-pass__wrapper-tittle'>
                        <h2 className='change-pass__tittle'>{tittle}</h2>
                    </div>
                    <div className='change-pass__wrapper'>

                        <div className="change-pass__wrapper-icon">
                            <AImage
                                role='presentation'
                                className='change-pass__icon'
                                url={lockSuccess}
                                alt='resetpassicon'
                            />
                        </div>

                        <div className='change-pass__wrapper-description'>
                            <p className="change-pass__description">{t('paramsChangePassword')}</p>
                        </div>

                        <form className="change-pass__form" onSubmit={handleSubmit(onSubmit)} data-testid='form'>
                            <div className="change-pass__form-box">
                                <div data-testid="container__error" className={`change-pass__form-wrapper ${errors.newPassword && 'change-pass__form-wrapper--error'}`
                                }>
                                    <input
                                        className='change-pass__form-input'
                                        type={showNewPassword ? 'text' : 'password'}
                                        id="newPassword"
                                        value={newPassword}
                                        placeholder={isDesktop ? 'Ingresa tu nueva contraseña' : 'Contraseña nueva'}
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('newPassword', { onChange: handleChange })}
                                        tabIndex={1}
                                    />
                                    <AButton
                                        tag='button'
                                        handleClick={() => dispatch(setShowPassword('newPassword'))}
                                        type="button"
                                        className={!errors.newPassword ? 'change-pass__form-btnPass' : 'change-pass__form-btnPass--error'}
                                    >
                                        <AImage
                                            role='presentation'
                                            url={errors.newPassword ? warningIcon : showNewPassword ? passwordShowIcon : passwordHideIcon}
                                            alt=''
                                        />
                                    </AButton>
                                </div>

                                {errors?.newPassword && <p className='change-pass__error-paragraph'><AImage className='change-pass__error-icon' url={errorIconParagraph} alt="errorIconParagraph" />{errors.newPassword.message}</p>}
                            </div>

                            <div className="change-pass__form-box">
                                <div className={`change-pass__form-wrapper ${errors.confirmNewPassword && 'change-pass__form-wrapper--error'}`}>
                                    <input
                                        className="change-pass__form-input"
                                        type={showConfirmNewPassword ? 'text' : 'password'}
                                        id="confirmNewPassword"
                                        value={confirmNewPassword}
                                        placeholder={isDesktop ? 'Confirma tu nueva contraseña' : 'Repite contraseña nueva' }
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...register('confirmNewPassword', { onChange: handleChange })}
                                        tabIndex={2}
                                    />
                                    <AButton
                                        tag='button'
                                        handleClick={() => dispatch(setShowPassword('confirmNewPassword'))}
                                        type="button"
                                        className={!errors.confirmNewPassword ? 'change-pass__form-btnPass' : 'change-pass__form-btnPass--error'}
                                    >
                                        <AImage
                                            role='presentation'
                                            url={errors.confirmNewPassword ? warningIcon : showConfirmNewPassword ? passwordShowIcon : passwordHideIcon}
                                            alt=''
                                        />
                                    </AButton>

                                </div>
                                {errors?.confirmNewPassword && <p className='change-pass__error-paragraph'><AImage className='change-pass__error-icon' url={errorIconParagraph} alt="errorIconParagraph" />{errors.confirmNewPassword.message}</p>}

                            </div>
                            <div className='change-pass__wrapper-button'>
                                <AButton tag='button' 
                                    type='submit' 
                                    disabled={!isValid}
                                    data-testid='button'
                                    className='button-primary change-pass__button'
                                >
                                    Cambiar
                                </AButton>
                            </div>
                        </form>
                    </div>
                </main>
            }
        </>
    );
};

export default ChangeForcePassword
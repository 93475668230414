
import { useEffect, useState } from 'react';
import { IAImage } from 'utils/interfaces';
import './AImage.scss';

export const AImage = ({ alt, url, className, role, width, height, handleClick, dataTestid }: IAImage) => {
  const [urlSrc, setUrlSrc] = useState<string>("");
  useEffect(() => { url && setUrlSrc(url) }, [url]);
  return (
    urlSrc ?
      <img
        onClick={handleClick}
        src={urlSrc}
        className={className}
        width={width}
        height={height}
        alt={alt}
        role={role}
        onError={() => setUrlSrc("")}
        data-testid={dataTestid}
      />
      :
      <></>
  )
}
import { useEffect, useState } from "react";

export const useMessageProgressBar = (
  totalStages: number,
  completedStages: number,
  isLoading: boolean
) => {
  const [nextStage, setNextStage] = useState({
    name: "Loading...",
    description: "Loading...",
  });

  useEffect(() => {
    let title
    let desc
    if (isLoading) return;
    switch (true) {
      case (completedStages > 3):
        title = "¡Súper!";
        desc = `Tienes ${completedStages}/${totalStages} etapas completadas`;
        break;
      case (completedStages > 1):
        title = "¡Genial!";
        desc = `Tienes ${completedStages}/${totalStages} etapas completadas`;
        break;
      case (completedStages > 0):
        title = "¡Wow! Esto Empezó";
        desc = `Tienes ${completedStages}/${totalStages} etapas completadas`;
        break;
      default:
        title = "¡Wow! Esto Empezó";
        desc = `Tienes ${totalStages} etapas por completar`;
        break;
    }
    setNextStage({
      name: title,
      description: desc,
    });
  }, [completedStages, isLoading, totalStages]);

  useEffect(() => {
    if (isLoading)
      setNextStage({
        name: "Loading...",
        description: "Loading...",
      });
  }, [isLoading]);

  return { ...nextStage };
};

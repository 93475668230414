
import { IHourSelected, IScheduleHour } from 'utils/interfaces';
import { ScheduleHours } from 'components/molecules';
import { SubtitleWithIcon } from 'components/atoms';
import './SchedulePartsOfDay.desktop.scss';
import { sunriseIcon, sunsetIcon } from 'utils/assets';


interface IProps {
  currentDayIndex: number;
  hoursList: {
    dateMorning: IScheduleHour[];
    dateAfternoon: IScheduleHour[];
  };
  hourSelected: IHourSelected | null;
  setHourSelected: (date: IHourSelected) => void;
}

const partsOfDayData = [
  {
    partOfDay: 'morning',
    hours: 'dateMorning',
    icon: sunriseIcon,
    title: 'Mañana'
  },
  {
    partOfDay: 'afternoon',
    hours: 'dateAfternoon',
    icon: sunsetIcon,
    title: 'Tarde'
  }
]

export const SchedulePartsOfDayDekstop = ({ currentDayIndex, hoursList, hourSelected, setHourSelected }: IProps) => {
  return (
    <section className="schedule-part-of-day-desktop">
      {
        partsOfDayData.map(({ icon, hours, partOfDay, title }, index) => (

          <div className="schedule-part-of-day-desktop__partOfDay" key={index + partOfDay}>
            <SubtitleWithIcon icon={icon} title={title} />

            <ScheduleHours
              dayIndex={currentDayIndex}
              hours={hoursList[hours as 'dateMorning' | 'dateAfternoon']}
              hourSelected={hourSelected}
              setHourSelected={setHourSelected}
              partOfDay={partOfDay as any}
            />
          </div>
        ))
      }
    </section>
  )
}

import { TCodes } from 'utils/interfaces/store/translations.interfaces';

const global = process.env.REACT_APP_TRANSLATIONS_ENDPOINT || ''

export const endpoint = async (lanCode: TCodes) => {
    let data = {}
    await fetch(global,
        {
            method: 'GET',
            headers: {
                'partition-key-value': `talento_estatico_${lanCode}`,
                'Content-Type': 'application/json'
            }

        })
        .then(response => response.json())
        .then((res) => {
            data = res
        })

    return data
}
import { useEffect, useState } from 'react';
import './Tabs.scss';
import {
  arrayFirstElement,
  completedText,
  localStorageItems,
} from 'utils/constants';
import { ILabelTab } from 'utils/constants';
import { useAppDispatch } from 'store/hooks';
interface IProps {
  tabs: ILabelTab[];
  onChange: (tab: string) => void;
}

const Tabs = ({ tabs, onChange }: IProps) => {
  const [activeTab, setActiveTab] = useState(tabs[arrayFirstElement].label);

  useEffect(() => {
    localStorage.setItem(localStorageItems.allowEdition, JSON.stringify(true));
  }, []);

  const handleClick = (tab: ILabelTab) => {
    setActiveTab(tab.label);
    onChange(tab.label);
    if (tab.label === completedText) {
      localStorage.setItem(
        localStorageItems.allowEdition,
        JSON.stringify(false)
      );
    } else {
      localStorage.setItem(
        localStorageItems.allowEdition,
        JSON.stringify(true)
      );
    }
  };

  return (
    <div>
      <div className="tab-titles">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            onClick={() => handleClick(tab)}
            className={activeTab === tab.label ? 'active' : ''}
          >
            {tab.label} {tab.amount && <p className="amount">{tab.amount}</p>}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

import { useState } from 'react';
import { selectTranslations } from 'store/translation/translation.slice';
import { TTabStage } from 'utils/interfaces/store/user.interfaces';
import { useAppSelector } from 'store/hooks';
import { useTranslation } from 'hooks/useTranslation';
import { Tab } from 'components/atoms/Tab/Tab.components';
import { StagesList } from 'components/organisms';
import './StagesHome.scss';
import '../StagesList/StagesList.scss';

interface IProps {
  disabled?: boolean;
}

export const StagesHome = ({ disabled }: IProps) => {
  const [currentTab, setCurrentTab] = useState<TTabStage>('active');
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  return (
    <div className="stagesHome">
      <section className="stagesHome__tabs">
        <Tab
          name={t('currentStages')}
          to="active"
          isActive={currentTab === 'active'}
          setCurrentTab={setCurrentTab}
          disabled={disabled}
        />
        <Tab
          name={t('completedStages')}
          to="completed"
          isActive={currentTab === 'completed'}
          setCurrentTab={setCurrentTab}
          disabled={disabled}
        />
      </section>

      {disabled ? (
        <StagesList currentTab={currentTab} disabled />
      ) : (
        <StagesList currentTab={currentTab} />
      )}
    </div>
  );
};

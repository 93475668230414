
import { useEffect } from 'react';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useAppDispatch } from 'store/hooks';
import { timeFormat } from 'utils/helpers';

interface IProps {
  timer: number;
  reduceTimerMethod: ActionCreatorWithoutPayload<string>;
}

export const CounterDown: React.FC<IProps> = ({
  timer,
  reduceTimerMethod,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { minutes, seconds } = timeFormat(timer);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        dispatch(reduceTimerMethod());
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch, timer, reduceTimerMethod]);

  return (
    <span className="counter-down">
      {`${minutes}:${seconds <= 9 ? '0' : ''}${seconds}`}
    </span>
  );
};

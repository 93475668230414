import { infoIcon, warningCircleIcon } from 'utils/assets';
import { IATextItem } from 'utils/interfaces/store/activities.interfaces';
import { TextIcon } from '../TextIcon/TextIcon.components';
import './ATextItem.scss';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export const ATextItem = ({
  text,
  item,
  title,
  textBold,
  help = false,
  link,
  className,
}: IATextItem) => {
  let textArray: string[] = [];

  if (help) {
    textArray = text.split('ayuda');
  }

  return item === 'info' ? (
    <TextIcon
      urlIcon={infoIcon}
      tag={item}
      alt="info"
      text={[{ name: text }]}
      title={title}
      textBold={textBold}
      link={link}
    />
  ) : item === 'warning' ? (
    <TextIcon
      urlIcon={warningCircleIcon}
      tag={item}
      alt="calendar"
      text={[{ name: text }]}
      title={title}
      textBold={textBold}
    />
  ) : help ? (
    <>
      <p className={className ? className : 'activityAtomTextItem'}>
        <span data-testid="spanHelp" className="activityAtomTextItem__item">
          {item}
        </span>
        {textArray[0]}
        <Link to={'/help'} className="activityAtomTextItem__link">
          ayuda
        </Link>
        {textArray.map((item) => {
          if (textArray.indexOf(item) !== 0 && textArray.indexOf(item) === 1) {
            return item;
          } else if (
            textArray.indexOf(item) !== 0 &&
            textArray.indexOf(item) !== 1
          )
            return `ayuda ${item}`;
        })}
      </p>
    </>
  ) : (
    <p data-testid="p" className="activityAtomTextItem">
      <span data-testid="span" className="activityAtomTextItem__item">
        {item}
      </span>
      {text}
    </p>
  );
};

import { RootState } from 'store/store';
import { getAuthToken } from 'lib/auth';

export const prepareHeaders = async (
  headers: Headers,
  getState: () => unknown
) => {
  const authToken = await getAuthToken();

  if (authToken) {
    headers.set('Authorization', authToken);
  }

  const languageCode =
    (getState() as RootState).translations.languageCode || 'es' as string;
  headers.set('Accept-language', languageCode);

  return headers;
};

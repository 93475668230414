import { AImage } from 'components/atoms'
import './SectionContainer.scss'
import { arrowLeftCircleFilled } from 'utils/assets'

interface IProps {
  title?: string,
  subtitle?: string | JSX.Element,
  onGoBack?: () => void
  marginForCalendar?: number,
  
  children: React.ReactNode
}

export const SectionContainer = ({ title, subtitle, onGoBack, children }: IProps) => {
  return (
    <div className="section-container">
      <header>
        <div className="section-container__title-container">
          <AImage
            url={arrowLeftCircleFilled}
            alt="Ir atrás"
            className="section-container__go-back"
            handleClick={onGoBack}
            role='button'
          />
          <h3 className="section-container__title">{title}</h3>
        </div>
        <div>
          <p className="section-container__subtitle">{subtitle}</p>
        </div>
      </header>
      <div>
        {children}
      </div>
    </div>
  )
}

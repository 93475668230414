import { Accordion } from 'components/atoms';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IUserConfirmData } from '../ConfirmDataTabForm';
import { Numbers } from 'utils/helpers/dictionaries';
import cn from 'classnames';
import './SummarySection.scss';

interface SummarySectionProps {
  selected: boolean;
  setSelected: (selected: boolean) => void;
  confirmBeforeSubmit?: boolean;
}

export default function SummarySection({
  selected,
  setSelected,
  confirmBeforeSubmit,
}: Readonly<SummarySectionProps>) {
  const { getValues } = useFormContext<IUserConfirmData>();
  const [accordionState, setAccordionState] = useState<number | null>(
    Numbers.one
  );
  const [personalDataAccordionState, setPersonalDataAccordionState] =
    useState(false);
  const [contactDataAccordionState, setContactDataAccordionState] =
    useState(false);
  const [educationAccordionState, setEducationAccordionState] = useState(false);

  function handlePersonalDataAccordion() {
    setPersonalDataAccordionState(!personalDataAccordionState);
  }

  function handleContactDataAccordion() {
    setContactDataAccordionState(!contactDataAccordionState);
  }

  function handleEducationAccordion() {
    setEducationAccordionState(!educationAccordionState);
  }

  const personalData = {
    'Nombres completos': getValues('names') + ' ' + getValues('secondName'),
    'Apellidos completos':
      getValues('lastname') + ' ' + getValues('secondLastname'),
    'Fecha de nacimiento': getValues('birthdate'),
    Sexo: getValues('gender'),
    'Estado civil': getValues('civilStatus'),
    'Pais de nacimiento': getValues('countryOfBirth'),
    'Departamento de nacimiento': getValues('departamentOfBirth'),
    'Provincia de nacimiento': getValues('cityOfBirth'),
    'Tipo de documento': getValues('documentType'),
    'Número de documento': getValues('documentNumber'),
    'Grupo sanguineo': getValues('bloodGroup'),
    'Talla de camiseta': getValues('shirtSize'),
    'Nombre preferido': getValues('preferredName'),
  };

  const contactData = {
    Teléfono: getValues('phone'),
    Email: getValues('email'),
    'País de residencia': getValues('countryOfResidence'),
    'Departamento de residencia': getValues('departamentOfResidence'),
    'Provincia de residencia': getValues('cityOfResidence'),
    'Distrito de residencia': getValues('residenceDistrict'),
    'Dirección de residencia': getValues('address'),
  };

  const emergencyContactData = getValues('emergencyContactInformation').map(
    (contact, index) => ({
      'Nombre y apellido': contact.fullname,
      'Telefono principal': contact.primaryPhone,
    })
  );

  const educationData = getValues('academicInformation').map(
    (education, index) => ({
      Institución: education.institution,
      'Nivel educativo': education.educationalLevel,
      'Titulo obtenido': education.professionalTitle,
      'Fecha de inicio': education.startDate,
      'Fecha de fin': education.endDate,
    })
  );

  return (
    <div className="SummarySection">
      <Accordion
        accordionState={accordionState === Numbers.one}
        title="Datos personales"
        handleAccordion={
          accordionState === Numbers.one
            ? () => setAccordionState(null)
            : () => setAccordionState(Numbers.one)
        }
      >
        <ul>
          {Object.entries(personalData).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {value}
            </li>
          ))}
        </ul>
      </Accordion>
      <Accordion
        accordionState={accordionState === Numbers.two}
        handleAccordion={
          accordionState === Numbers.two
            ? () => setAccordionState(null)
            : () => setAccordionState(Numbers.two)
        }
        title="Datos de contacto"
      >
        <ul>
          {Object.entries(contactData).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {value}
            </li>
          ))}
        </ul>
        <h1>Contacto de emergencia</h1>
        <ul>
          {emergencyContactData.map((contact, index) => (
            <li key={index}>
              <ul>
                {Object.entries(contact).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Accordion>
      <Accordion
        accordionState={accordionState === Numbers.three}
        handleAccordion={
          accordionState === Numbers.three
            ? () => setAccordionState(null)
            : () => setAccordionState(Numbers.three)
        }
        title="Educación"
      >
        {educationData.map((education, index) => (
          <ul key={index}>
            {Object.entries(education).map(([key, value]) => (
              <li key={key}>
                <strong>{key}:</strong> {value}
              </li>
            ))}
          </ul>
        ))}
      </Accordion>
      {confirmBeforeSubmit && (
        <div className="DeclaracionJurada">
          <div className="DeclaracionJurada__radio">
            <RadioButton
              selected={selected}
              onClick={() => setSelected(!selected)}
            />
          </div>
          <div className="DeclaracionJurada__content">
            <strong>DECLARACION JURADA:</strong>
            <p>
              Confirmo la exactitud y la veracidad de las declaraciones, antes
              expresada que nada he ocultado, omitido o disimulado, siendo el
              único responsable de la información remitida; y autorizo a que
              sean investigados para los fines que la empresa convenga.
              Asimismo, declaro que actualmente no cuento con contrato vigente
              con ninguna compañía, bajo ninguna modalidad contractual. De otro
              lado, autorizo para que se me notifique cualquier documento o
              decisión que emita la empresa al correo electrónico consignado.
              Finalmente me comprometo a informar oportunamente cualquier cambio
              en los datos que he declarado en el presente documento; asumiendo
              la responsabilidad que su omisión genere.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function RadioButton({
  selected,
  onClick,
}: Readonly<{
  selected: boolean;
  onClick: () => void;
}>) {
  const classes = cn('RadioButton', {
    'RadioButton--selected': selected,
  });

  return (
    <div className={classes} onClick={onClick}>
      <div className="RadioButton__inner"></div>
    </div>
  );
}

import { useState } from 'react';
import {
  FieldValues,
  Path,
  UseFormReturn,
} from 'react-hook-form';

type UseTabFormProps<T extends FieldValues> = {
  /**
   * The form methods returned by useForm
   */
  form: UseFormReturn<T>;
  /**
   * The fields that belong to each tab. Each field will be triggered when the tab is validated
   * @example [['name', 'email'], ['password', 'confirmPassword']]
   */
  fields: Path<T>[][];
};

type UseTabFormValidationMethods = {
  /**
   * Returns the validation status of the tab
   */
  isTabValid: (tab: number) => 'success' | 'error' | undefined;
  /**
   * Triggers validation for the last tab
   */
  validateLastTab: (tab: number) => void;
  /**
   * Triggers validation for a tab
   */
  triggerTab: (tab: number) => void;
};

/**
 *  Hook that returns useful methods for tab form validation
 * @returns {UseTabFormValidationMethods}
 */
export function useTabFormValidation<T extends FieldValues>({
  form: methods,
  fields,
}: UseTabFormProps<T>): UseTabFormValidationMethods {
  const [lastTab, setLastTab] = useState(0);
  const [isTabInteractedWith, setIsTabInteractedWith] = useState(
    Array(fields.length).fill(false)
  );

  function isTabValid(tab: number): 'success' | 'error' | undefined {
    if (!isTabInteractedWith[tab]) {
      return undefined;
    }

    const isErrorFieldPresent = fields[tab].some((field) => {
      const errorFields = Object.keys(methods.formState.errors);
      return errorFields.includes(field);
    });

    if (isErrorFieldPresent) {
      return 'error';
    } else {
      return 'success';
    }
  }

  function triggerTab(tab: number) {
    methods.trigger(fields[tab]);

    if (isTabInteractedWith[tab]) return;

    setIsTabInteractedWith((prev) => {
      const newInteractedWith = [...prev];
      newInteractedWith[tab] = true;
      return newInteractedWith;
    });
  }

  function validateLastTab(tab: number) {
    setLastTab(tab);
    if (lastTab < fields.length) {
      triggerTab(lastTab);
    }
  }

  return { isTabValid, validateLastTab, triggerTab };
}
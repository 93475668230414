import {
  selectRestorePasswordForm,
  setFormType,
  setRestorePasswordForm,
} from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { AButton, AImage } from 'components/atoms';
import { ConfirmationCodeForm } from 'components/organisms';
import { Back } from 'components/atoms/Back/Back.components';
import { mailImage, pragmaLogoImage } from 'utils/assets';
import './ConfirmationCodePage.scss';
import { useTranslation } from 'hooks/useTranslation';
import { selectTranslations } from 'store/translation/translation.slice';

export const ConfirmationCodePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const { email } = useAppSelector(selectRestorePasswordForm);

  const handleIncorrectEmailClick = () => {
    dispatch(setFormType('restorePassword'));
    dispatch(setRestorePasswordForm({ name: 'email', value: '' }));
  };

  return (
    <main className="template-authentication">

      <div className='template-authentication__wrapper' >
        <Back className='template-authentication__button' handleBack={() => { dispatch(setFormType('restorePassword')) }} />
        <div className="template-authentication__wrapper-container">

          <AImage url={pragmaLogoImage} alt='logoPragma' className='logoPragma' />
          <AImage url={mailImage} alt='emailIcon' className='icon__mail' />
          <h1 className="template-authentication__title-email">
            {t('authenticationEmail')}
          </h1>
          <div className="template-authentication__block">
            <h2 className="template-authentication__subtitle">
              {t('messageSendCode')} {email}
            </h2>

            <h2 className='template-authentication__code'>{t('authenticationCode')}</h2>
          </div>
        </div>

        

        <div className='resend__code'>
          <ConfirmationCodeForm />
        </div>
      </div>
    </main>
  );
};

import { Link, useMatch } from 'react-router-dom';
import { AImage } from 'components/atoms'
import './TabbarItem.components.scss';
import { circle } from 'utils/assets';

interface IProps {
  path: string;
  title: string;
  icon: string;
  isDisabled?: boolean
}

export const TabbarItem = ({ path, title, icon, isDisabled }: IProps) => {
  const match = useMatch(path);

  return (
    <Link
      to={`/${path}`}
      className={`tabbar-item ${match ? 'tabbar-item--active' : ''} ${isDisabled ? 'tabbar-item--inactive' : ''}`}
    >
      <div className={`tabbar-item__border ${match ? 'tabbar-item__border--active' : ''}`}></div>

      <div className='tabbar-item__icon--container'>
        <img src={circle} alt="circulo" className='tabbar-item__icon--circle' />
        <AImage
          url={icon}
          alt=''
          className={`tabbar-item__icon ${match && 'tabbar-item__icon--active'}`}
        />
      </div>


      <span className="tabbar-item__title">{title}</span>
    </Link>
  );
};

import { useEffect } from 'react';

import { useAppSelector } from 'store/hooks';

import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { LoginForm, Seo } from 'components/organisms';
import { LogoPragma, AImage, AButton } from 'components/atoms';
import './LoginPage.scss';

import Lottie from 'lottie-react-web'
import { AnimationLogo } from 'utils/assets';

export const LoginPage: React.FC = () => {
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);

  useEffect(() => {
    const animation = document.getElementById('animation');
    const logo = document.getElementById('logo');
    setTimeout(() => {
      animation?.classList.remove('login-page__animation');
      animation?.classList.add('login-page__animation--hidden');
    }, 3200)
    setTimeout(() => {
      logo?.classList.remove('login-page__logo-pragma');
      logo?.classList.add('login-page__logo-pragma--show');
    }, 3200)
  });

  return (
    <Seo title="Login | Pragma 💜">
      <main className="login-page" data-testid="main">
        <div className='login-page__wrapper'>
          <div className="login-page__authenticate">
            <div className="login-page__info-pragma">
              <div className='login-page__animation' id='animation' data-testid="animationLogin">
                <Lottie
                  options={{
                    animationData: AnimationLogo
                  }}
                />
              </div>
              <div className='login-page__logo-pragma' id='logo'>
                <LogoPragma/>
              </div>
              <div className="login-page__title">
                <h2 className='login-page__title-text'>{t('weAreTheMasters')}</h2>
                <p className='login-page__title-text login-page__title-text--p'>{t('ofOurFate')}</p>
              </div>
            </div>
            <div className="login-page__form">
              <LoginForm />
            </div>
          </div>
        </div>
      </main >
    </Seo>
  );
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TCodes } from 'utils/interfaces/store/translations.interfaces';

export interface TranslationsState {
  translations: {
    isLoading: boolean;
    isError: boolean;
    isSucces: boolean;
    translations: Object;
  };
  languageCode: TCodes;
}

const initialState: TranslationsState = {
  translations: {
    isLoading: true,
    isError: false,
    isSucces: false,
    translations: {},
  },
  languageCode: 'es',
};

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setTranslations(
      state,
      action: PayloadAction<{
        translations?: Object;
        isLoading?: boolean;
        isError?: boolean;
        isSucces?: boolean;
      }>
    ) {
      state.translations = {
        translations: action.payload.translations || {},
        isError: action.payload.isError || false,
        isLoading: action.payload.isLoading || false,
        isSucces: action.payload.isSucces || false,
      };
    },
    setLanguageCode(state, action: PayloadAction<TCodes>) {
      state.languageCode = action.payload;
    },
  },
});

export const { setTranslations, setLanguageCode } = translationsSlice.actions;
export const selectTranslations = (state: RootState) =>
  state.translations.translations;
export const selectLanguageCode = (state: RootState) =>
  state.translations.languageCode;

export default translationsSlice.reducer;

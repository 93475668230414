import React, { createContext, useContext, useState, useEffect } from 'react';
import useRealtimeNotifications, { Notification } from 'hooks/useRealtimeNotifications';
import {states, errors} from 'utils/constants/Notifications/indexConstants'
import {Numbers} from 'utils/helpers/dictionaries';
type NotificationState = states.default | states.received | states.read | states.completed | states.rejected;
interface NotificationContextType {
  email:string,
  notifications: Notification[];
  notificationStateContext: NotificationState;
  setNotificationState: React.Dispatch<React.SetStateAction<NotificationState>>;
  updateNotification: (notificationId: number, status: states.received | states.read  | states.completed) => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(errors.errorUsedContext);
  }
  return context;
};

export const NotificationContextProvider: React.FC<{ email: string }>  = ({email, children}) => {
  const [notificationStateContext, setNotificationState] = useState<NotificationState>(states.default);
  const {
    notifications,
    updateNotification,
  } = useRealtimeNotifications(email);
  useEffect(() => {
    if (notifications.length === Numbers.zero) {
      setNotificationState(states.default);
    } else if (notifications[0].notificationStatus === states.completed) {
      setNotificationState(states.completed);
    } else {
      const hasUnread = notifications.some(notification => notification.notificationStatus === states.received);
      if (hasUnread) {
        setNotificationState(states.received);
      } else if (notificationStateContext === states.read && !hasUnread) {
        setNotificationState(states.completed);
      } else {
        setNotificationState(states.read);
      }
    }
  }, [notifications]);
  return (
    <NotificationContext.Provider value={{
      email,
      notifications,
      notificationStateContext, 
      setNotificationState,
      updateNotification
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

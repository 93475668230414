import { IModal } from 'utils/interfaces';

export const confirmButtonText = 'Aceptar';
export const returnButtonText = 'Volver';
export const continueEditingButtonText = 'Seguir editando';
export const exitButtonText = 'Salir';
export const PREVIOUS_LOCATION = -1;
export const GO_BACK_MODAL_QUESTION: IModal['type'] = 'question';
export const GO_BACK_MODAL_TITLE = '¿Deseas salir de la página?';
export const GO_BACK_MODAL_MESSAGE = 'Tus cambios no se han enviado';
export const title = 'Carga documentos y completa la información adicional';
export const subtitle =
  'Carga algunos documentos y completa la información que necesitamos para formalizar tu contrato.';
export const DOCUMENTS_TAB_TEXT = 'Documentos';
export const BANK_INFO_TAB_TEXT = 'Info. bancaria';
export const AFFILIATIONS_TAB_TEXT = 'Afiliaciones';
export const WELCOME_KIT_TAB_TEXT = 'Equipo de trabajo';
export const SELECTED_KIT_TAB_TEXT = 'Accesorios adicionales';
export const SUMMARY_TAB_TEXT = 'Resumen';
export const LOADING_DATA = 'Cargando datos...';
export const SUCCESS_TEXT = '¡Has confirmado tus datos!';
export const ERROR_TEXT = '¡Ha ocurrido un error!';
export const ERROR_FILE = 'Error guardando archivo';
export const LIMIT_MAX_FILES = 'No puedes subir más de 5 archivos';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const successTitle = '¡Súper!';
export const successDescription = (email: string) =>
  `Revisaremos toda tu información, si tenemos alguna novedad te lo haremos saber al correo ${email}`;
export const successButtonText = 'Aceptar';
export const errorTitle = '¡Ups!';
export const errorDescription =
  'No fue posible confirmar tus datos, intentalo de nuevo más tarde.';
export const errorButtonText = 'Aceptar';
export const loadingText = 'Enviando tu información';
export const titleMessage = 'Recuerda: '
export const textMessage = 'Nuestro aliado logístico te contactará por teléfono para coordinar la entrega de tu kit de bienvenida. En la mayoría de los casos, esta entrega se realiza el fin de semana antes de tu fecha de ingreso, aunque la fecha exacta puede variar dependiendo de la fecha en la que se notificó tu contratación. Adicional a esto, el equipo de TI  te enviará un mensaje vía WhatsApp con las credenciales de tu correo corporativo.'

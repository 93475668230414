interface timeResult {
  minutes: number;
  seconds: number;
}

export const timeFormat = (timer: number): timeResult => {
  const minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timer % 60000) / 1000);

  return { minutes, seconds };
};

import React from 'react';
import styles from './Modal.module.scss';
import { ReactComponent as CloseIconModal } from 'utils/assets/images/closeIconModal.svg';
import classNames from 'classnames';
export interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  title?: string;
  onClose: () => void;
}

export const Modal = ({ isOpen, children, title, onClose }: ModalProps) => {
  return (
    <div className={classNames(styles.modal, { [styles.open]: isOpen })}>
      <div className={styles.modalOverlay} onClick={onClose}></div>
      <div className={styles.ModalContent}>
        <div className={styles.ModalHeader}>
          <h3 className={styles.ModalTitle}>{title}</h3>
          <button
            type="button"
            className={styles.closeButton}
            onClick={onClose}
          >
            <CloseIconModal />
          </button>
        </div>
        <div className={styles.ModalChildren}>{children}</div>
      </div>
    </div>
  );
};

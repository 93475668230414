import {
  TStatusStage,
  TStageState,
} from 'utils/interfaces/store/user.interfaces';

export interface IProps {
  status: TStatusStage;
  stage: number;
  currentStage: number;
  statusUser: number;
}

export function stageState({
  status,
  stage,
  currentStage,
  statusUser,
}: IProps): TStageState {
  if (status === '4' || status === '6') {
    return 'completed';
  } else if (stage === currentStage && statusUser !== 9) {
    return 'active-lock';
  } else if (stage === currentStage && status !== '5') {
    return 'active';
  } else if (stage !== currentStage || status === '5') {
    return 'locked';
  }

  return 'pending';
}

export const stagesWithActivities = [0, 1, 2, 3];

import { createContext, useContext, useMemo, useState } from 'react';
import { ReactComponent as Check } from '../../../utils/assets/images/check.svg';
import { ReactComponent as IconWarning } from '../../../utils/assets/images/iconWarning.svg';
import styles from './VerticalTabPanel.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(styles);

const VerticalTabPanelContext = createContext({
  activeTab: 0,
  setActiveTab: (id: number) => {},
});

export function useTabPanelContext() {
  return useContext(VerticalTabPanelContext);
}

function useActiveTabs() {
  const [activeTab, setActiveTab] = useState(0);

  function setActiveTabWithinBounds(id: number) {
    if (id < 0) {
      setActiveTab(0);
    } else {
      setActiveTab(id);
    }
  }

  return { activeTab, setActiveTab: setActiveTabWithinBounds };
}

function TabPanelProvider({
  children,
}: Readonly<{ children?: React.ReactNode }>) {
  const { activeTab, setActiveTab } = useActiveTabs();

  const value = useMemo(
    () => ({ activeTab, setActiveTab }),
    [activeTab, setActiveTab]
  );

  return (
    <VerticalTabPanelContext.Provider value={value}>
      {children}
    </VerticalTabPanelContext.Provider>
  );
}

/**
 * This is the top-level component for the vertical tab panel. It should wrap all the other components.
 */
export default function VerticalTabPanel({
  children,
  className,
}: Readonly<{ children?: React.ReactNode; className?: string }>): JSX.Element {
  return (
    <TabPanelProvider>
      <div className={cn(styles.VerticalTabPanel, className)}>{children}</div>
    </TabPanelProvider>
  );
}

function TabList({ children }: Readonly<{ children?: React.ReactNode }>) {
  return (
    <div
      aria-orientation="vertical"
      role="tablist"
      className={styles.VerticalTabPanel__TabList}
    >
      {children}
    </div>
  );
}

/**
 *  Represents a tab list component within a vertical tab panel. It should contain all the tabs that will be displayed.
 */
VerticalTabPanel.TabList = TabList;

interface TabProps {
  /** The unique identifier of the tab. */
  id: number;
  disabled?: boolean;
  variant?: 'success' | 'error' | 'none';
  children?: React.ReactNode;
  onClick?: () => void;
}

function Tab({
  id,
  variant,
  disabled = false,
  children,
  onClick,
}: Readonly<TabProps>) {
  const { activeTab, setActiveTab } = useTabPanelContext();
  const selected = activeTab === id;

  const formTabClass = cn('VerticalTabPanel__Tab', {
    'VerticalTabPanel__Tab--selected': selected && !disabled,
    'VerticalTabPanel__Tab--disabled': disabled,
    'VerticalTabPanel__Tab--error': variant === 'error',
    'VerticalTabPanel__Tab--success': variant === 'success',
    'VerticalTabPanel__Tab--previous-tab': id === activeTab - 1,
    'VerticalTabPanel__Tab--next-tab': id === activeTab + 1,
  });

  const iconClass = cn('VerticalTabPanel__TabIcon', {
    'VerticalTabPanel__TabIcon--error': variant === 'error',
    'VerticalTabPanel__TabIcon--success': variant === 'success',
  });

  function handleTabClick() {
    if (!disabled) {
      setActiveTab(id);
      if (onClick) {
        onClick();
      }
    }
  }

  return (
    <button
      role="tab"
      id={`VerticalTabPanel__Tab-${id}`}
      aria-selected={selected}
      aria-controls={`VerticalTabPanel__Panel-${id}`}
      className={formTabClass}
      onClick={handleTabClick}
    >
      {!disabled && (
        <div className={iconClass}>
          <TabIcon status={variant} />
        </div>
      )}
      <div className={styles.VerticalTabPanel__TabContent}>{children}</div>
    </button>
  );
}

function TabIcon({
  status,
}: Readonly<{ status?: 'success' | 'error' | 'none' }>) {
  if (status) {
    if (status === 'none') return null;

    return status === 'success' ? (
      <Check />
    ) : (
      <IconWarning width={38} height={38} />
    );
  }
  return <div className="VerticalTabPanel__TabIcon--default"></div>;
}

/**
 * Represents a tab component within a vertical tab panel. The tab can be clicked to display the content of the panel.
 */
VerticalTabPanel.Tab = Tab;

interface Panel {
  /** The unique identifier for the panel. It should match the tab id. */
  id: number;
  children?: React.ReactNode;
}

export function Panel({ id, children }: Readonly<Panel>) {
  const { activeTab } = useTabPanelContext();

  if (activeTab !== id) return <></>;

  return (
    <section
      role="tabpanel"
      id={`VerticalTabPanel__Panel-${id}`}
      className={styles.VerticalTabPanel__Panel}
      aria-labelledby={`VerticalTabPanel__Tab-${id}`}
      hidden={activeTab !== id}
    >
      {children}
    </section>
  );
}

/**
 * Renders a panel component for the vertical tab panel. Here you can place the content that will be displayed when the tab is active.
 */
VerticalTabPanel.Panel = Panel;

import { TextIcon, ATextWithAtoms, AImage, ATextItem } from "components/atoms"
import { useGetEnterviewDataQuery } from "services/talento"
import { calendarIcon, grupoIcon, videoIcons } from "utils/assets"
import linkIcon from 'utils/assets/images/link.svg';
import { IActivity, IATextItem } from "utils/interfaces";
import "./interviewInfo.scss";

interface Props {
  fullName: string
  email: string
  idActivity: string
  data?: any
}
const InterviewInfo = ({ email, idActivity, fullName, data }: Props) => {

  const { data: interviewInfo } = useGetEnterviewDataQuery({ email, idActivity })

  let linksInterviewInfo = []
  if (interviewInfo) {
    linksInterviewInfo.push({ id: 1, url: interviewInfo.data.url_meet, title: interviewInfo.data.url_meet })
  }
  return (
    <>
      <div className="interviewInfo__spacing">

        { interviewInfo && 
          <TextIcon 
            className='interviewInfo__icon'
            urlIcon={calendarIcon} 
            tag="text" alt="calendar" 
            text={[{ date: interviewInfo.data.date_interviewer, time: interviewInfo.data.range_hours }]} 
          />
        }

        <div className="interviewInfo__textIcon">

          <AImage 
            alt="enlace Meet" 
            className="textIcon__img interviewInfo__icon" 
            height={14} 
            width={14} 
            url={linkIcon} 
          />
          <ATextWithAtoms 
            text={"$1"} 
            links={linksInterviewInfo} 
            className="interviewInfo__paragraph"
            />
        </div>

        { interviewInfo && 
          <TextIcon 
            className='interviewInfo__icon'
            urlIcon={grupoIcon} 
            tag="text" 
            alt="guests" 
            text={[{ interviewer: interviewInfo.data.name_interviewer, candidate: fullName }]} 
          />
        }

      </div>
      {
        data?.data.map((item: IActivity) => {
          const actionObject = JSON.parse(item.action)

          if (item.tab === 'help') {
            if (item.type === 'textItem') {
              const { text, item: element } = (actionObject as IATextItem)
              return (
                <ATextItem key={item.id} text={text} item={element} />
              )
            }
          }
        })
      }
    </>
  )
}

export default InterviewInfo
import { useState } from "react";

export default function useCalendar() {
  const [, setMarginForCalendar] = useState(0);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return {
    setMarginForCalendar,
    isCalendarOpen,
    setIsCalendarOpen,
  };
}
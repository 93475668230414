export enum alerts {
    noNotifications = "No hay notificaciones nuevas sobre tu proceso. Si tenemos alguna novedad, podrás ver sus detalles aquí."
}
export enum states {
    default = 'DEFAULT',
    received = 'RECEIVED',
    read = 'READ',
    completed = 'COMPLETED',
    rejected = 'REJECTED'
}
export enum errors{
    errorUsedContext = 'useNotificationContext must be used within a NotificationContextProvider'
}
export enum firebase {
    users = "users"
}
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IAButton, IAImage, IAText, IATextItem, IAFileUpload, IATextWithAtoms, IModal, IActivity, IStage, TTabName } from 'utils/interfaces';
import { useGetActivitiesByIdStageQuery, useGetActivityStatusQuery, useChangeStatusMutation, useGetStagesQuery } from 'services/talento';
import { selectStages, selectUserInfo, selectVacantInfo, selectCurrentActivity, setActivityStatus, setStages, setCalendar, setStageCompleted } from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import { AButton, AImage, Loader, AText, ATextItem, ALink, ATextWithAtoms, TextIcon } from 'components/atoms';
import { ModalAlert } from 'components/molecules'
import { FileUpload } from 'components/organisms';

import './Activities.scss';
import { IResponseActivitiesByIdStage, IResponseStages } from 'utils/interfaces/store/talento.interfaces';
import InterviewInfo from 'components/molecules/InterviewInfo/InterviewInfo.component';
import ErrorBoundarySide from 'components/molecules/errorBoundaryDesktop/ErrorBoundarySide.component';
import { useWindowMobile } from 'hooks/useWindowMobile';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { userEmailMarker, routes } from "utils/constants";
import { activityStates} from "utils/helpers"; 
import { ContentTabs, ContentTypes, activities, EmptyString, Numbers, ActivityTypes } from "utils/helpers/dictionaries";

interface IProps {
  idStage: string;
  currentTab: TTabName;
  data: IResponseActivitiesByIdStage | undefined;
}

export const Activities = ({ idStage, currentTab, data }: IProps) => {
  const navigate = useNavigate();
  const { vacant } = useAppSelector(selectVacantInfo);
  const { email } = useAppSelector(selectUserInfo);
  const { stages } = useAppSelector(selectStages);
  const { status, idActivity, nameActivity: activityName } = useAppSelector(selectCurrentActivity);
  const { isMobile } = useWindowMobile(1279);
  const { translations, isLoading: isLoadingTranslations } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoadingTranslations);
  

  const [completeStatus, setCompleteStatus] = useState(false)
  const [testInProgress, setTestInProgress] = useState(false)
  const [activityType, setActivityType] = useState(0)
  const [closeIconActive, setCloseIconActive] = useState(false)
  const [isDate, setIsDate] = useState(false)
  const [isConfirmationSchedule, setisConfirmationSchedule] = useState(false)
  const [isScheduleDone, setIsScheduleDone] = useState(false)
  const [isInterview, setIsInterview] = useState(false)
  const [currentInterviewActivities, setCurrentInterviewActivities] = useState<IActivity[] | []>([])
  const [interview, setInterview] = useState<IActivity[] | []>([])
  const [modal, setModal] = useState<IModal>({ message: '', type: 'success', isOpen: false, closeIcon: false, confirmButton: false, handleClick: undefined, disabledButton: false, cancelButton: false })
  const activityApiParameters = { idStage, idVacant: vacant?.idVacant, email, activityName }
  const [recomandations, setRecomendations] = useState<IActivity[] | []>([]);
  const [details, setDetails] = useState<IActivity[] | []>([])
  const [description, setDescription] = useState<IActivity[] | []>([]);
  const [currentActivities, setCurrentActivities] = useState<IActivity[] | []>([]);
  const [statusActivity, setStatusActivity] = useState('')
  const [idActivityState, setIdActivityState] = useState(idActivity)
  const [error, setError] = useState(false);
  const [testWithFiles, setTestWithFiles] = useState(false)
  const [isButtonAttended, setIsButtonAttended] = useState(false)
  const [linkUploaded, setLinkUploaded] = useState(false)
  const [titleLinkButton, setTitleLinkButton] = useState(EmptyString.emptyString)
  const [paddingForAlerts, setPaddingForAlerts] = useState(Numbers.zero)
  const [isAlert, setIsAlert] = useState(false)
  const [isCompletingStatus, setCompletingStatus] = useState(false);


  const { data: dataActivities, isLoading, isError, refetch: getActivity } = useGetActivitiesByIdStageQuery(activityApiParameters, { refetchOnMountOrArgChange: true });
  const { data: activityStatus, refetch: getStatus } = useGetActivityStatusQuery(activityApiParameters)
  const [changeStatus, { }] = useChangeStatusMutation()
  const { data: stagesData = {} as IResponseStages, refetch: getStages, status: stagesStatus } = useGetStagesQuery({ email })
  const dispatch = useAppDispatch()

  // if ocurred error or data is corrupted redirect to Home

  useEffect(() => {
    !isMobile && navigate('/')
  }, [isMobile])

  useEffect(() => {
    if (modal.type == 'inProgress' || modal.type == 'error') {
      setCloseIconActive(true)
    } else {
      setCloseIconActive(false)
    }
  }, [modal.type])

  useEffect(() => {
    if (isError || (!isLoading && !dataActivities)) {
      navigate('/');
    }
  }, [isError, dataActivities]);

  useEffect(() => {
    const [type] = stages.filter(({ id }) => id === Number(idStage))
    setActivityType(type?.activityType)

    const interviewTmp = dataActivities?.data.find((item: IActivity) => item.tab === 'interview' ? true : false)
    if (interviewTmp) setIsInterview(true)
    if (interviewTmp) setIsScheduleDone(true)
  }, [dataActivities])

  useEffect(() => {
    if (!isLoading && dataActivities && !isError) {
      const { data } = dataActivities;
      setRecomendations((data as IActivity[]).filter(activity => {
        const elements = JSON.parse(activity.action);
        const { item } = (elements as IATextItem)
        return activity.tab === 'recomendations' || activity.tab === 'both'
      }));
      setDescription((data as IActivity[]).filter(activity => {
        const elements = JSON.parse(activity.action);
        const { item } = (elements as IATextItem)
        return activity.tab === 'description' || activity.tab === 'both'
      }));
      setInterview((data as IActivity[]).filter(activity => {
        const elements = JSON.parse(activity.action);
        const { item } = (elements as IATextItem)
        return activity.tab === 'interview' || activity.tab === 'both'
      }));
      setDetails((data as IActivity[]).filter(activity => {
        const elements = JSON.parse(activity.action);
        const { item } = (elements as IATextItem)
        return activity.tab === 'detailsConfirm' || activity.tab === 'both'
      }));
      setIsAlert(!!(data as IActivity[]).find(activity => {
        if (activity.type === 'textItem') {
          const action = JSON.parse(activity.action);
          return action.item === 'info' || action.item === 'warning';
        }
      }))
    }
  }, [isLoading, dataActivities]);

  useEffect(() => {
    if (currentTab === 'description') {
      setCurrentActivities(description);
    } else if (currentTab === 'interview') {
      setCurrentInterviewActivities(interview)
    } else if (currentTab === 'recomendations') {
      setCurrentActivities(recomandations);
    } else {
      setCurrentActivities(details);
    }
  }, [currentTab, description, recomandations, interview, details]);

  useEffect(() => {
    activityStatus?.status === 200
      ? activityStatus?.data === '3'
        ? setModal({ ...modal, message: "El sistema esta procesando tus respuestas, intenta de nuevo en unos minutos", type: 'inProgress', closeIcon: false, confirmButton: true })
        : (activityStatus?.data === '4' && !isButtonAttended)
          ? setModal({
            ...modal, message: 'Ya tenemos las respuestas de tu Test', type: 'success', closeIcon: false, confirmButton: true, handleClick: () => {
              dispatch(setStageCompleted({ id: idActivity }))
              navigate('/home')
            }
          })
          : activityStatus?.data === '1' && setModal({ ...modal, message: 'Error al conectar con el servidor.', type: 'error', closeIcon: true, confirmButton: false })
      : setModal({ ...modal, message: 'Error al conectar con el servidor.', type: 'error', closeIcon: true, confirmButton: false })
    dispatch(setActivityStatus({ status: activityStatus?.data }))
  }, [activityStatus])

  if (stagesData.data) {
    useEffect(() => {
      dispatch(
        setStages({
          stagesList: stagesData.data?.stages,
          isLoadingStages: false,
        })
      );
    }, [completeStatus])
  }

  useEffect(() => {
    if (!stagesData.data) {
      setError(true)
    }
  }, [stagesData.data])

  const handleActiviyStatus = () => {
    setModal({ ...modal, isOpen: true })
    getStatus()
    getStages()
    setTestInProgress(true)
  }

  const handleChangeStatus = () => {
    getStages()
    statusActivity === '2' &&
      statusActivity === '2' &&
      changeStatus({ ...activityApiParameters, status: '3' }).then(_ => {
        getStatus()
        getActivity()
      });
  }

  useEffect(() => {
    if (status !== undefined || status !== null || status !== '') {
      if (statusActivity === undefined || statusActivity === '') {
        setStatusActivity(status)
      } else if (statusActivity < status) {
        setStatusActivity(status)
      } else if (idActivityState !== idActivity) {
        setIdActivityState(idActivity)
        setStatusActivity(status)
      }
    }
  }, [status, idActivity])

  const handleModalAndAdvanceStage = () => {
    if (testWithFiles) {
      statusActivity === '2' &&
        changeStatus({ ...activityApiParameters, status: '3' }).then(_ => {
          getStatus()
          getActivity()
        });
    }
    if (isButtonAttended) {
      dispatch(setStageCompleted({ id: idActivity }))
      navigate('/home')
    } else {
      setModal(state => ({ ...state, isOpen: false }));
      statusActivity === '4' && dispatch(setStageCompleted({ id: idActivity }));
    }
  }

  const handleCompleteStatus = () => {
    statusActivity === activityStates.inProgress.toString() &&
      changeStatus({ ...activityApiParameters, status: activityStates.completed.toString() }).then(() => {
        getStatus()
        getActivity()
        getStages().then(({ data }) => {
          data &&
            dispatch(
              setStages({
                stagesList: data.data.stages,
                isLoadingStages: false,
              })
            );
          setCompletingStatus(false);
          navigate(routes.home);
        })
      });
  }

  useEffect(() => {
    [...currentActivities].map((item: IActivity) => {
      const actionObject = JSON.parse(item.action)
      if (item.type === 'linkButton') {
        const { text } = (actionObject)
        setTitleLinkButton(text)
        setLinkUploaded(true)
      }
    })
  })

  const detectAlerts = () => {
    const elementsInfo = document.getElementsByClassName('textIcon--info');
    const info = elementsInfo[0] as HTMLElement;
    const elementsWarning = document.getElementsByClassName('textIcon--warning');
    const warning = elementsWarning[0] as HTMLElement;

    if (info) {
      setPaddingForAlerts(info.offsetHeight / 10)
    } else if (warning) {
      setPaddingForAlerts(warning.offsetHeight / 10)
    }
  }



  useEffect(() => {
    detectAlerts();
  })

  if (isLoading) {
    return <Loader />;
  }

  if (isError || error) {
    return (
      <div className="currentActivity__error">
        <ErrorBoundarySide code={stagesData.errorCode} />
      </div>
    )
  }



  return (
    <>
      <ModalAlert
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        closeIcon={modal.closeIcon}
        confirmButton={modal.confirmButton}
        handleClick={modal.handleClick}
        disabledButton={modal.disabledButton}
        cancelButton={modal.cancelButton}
        cancelFunction={() => setModal(state => ({ ...state, isOpen: false }))}
        toggleModal={handleModalAndAdvanceStage}
      />
      <section
        className='activities'
        style={isAlert ? {
          marginBottom: `${paddingForAlerts}rem`
        } : {
          marginBottom: '2.9rem'
        }
        }
      >
        {
          isInterview && currentTab === 'interview' ?
            <div className='activities__description'>
              <InterviewInfo email={email} idActivity={idStage} fullName={vacant?.fullName} data={data} />
            </div>
            :
            <div className='activities__description'>
              {
                [...currentActivities].map(({ id, type, action }) => {
                  const elements = JSON.parse(action);

                  if (type === 'image') {
                    const { url, height, width, alt } = (elements as IAImage);
                    return isInterview ? <AImage key={id} url={url} height={height} width={width} alt={alt} className='activityAtomImage' /> : <AImage key={id} url={url} height={height} width={width} alt={alt} className='activityAtomImage' />;
                  }

                  if (type === 'text') {
                    const { text } = (elements as IAText);
                    return <AText key={id} text={text} classStyle={"mobile"} />;
                  }

                  if (type === 'textItem') {
                    const { text, textBold, title, item, link } = (elements as IATextItem)
                    return isInterview ?
                      <ATextItem key={id} text={text} item={item} />
                      : <ATextItem key={id} text={text} item={item} title={title} textBold={textBold} link={link} />
                  }


                  if (type === 'textWithAtoms') {
                    const { text, links: enlaces, icons, item } = (elements as IATextWithAtoms)
                    return (
                      isInterview ? <ATextWithAtoms key={id} text={text} links={enlaces} icons={icons} item={item} /> : <ATextWithAtoms key={id} text={text} links={enlaces} icons={icons} item={item} />
                    )
                  }

                  if (type === 'button') return null
                  if (type === 'inputFile') return null
                  if (type === 'testWithFiles') return null
                  if (type === 'buttonAttended') return null
                  if (type === 'linkButton') return null
                  return <p key={id}>Por favor comunícate con Pragma [error]</p>
                })
              }
            </div>
        }


        <div className="activities__buttons">
          {
            [...currentActivities].map(({ id, type, action }) => {
              const elements = JSON.parse(action);
              if (type === ContentTypes.button) {
                const { title, url } = (elements as IAButton);
                if (activityType !== ActivityTypes.interview) {
                  let url1 = url?.replace(userEmailMarker, email);
                  return (
                    <AButton
                      tag='a'
                      key={id}
                      disabled={false}
                      className={`${statusActivity === activityStates.initial.toString() ? 'button-primary--dark' : 'button-secondary'}  button__mobile`}
                      url={url1}
                      target={"_blank"}
                      handleClick={handleChangeStatus}
                    >
                      {title}
                    </AButton>
                  )
                } else {
                  return (
                    <AButton
                      tag='a'
                      key={id}
                      disabled={false}
                      className={`${statusActivity === '2' ? 'button-primary--dark' : 'button-secondary'}  button__mobile`}
                      url={parseInt(idActivity) == 5 || parseInt(idActivity) == 45 ? undefined : stages.find(item => item.id === parseInt(idActivity))?.url}
                      target={"_blank"}
                      handleClick={() => {
                        if (parseInt(idActivity) == 5 || parseInt(idActivity) == 45) {
                          dispatch(setCalendar({
                            isCalendar:true
                          }))                       
                        }
                      }}
                    >
                      {title}
                    </AButton>
                  )
                }
              }

              if (type === 'buttonAttended') {
                const { title } = (elements as IAButton)
                return (
                  <AButton
                    tag='button'
                    key={id}
                    className={`${statusActivity === '2' ? 'button-primary--dark' : 'button-secondary'} button__mobile button__mobile--attended`}
                    target={"_blank"}
                    handleClick={() => {
                      setModal({
                        message: t('messageModalAttended'), type: 'success', isOpen: true, closeIcon: false, confirmButton: true
                      })
                      changeStatus({ ...activityApiParameters, status: '4' }).then(_ => {
                        getStatus()
                        getActivity()
                      });
                      setIsButtonAttended(true)
                    }}
                  >
                    {title}
                  </AButton>
                )
              }

              if (type === 'testWithFiles') {
                const { title } = (elements as IAFileUpload)

                return (
                  <AButton
                    key={id}
                    tag='button'
                    className='button-primary--dark testWithFiles__button'
                    handleClick={() => {
                      setModal({
                        message: 'Recuerda que una vez empieces la prueba  tendrás 3 días para finalizarla y enviarla por medio de esta plataforma',
                        isOpen: true,
                        confirmButton: true,
                        type: 'question',
                        closeIcon: false,
                        cancelButton: true
                      })
                      setTestWithFiles(true)
                    }}
                  >
                    {title}
                  </AButton>
                )
              }
              if (type === 'inputFile') {
                const { title } = (elements as IAFileUpload)
                return (
                  <FileUpload key={id} title={title} linkUploaded={linkUploaded} titleLinkButton={titleLinkButton} />
                )
              }
            })
          }
          {
            activityType === 2 &&
            <>
              <AButton tag='button'
                disabled={statusActivity === activityStates.initial.toString() || isCompletingStatus}
                className={`${(statusActivity === activityStates.inProgress.toString() || statusActivity === activityStates.completed.toString()) ? 'button-primary--dark' : 'button-secondary'} button__mobile`}
                handleClick={() => {
                  if (idActivity === activities.technicalKnowledge && (statusActivity === activityStates.inProgress.toString() || statusActivity === activityStates.completed.toString())) {
                    setCompletingStatus(true);
                    handleCompleteStatus();
                  } else {
                    (statusActivity === activityStates.inProgress.toString() || statusActivity === activityStates.completed.toString()) &&
                      handleActiviyStatus();
                  }
                }}
              >
                ¡Ya completé mi test!
              </AButton>
            </>
          }

        </div>
      </section>
    </>
  )
}

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UiState {
  uiState: {
    showPasswordLogin: boolean;
    showNewPassword: boolean;
    showConfirmNewPassword: boolean;
  };
}

export const initialState: UiState = {
  uiState: {
    showPasswordLogin: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setShowPassword(
      state,
      action: PayloadAction<'login' | 'newPassword' | 'confirmNewPassword'>
    ) {
      switch (action.payload) {
        case 'login':
          state.uiState.showPasswordLogin = !state.uiState.showPasswordLogin;
          break;
        case 'newPassword':
          state.uiState.showNewPassword = !state.uiState.showNewPassword;
          break;
        case 'confirmNewPassword':
          state.uiState.showConfirmNewPassword = !state.uiState.showConfirmNewPassword;
          break;
        default:
          break;
      }
    },

  },
});

export const { setShowPassword } = uiSlice.actions;

export const selectUi = (state: RootState) => state.ui.uiState;

export default uiSlice.reducer;

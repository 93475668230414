import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  selectConfirmationCodeForm,
  selectRestorePasswordForm,
  setConfirmationCodeForm,
  setFormType,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useConfirmCodeMutation } from 'services/talento';

import { IConfirmationCodeForm } from 'utils/interfaces';
import { confirmationCodeFormSchema } from 'utils/schemas';

import { AButton, AImage } from 'components/atoms';
import './ConfirmationCodeForm.scss';
import { ReSendEmail } from 'components/molecules';
import { errorIconParagraph } from 'utils/assets';

export const ConfirmationCodeForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isValidCode, setIsValidCode] = useState(false)
  const [confirmCode, { isSuccess, error }] = useConfirmCodeMutation();
  const { confirmationCode } = useAppSelector(selectConfirmationCodeForm);
  const { email } = useAppSelector(selectRestorePasswordForm);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid, dirtyFields },
  } = useForm<IConfirmationCodeForm>({
    mode: 'onChange',
    defaultValues: { confirmationCode },
    resolver: yupResolver(confirmationCodeFormSchema),
  });

  const handleChange = ({
    currentTarget,
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    // Dispatching just when the regex is true or is empty
    if (/^\d+$/.test(value) || value === '') {
      dispatch(setConfirmationCodeForm({ name, value }));
    }
    // Losing focus(blur) when the target's value is equal to 6
    if (value.length === 6) {
      currentTarget.blur();
    }
  };

  const onSubmit: SubmitHandler<IConfirmationCodeForm> = async (values) => {
    await confirmCode({ email, code: values.confirmationCode }).unwrap();
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setFormType('confirmNewPassword'));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isValid) {
      clearErrors();
    }
  }, [isValid, error]);

  return (
    <form className="template-form" onSubmit={handleSubmit(onSubmit)}>
      <div className={`template-code__box
      ${errors?.confirmationCode && 'template-code__box--error'}
      ${error && 'template-code__box--error'}
      ${isValid && dirtyFields.confirmationCode && error == undefined && 'template-code__box--isValid'}
      `}>

        <div className="input-code-box">
          <input
            id="confirmationCode"
            type="text"
            className={
              `input-code__element 
              ${errors?.confirmationCode && 'input-code__element--error'}
              ${error && 'input-code__element--error'}
              ${isValid && dirtyFields.confirmationCode && error == undefined && 'input-code__element--isValid'}
              `}
            value={confirmationCode}
            autoComplete="off"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register('confirmationCode', { onChange: handleChange })}
          />
        </div>
      </div>


      <p className='error'>{errors?.confirmationCode && errors.confirmationCode.message}</p>

      {error && 'data' in error && (

        <p className='error-code'>
          <AImage
            role='error'
            className='error-icon'
            url={errorIconParagraph}
            alt='close'
          />
          Verifica tu código e inténtalo de nuevo
        </p>
      )}

      <div className='template-confirmation'>
        <article className="template-confirmation__resend__email">
          <ReSendEmail />
        </article>

        <AButton tag='button' type='submit' disabled={!isValid} className='button-primary--white btn__code'>
          Continuar
        </AButton>
      </div>
    </form>
  );
};

import React, { useState } from 'react';
import './ATable.scss';
import { ASelect } from '../ASelect/ASelect.components';
import { chevronLeft, chevronRight } from 'utils/assets';
import { INewCandidates } from '../../../utils/interfaces/store/talento.interfaces';
import {
  PageNumber,
  arrayFirstElement,
  divider,
  firsTableCols,
  minPageCount,
  one,
  pageSizes,
  tablePropIdentifier,
} from 'utils/constants';
import { Numbers, atomTable } from 'utils/helpers/dictionaries';
import { useNavigate } from 'react-router-dom';

interface IHeaderItem {
  label: string;
  prop: string;
}

interface IProps {
  headers: IHeaderItem[];
  data: INewCandidates[];
  isDisableButon?: boolean;
  isCompleted?: boolean;
}

export const ATable = ({ headers, data, isDisableButon, isCompleted }: IProps) => {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(
    pageSizes[arrayFirstElement].value
  );
  const [currentPage, setCurrentPage] = useState(one);

  const filteredHeaders = headers.filter(
    (header) => header.prop !== tablePropIdentifier
  );

  if (!data) {
    return <></>;
  }

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentItems = data.slice(firstItemIndex, lastItemIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const pageNumbers: number[] = [];

  for (
    let pageNumber = Numbers.one;
    pageNumber <= Math.ceil(data.length / itemsPerPage);
    pageNumber++
  ) {
    pageNumbers.push(pageNumber);
  }

  const handleItemsPerPage = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(one);
  };
  const totalPageCount = Math.ceil(data.length / itemsPerPage);

  const getPageNumbersToShow = () => {
    let pagesToShow: PageNumber[] = [currentPage];

    if (totalPageCount > minPageCount && currentPage < totalPageCount) {
      pagesToShow.push(divider, totalPageCount);
    }

    if (totalPageCount === minPageCount && currentPage < totalPageCount) {
      pagesToShow.push(totalPageCount);
    }

    return pagesToShow;
  };

  const pageNumbersToShow = getPageNumbersToShow();

  const tableHeaderClass =
    headers.length === firsTableCols
      ? atomTable.tableThreeCols
      : atomTable.tableFiveCols;

  const paginateForward = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + one);
    }
  };

  const paginateBackward = () => {
    if (currentPage > one) {
      setCurrentPage(currentPage - one);
    }
  };
  const handleFormPage = (email: any) => {
    if (!isCompleted)
      navigate('/admin/candidateForm/' + email);
    else 
      navigate('/admin/completedCandidateForm/' + email);
  };

  return (
    <>
      <div className="tableContainer">
        <ASelect
          data={pageSizes}
          value={itemsPerPage}
          onChange={(value: string | number) =>
            handleItemsPerPage(Number(value))
          }
        />
        <div className="scrollTable">
          <table className="atomTable">
            <thead className={tableHeaderClass}>
              <tr>
                {filteredHeaders.map((header, index) => (
                  <th key={index}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody className="atomTable__tableBody">
              {currentItems.map((item) => (
                <tr className="atomTable__tableBody__tableRow" key={item.email}>
                  {filteredHeaders.map((header, columnIndex) => (
                    <td key={columnIndex}>
                      {item[header.prop]}

                      {columnIndex == filteredHeaders.length - one ? (
                        <button
                          onClick={() => {
                            handleFormPage(item.email);
                          }}
                          className="actionbutton"
                          disabled={isDisableButon}
                        >
                          <img
                            src={chevronRight}
                            alt={`Ver ${item.fullName}`}
                          />
                        </button>
                      ) : null}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="navContainer">
            {currentPage > one && (
              <img
                className="paginationRow"
                src={chevronLeft}
                alt="Anterior"
                onClick={paginateBackward}
              />
            )}
            {pageNumbersToShow.map((number, index) => (
              <React.Fragment key={index}>
                {number === divider ? (
                  <span>...</span>
                ) : (
                  <li
                    onClick={() => paginate(number as number)}
                    className={currentPage === number ? 'active' : ''}
                  >
                    {number}
                  </li>
                )}
              </React.Fragment>
            ))}
            {currentPage < totalPageCount && (
              <img
                className="paginationRow"
                src={chevronRight}
                alt="Siguiente"
                onClick={paginateForward}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

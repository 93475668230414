import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IUserConfirmData } from '../ConfirmDataTabForm';
import { ReactComponent as TrashIcon } from 'utils/assets/images/trash.svg';
import './EmergencyContactsSection.scss';
import { Input } from 'components/atoms';
import { AddButton } from '../../../atoms/AddButton/AddButton';
import { useUpdateEmergencyContactInformationMutation } from 'services/talento';
import { useEffect } from 'react';

export default function EmergencyContactsSection() {
  const { control, getValues } = useFormContext<IUserConfirmData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emergencyContactInformation',
  });

  const [updateEmergencyContact] =
    useUpdateEmergencyContactInformationMutation();

  function onClickAppend() {
    append({ id: null, fullname: '', primaryPhone: '' });
  }

  function onClickRemove(index: number) {
    remove(index);
    handleOnBlurSubmit();
  }

  useEffect(() => {
    if (fields.length < 1) {
      append({ id: null, fullname: '', primaryPhone: '' });
    }
  }, [fields, append]);

  function renderButton(index: number) {
    if (fields.length > 1 && index !== 0) {
      return (
        <div className="delete-button">
          <div onClick={() => onClickRemove(index)}>
            <TrashIcon />
          </div>
        </div>
      );
    } else if (fields.length === 1) {
      return (
        <div className="add-button">
          <AddButton onClick={onClickAppend} />
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function handleOnBlurSubmit() {
    updateEmergencyContact({
      id: getValues('id'),
      emergencyContactInformation: getValues('emergencyContactInformation'),
    });
  }

  return (
    <div className="EmergencyContactSection">
      {fields.map((field, index) => (
        <>
          <Controller
            control={control}
            name={`emergencyContactInformation.${index}.fullname` as const}
            defaultValue={field.fullname as any}
            render={({ field: { value, onBlur, ...rest }, fieldState }) => (
              <Input
                type="text"
                id={`emergencyContactInformation.${index}.fullname`}
                label="Nombre completo"
                subtext={fieldState.error?.message}
                defaultValue={value ?? ''}
                error={fieldState.error !== undefined}
                onBlur={() => {
                  onBlur();
                  handleOnBlurSubmit();
                }}
                {...rest}
              />
            )}
          />
          <Controller
            control={control}
            name={`emergencyContactInformation.${index}.primaryPhone` as const}
            defaultValue={field.primaryPhone as any}
            render={({ field: { value, onBlur, ...rest }, fieldState }) => (
              <Input
                id={`emergencyContactInformation.${index}.primaryPhone`}
                type="text"
                label="Móvil"
                subtext={fieldState.error?.message}
                error={fieldState.error !== undefined}
                defaultValue={value ?? undefined}
                onBlur={() => {
                  onBlur();
                  handleOnBlurSubmit();
                }}
                {...rest}
              />
            )}
          />
          {renderButton(index)}
        </>
      ))}
    </div>
  );
}

import { upperCaseFirstchart } from "utils/helpers/upperCaseFirstChart";
import { AButton } from "components/atoms";
import './CardScheduleDay.scss';

interface IProps {
  isToday: boolean;
  dayOfWeek: string;
  day: string;
  setCurrentDay: (indexInArray: number) => void;
  indexInArray: number;
  isSelected: boolean;
}

export const CardScheduleDay = ({ isToday, dayOfWeek, day, setCurrentDay, indexInArray, isSelected }: IProps) => {
  const dayText = dayOfWeek.slice(0, 3)

  return (
    <AButton
      tag='button'
      className={`card-schedule-day ${isSelected && 'card-schedule-day--selected'}`}
      handleClick={() => setCurrentDay(indexInArray)}
    >
      <p className={`${isToday && 'card-schedule-day__today'}`}>
        {isToday ? 'Hoy' : <>&nbsp;</>}
      </p>

      <p className="card-schedule-day__text">
        {upperCaseFirstchart(dayText)}
      </p>

      <p className="card-schedule-day__text">
        {day}
      </p>
    </AButton>
  )
}

import { AImage, AButton, Loader } from 'components/atoms';
import { useEffect, useState } from 'react';
import { useDeleteSavedDocumentMutation } from 'services/talento';
import { selectCurrentActivity, selectUserInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { file, xCloseIcon, GroupLink, trash } from 'utils/assets';
import { IDocument, IDocuments, IFiles } from 'utils/interfaces';
import { ModalAlert } from '../ModalAlert/ModalAlert.components';
import './FileLoading.scss';
import { Numbers } from 'utils/helpers/dictionaries';
interface Props {
  deleteDocument?: (id: any, document: IDocument) => void;
  documentDelete?: IDocument;
  documentID?: string;
  files?: IFiles[];
  fileSize?: string;
  isLoadingDelete?: boolean;
  isUploading?: boolean;
  link?: boolean;
  nameFile?: string;
  setFiles?: (state: IFiles[]) => void;
  setIsLoading?: (state: boolean) => void;
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>;
  setKeyInputs?: (state: number) => void;
  setLinkUrl?: (state: string | undefined) => void;
  url?: string;
  setFilesToDelete?: (state: IFiles[]) => void;
}
export const FileLoading = ({
  deleteDocument = () => {},
  documentDelete,
  documentID,
  files,
  fileSize,
  isUploading,
  link,
  nameFile = '',
  setFiles = () => {},
  setIsLoading = () => {},
  setIsUploading = () => {},
  setKeyInputs = () => {},
  setLinkUrl = () => {},
  url,
  setFilesToDelete = () => {},
}: Props) => {
  const { userState } = useAppSelector(selectUserInfo);
  const [isHiring, setisHiring] = useState(userState === 5 ? true : false);
  const [bars, setBars] = useState(true);

  const { email } = useAppSelector(selectUserInfo);
  const [deleteSavedDocument, { isLoading: isLoadingDelete }] =
    useDeleteSavedDocumentMutation();

  const handleCleanInputFile = (fileNameToDelete: String) => {
    if (files) {
      setKeyInputs(Date.now());
      let fileToDelete = files.filter(
        (item) => item.nameFile == fileNameToDelete
      );
      setFilesToDelete(fileToDelete);

      let deleteFile = files.filter((item) => item.nameFile !== nameFile);
      setFiles(deleteFile);

      setIsLoading(false);
    } else if (link) {
      setLinkUrl(undefined);
      setKeyInputs(Date.now());
    }
  };

  const handleDelete = async (type: keyof IDocuments, document: IDocument) => {
    setIsUploading(true);

    const body = {
      email,
      newNameDocument: document.newName,
      url: document.url,
    };

    // @ts-ignore
    await deleteSavedDocument(body).unwrap();

    deleteDocument(type, document);

    setIsUploading(false);
  };

  const startPercentAnim = () => {
    let porcentaje = Numbers.zero;

    let idInterval = setInterval(() => {
      if (porcentaje < Numbers.haundred) {
        porcentaje = porcentaje + Numbers.ten;
      } else {
        porcentaje = Numbers.haundred;
      }

      let pELement = document.getElementById('bar-loading__porcentaje');
      if (pELement) {
        pELement.textContent = `${porcentaje}%`;
      }
    }, 200);

    setTimeout(() => {
      clearInterval(idInterval);
      setBars(false);
      setIsLoading(false);
      setIsUploading(false);
    }, 2190);
  };

  useEffect(() => {
    if (link) {
      setIsLoading(false);
      setBars(false);
    } else {
      setIsLoading(true);
      startPercentAnim();
    }
  }, []);

  return (
    <>
      <div
        className={isHiring ? 'file-loading__documents' : 'file-loading'}
        data-testid="file-loading"
      >
        {!isHiring && (
          <AImage
            url={link ? GroupLink : file}
            className="file-loading__icon"
            alt="file-icon"
          />
        )}
        {isLoadingDelete ? (
          <Loader className="loader__purple" />
        ) : (
          <>
            <div className={isHiring && !bars ? 'pdf_name' : 'loader_bar'}>
              <AButton
                tag="a"
                url={url}
                target="_blank"
                className={`file-loading__name-file ${
                  !bars ? 'file-loading__name-file--link' : ''
                } ${link ? 'file-loading__name-file--url' : ''}`}
              >
                {nameFile}
              </AButton>

              {fileSize && (
                <p className="" data-testid="file-loading__size">
                  {fileSize} MB
                </p>
              )}
              {bars && (
                <div className="bar-loading">
                  <div className="bar-loading__progress bar-loading__progress--avance"></div>
                  <p
                    className="bar-loading__porcentaje"
                    id="bar-loading__porcentaje"
                  >
                    %
                  </p>
                </div>
              )}
            </div>
            <span
              className="file-loading__close"
              onClick={
                isHiring && documentID && documentDelete
                  ? () =>
                      handleDelete(
                        documentID as keyof IDocuments,
                        documentDelete
                      )
                  : () => handleCleanInputFile(nameFile)
              }
            >
              <AImage
                className={isHiring ? 'iconDelete' : ''}
                url={isHiring ? trash : xCloseIcon}
                width={20}
                height={16}
                alt="x-icon"
                font-weight={100}
              />
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default FileLoading;

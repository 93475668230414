import axios from "axios"

const url = process.env.REACT_APP_BITACORA_TOKEN || ''

export const getToken = async () => {

    let token = ''

    await axios.get("https://qamn7n5yy1.execute-api.us-east-1.amazonaws.com/token")
        .then(response => {
            token = response.data.token;
        })

    return token
}

import { ChangeEvent, useState, useEffect } from 'react';
import { bytesToMB, isValidMagicNumber } from 'utils/helpers';
import { IFiles } from 'utils/interfaces';
import './AInputImage.scss';
import { isValidImage } from 'utils/helpers/isValidFile';
import { ImageUploadConstants } from 'utils/constants/constants';

const acceptedType = ImageUploadConstants.ACCEPTED_TYPES;
const maxFileSize = ImageUploadConstants.MAX_FILE_SIZE_MB;
const fileLimitExceededMessage =
  ImageUploadConstants.FILE_LIMIT_EXCEEDED_MESSAGE;
const fileLimitExceededTitle = ImageUploadConstants.FILE_LIMIT_EXCEEDED_TITLE;
const invalidFileTypeMessage = ImageUploadConstants.INVALID_FILE_TYPE_MESSAGE;

interface Props {
  setModal: (state: any) => void;
  setFiles?: (state: IFiles[]) => void;
  disabled?: boolean;
  files?: IFiles[];
  accept: string;
  keyInputs?: number;
  onDelete?: IFiles[];
}

export const AInputImage = ({
  disabled,
  setModal,
  setFiles = () => {},
  files,
  accept,
  keyInputs = 0,
  onDelete,
}: Props) => {
  const [totalFileSize, setTotalFileSizeLocal] = useState<number>(0);

  useEffect(() => {
    if (files) {
      const sizeToDelete = files.reduce(
        (acc, file) => acc + parseFloat(file.fileSize),
        0
      );
      setTotalFileSizeLocal(sizeToDelete);
    }
  }, [onDelete]);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e && e.target.files) {
      const file = e.target.files[0];
      if (isValidImage(file).isValid) {
        isValidMagicNumber(file).then((response: any) => {
          if (
            response.type === acceptedType.JPEG ||
            response.type === acceptedType.PNG ||
            response.type === acceptedType.JPG
          ) {
            if (files) {
              const fileSize = parseFloat(bytesToMB(file.size));
              const newTotalSize = totalFileSize + fileSize;

              if (newTotalSize <= maxFileSize) {
                setTotalFileSizeLocal(newTotalSize);
                let newFiles = files.concat({
                  fileSize: bytesToMB(file.size),
                  nameFile: file.name,
                  file: file,
                  urlFile: URL.createObjectURL(file),
                  base64: '',
                });
                setFiles(newFiles);
              } else {
                setModal({
                  message: fileLimitExceededMessage,
                  title: fileLimitExceededTitle,
                  isOpen: true,
                  type: 'error',
                  confirmButton: true,
                });
              }
            }
          } else {
            setModal({
              message: invalidFileTypeMessage,
              isOpen: true,
              type: 'error',
              confirmButton: true,
            });
          }
        });
      } else {
        const { message = '', title = '' } = isValidImage(file);
        setModal({
          message,
          title,
          isOpen: true,
          type: 'error',
          closeIcon: true,
        });
      }
    } else {
      return;
    }
  };

  return (
    <input
      type="file"
      id="upload_file"
      name="file"
      data-testid="upload_file"
      accept={accept}
      style={{ display: 'none' }}
      onChange={handleFile}
      disabled={disabled}
      role="input"
      key={keyInputs}
    />
  );
};

export default AInputImage;

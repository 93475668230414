import { useEffect, useState } from 'react';
import { selectUi, setShowPassword } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';
import { AButton, AImage, Label } from "components/atoms"
import { changePasswordFormSchema } from 'utils/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IChangePassword, IModal, IToast } from 'utils/interfaces';
import { AlertToast, ModalAlert } from 'components/molecules';
import { errorIconParagraphProfile, profilePasswordHideIcon, profilePasswordShowIcon, warningIcon } from 'utils/assets';
import { Auth } from 'aws-amplify';
import "./ChangePasswordForm.scss"
import { useWindowMobile } from 'hooks/useWindowMobile';

interface IProps {
    handleBack?: () => void;
    profilePage?: boolean;
}

export const ChangePasswordForm = ({ handleBack, profilePage = false }: IProps) => {

    const { showNewPassword, showConfirmNewPassword, showPasswordLogin } = useAppSelector(selectUi);
    const { translations, isLoading } = useAppSelector(selectTranslations);

    const { t } = useTranslation(translations, isLoading);
    const dispatch = useAppDispatch();

    const [validationError, setValidationError] = useState('')
    const [modal, setModal] = useState<IModal>({ message: '', type: 'success', isOpen: false })
    const [alertToast, setAlertToast] = useState<IToast>({ text: '', isOpen: false })
    const { isMobile } = useWindowMobile(744);

    const {
        register,
        handleSubmit,
        getValues,
        clearErrors,
        resetField,
        setError,
        formState: { errors, dirtyFields, isValid },
    } = useForm<IChangePassword>({
        mode: 'all',
        resolver: yupResolver(changePasswordFormSchema),
    });

    const onSubmit = async () => {
        const values = getValues();
        values.currentPassword === values.newPassword ? setValidationError('Tu contraseña nueva no puede ser igual a la actual') :

            await Auth.currentAuthenticatedUser()
                .then((user) => {
                    return Auth.changePassword(user, values.currentPassword, values.newPassword)
                })
                .then((data) => {
                    resetField('confirmNewPassword')
                    resetField('currentPassword')
                    resetField('newPassword')
                    setValidationError('')
                    if (profilePage) {
                        setAlertToast({ text: t('messageChangePassSuccess'), isOpen: true })

                    setTimeout(() => {
                        setAlertToast({ text: t('messageChangePassSuccess'), isOpen: false })
                    }, 3000)
                }else{
                    setModal({ message: 'Se cambio tu contraseña correctamente', type: 'success', isOpen: true })
                }
            })
            .catch((err) => {
                if(err.name?.includes('NotAuthorizedException')) {
                    setError('currentPassword', {type: 'custom', message: 'La contraseña actual es incorrecta'} )
                } else if (err.name?.includes('LimitExceededException')) {
                    setValidationError(t('limitExceededException'))
                } else {
                    setModal({ message: 'No se pudo procesar su solicitud', type: 'error', isOpen: true })
                }
            })
    };
    useEffect(() => {
        const values = getValues();
        if (isValid) { clearErrors(); setValidationError('') } else {
            if (values.newPassword !== values.confirmNewPassword) {
                setError('confirmNewPassword', { type: 'custom', message: 'Las contraseñas no coinciden' })
            }
        }
    }, [isValid]);

    return (

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="change-password-form" role="form">
            {
                profilePage ?
                    <AlertToast
                        isOpen={alertToast.isOpen}
                        text={alertToast.text}
                        modifier='profile'
                    />
                    :
                    <ModalAlert
                        alertType={modal.type as any}
                        alertText={modal.message}
                        alertTitle={modal.title ? modal.title : undefined}
                        isOpened={modal.isOpen}
                        closeIcon={true}
                        confirmButton={false}
                        handleClick={handleBack}
                        toggleModal={(isOpen: boolean) => setModal(state => ({ ...state, isOpen }))
                        }
                    />
            }
            <p className="change-password-form__description">{t('paramsChangePassword')}</p>

            <div className='change-password-form__inputs'>

                <div className="input-password-wrapper">

                    <div className={
                        `input-password 
                        ${errors?.currentPassword && 'error'} 
                        ${errors?.currentPassword == undefined && dirtyFields.currentPassword && 'isValidCurrent'}`
                    }>
                        <input
                            data-testid="oldPassword"
                            id="oldPassword"
                            type={showPasswordLogin ? 'text' : 'password'}
                            className="input-password__field"
                            placeholder={isMobile ? 'Contraseña actual' : 'Ingresa tu contraseña actual'}
                            {...register("currentPassword")}
                            tabIndex={1}
                        />
                        <AButton tag='button' type='button' className={!errors.currentPassword ? "input-password__button" : "input-password__button-error"} handleClick={() => dispatch(setShowPassword('login'))}>
                            <AImage
                                role='presentation'
                                className='input-text__icon'
                                url={errors?.currentPassword ? warningIcon : showPasswordLogin ? profilePasswordShowIcon : profilePasswordHideIcon}
                                alt=''
                            />
                        </AButton>
                    </div>
                    <span className='input-password-wrapper__error'>
                        {errors?.currentPassword &&
                            <AImage
                                role='error'
                                className='error-icon'
                                url={errorIconParagraphProfile}
                                alt='close'
                            />
                        }
                        {errors?.currentPassword?.message}
                    </span>
                </div>

                <div className="input-password-wrapper">

                    <div className={
                        `input-password 
                        ${errors?.newPassword && 'error'} 
                        ${errors?.newPassword == undefined && dirtyFields.newPassword && 'isValid'}`
                    }>
                        <input
                            id="newPassword"
                            data-testid="newPassword"
                            type={showNewPassword ? 'text' : 'password'}
                            className="input-password__field"
                            placeholder={isMobile ? 'Contraseña nueva' : 'Ingresa tu contraseña nueva'}
                            {...register("newPassword")}
                            tabIndex={2}
                        />
                        <AButton tag='button' type='button' className={!errors.newPassword ? "input-password__button" : "input-password__button-error"} handleClick={() => dispatch(setShowPassword('newPassword'))}>
                            <AImage
                                role='presentation'
                                className='input-text__icon'
                                url={errors?.newPassword ? warningIcon : showNewPassword ? profilePasswordShowIcon : profilePasswordHideIcon}
                                alt=''
                            />
                        </AButton>
                    </div>

                    <span className='input-password-wrapper__error'>
                        {errors?.newPassword &&
                            <AImage
                                role='error'
                                className='error-icon'
                                url={errorIconParagraphProfile}
                                alt='close'
                            />
                        }
                        {errors?.newPassword?.message}
                    </span>

                </div>

                <div className="input-password-wrapper">

                    <div className={
                        `input-password 
                        ${errors?.confirmNewPassword && 'error'} 
                         ${errors?.confirmNewPassword == undefined && dirtyFields.confirmNewPassword && 'isValid'}`
                    }>
                        <input
                            id="confirmNewPassword"
                            data-testid="confirmNewPassword"
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            className="input-password__field"
                            placeholder={isMobile ? 'Repetir contraseña nueva' : 'Confirma tu contraseña nueva'}
                            {...register("confirmNewPassword")}
                            tabIndex={3}
                        />
                        <AButton tag='button' type='button' className={!errors.confirmNewPassword ? "input-password__button" : "input-password__button-error"} handleClick={() => dispatch(setShowPassword('confirmNewPassword'))}>
                            <AImage
                                role='presentation'
                                className='input-text__icon'
                                url={errors?.confirmNewPassword ? warningIcon : showConfirmNewPassword ? profilePasswordShowIcon : profilePasswordHideIcon}
                                alt=''
                            />
                        </AButton>
                    </div>
                    <span className='input-password-wrapper__error'>
                        {errors?.confirmNewPassword &&
                            <AImage
                                role='error'
                                className='error-icon'
                                url={errorIconParagraphProfile}
                                alt='close'
                            />
                        }
                        {errors?.confirmNewPassword?.message}
                    </span>
                    <span className='input-password-wrapper__error'>
                        {validationError !== '' &&
                            <>
                                <AImage
                                    role='error'
                                    className='error-icon'
                                    url={errorIconParagraphProfile}
                                    alt='close'
                                /> {validationError}
                            </>
                        }
                    </span>
                </div>
            </div>
            <div className="change-password-form__button">
                <AButton role='button' data-testid="btnSaveChangePassword" tag='button' type='submit' disabled={!isValid} className="button-primary--dark button__send-form">
                    Guardar
                </AButton>
            </div>
        </form >
    )
}

export default ChangePasswordForm
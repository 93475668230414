export const scheduleTexts = {
  sorry: 'Lo sentimos 🙁, Por el momento no hay agenda disponible',
  noSchedule:
    '¡Pero no te preocupes! Te avisaremos una vez habilitemos nuevos espacios, permanece atento a tu correo.',
  date: '1. Selecciona la fecha',
  hour: '2. Selecciona la hora',
  morning: 'Mañana',
  afternoon: 'Tarde',
  schedule: 'Agendar',
  diary: 'Agenda',
};

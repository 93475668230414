import styles from './NotificationsPanel.module.scss'
import { Link } from 'react-router-dom';
interface ListNotifications {
    title: string,
    description: string,
    link: string,
    onClick:() => void; 
}
export const ListNotification = ({ title, description, link, onClick }:ListNotifications)=>{
    return(
        <div className={styles.notification} >
            <h3>{title}</h3>
            <p>{description}</p>
            <Link
                to={link}
            >
                <button role='button' onClick={onClick}>Ver</button>
            </Link>
        </div>
    )
}
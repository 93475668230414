import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IUserConfirmData } from '../ConfirmDataTabForm';
import { ReactComponent as TrashIcon } from 'utils/assets/images/trash.svg';
import styles from './EducationSection.module.scss';
import { ACalendar, Input, Label } from 'components/atoms';
import moment from 'moment';
import { useState } from 'react';
import ACalendarNext from 'components/atoms/AInputDateNext/ACalendarNext.component';
import { AddButton } from '../../../atoms/AddButton/AddButton';
import CatalogSelect from 'components/molecules/CatalogSelect/CatalogSelect';
import { catalogoPersonal, Numbers } from 'utils/helpers/dictionaries';
import { useUpdateAcademicInformationMutation } from 'services/talento';
import useCalendar from 'hooks/useCalendar';
export default function EducationSection() {
  const { control, getValues } = useFormContext<IUserConfirmData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'academicInformation',
  });

  const [updateAcademicInformation] = useUpdateAcademicInformationMutation();

  const [_, setMarginForCalendar] = useState(0);
  const [startDateCalendarsState, setStartDateCalendarState] = useState<
    boolean[]
  >([false]);

  const [endDateCalendarsState, setEndDateCalendarState] = useState<boolean[]>([
    false,
  ]);

  function handleOnBlurSave() {
    updateAcademicInformation({
      academicInformation: getValues('academicInformation'),
      id: getValues('id'),
    });
  }

  function setIsStartDateCalendarOpen(index: number, value: boolean) {
    setStartDateCalendarState((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
    if (value) {
      setEndDateCalendarState((prevState) => prevState.map(() => false));
    }
  }

  function setIsEndDateCalendarOpen(index: number, value: boolean) {
    setEndDateCalendarState((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
    if (value) {
      setStartDateCalendarState((prevState) => prevState.map(() => false));
    }
  }

  function onClickAppend() {
    append({
      id: null,
      educationalLevel: '',
      institution: '',
      endDate: '',
      startDate: '',
      professionalCard: '',
      professionalTitle: '',
    });
  }

  function onClickRemove(index: number) {
    remove(index);

    setStartDateCalendarState((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });

    setEndDateCalendarState((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });

    handleOnBlurSave();
  }
  
  function handleCalendarCloseOnClick() {
    setStartDateCalendarState((prevState) => prevState.map(() => false));
    setEndDateCalendarState((prevState) => prevState.map(() => false));
  }
  return (
    <div className={styles.AcademicInfo}>
      {fields.map((item, index) => (
        <div className={styles.AcademicInfo__item} key={item.id}>
          {index > 0 && (
            <div className={styles.AcademicInfo__delete}>
              <div onClick={() => onClickRemove(index)}>
                <TrashIcon height={20} />
              </div>
            </div>
          )}
          <div className={styles.AcademicInfo__item__content}>
            <Controller
              control={control}
              name={`academicInformation.${index}.institution` as const}
              defaultValue={item.institution as any}
              render={({ field: { value, onBlur, ...rest }, fieldState }) => (
                <Input
                  id={`academicInformation.${index}.institution`}
                  type="text"
                  label="Institución"
                  error={fieldState.error !== undefined}
                  defaultValue={value ?? undefined}
                  subtext={fieldState.error?.message}
                  onBlur={() => {
                    handleOnBlurSave();
                    onBlur();
                  }}
                  {...rest}
                />
              )}
            />
            <Controller
              control={control}
              name={`academicInformation.${index}.educationalLevel` as const}
              defaultValue={item.educationalLevel as any}
              render={({ field: { value, onChange, ...rest }, fieldState }) => (
                <CatalogSelect
                  onClick={handleCalendarCloseOnClick}
                  catalog={catalogoPersonal.degree}
                  textLabel="Nivel educativo"
                  htmlForLabel="educationalLevel"
                  subtext={fieldState.error?.message}
                  placeholder={'Seleccione uno'}
                  handleChange={(value) => {
                    onChange(value);
                    handleOnBlurSave();
                  }}
                  value={{ label: value as any, value: value as any }}
                  error={fieldState.error !== undefined}
                  {...rest}
                />
              )}
            />
            <Controller
              control={control}
              name={`academicInformation.${index}.professionalTitle` as const}
              defaultValue={item.professionalTitle as any}
              render={({ field: { value, onBlur, ...rest }, fieldState }) => (
                <Input
                  id={`academicInformation.${index}.professionalTitle`}
                  type="text"
                  label="Título"
                  error={fieldState.error !== undefined}
                  subtext={fieldState.error?.message}
                  defaultValue={value ?? undefined}
                  onBlur={() => {
                    handleOnBlurSave();
                    onBlur();
                  }}
                  {...rest}
                />
              )}
            />
            <Controller
              control={control}
              name={`academicInformation.${index}.startDate` as const}
              defaultValue={item.startDate as any}
              render={({
                field: { onChange, value, onBlur, ...rest },
                fieldState,
              }) => (
                <div>
                  <Label text="Fecha de inicio" htmlFor="birthdate" />
                  <ACalendar
                    birthdateForm={moment(value)}
                    updateBirthdate={(value: any) => {
                      onChange(value);
                      handleOnBlurSave();
                      onBlur();
                    }}
                    setMarginForCalendar={setMarginForCalendar}
                    isOpenCalendar={startDateCalendarsState[index]}
                    setIsOpenCalendar={(value: any) =>
                      setIsStartDateCalendarOpen(index, value)
                    }
                    error={fieldState.error !== undefined}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name={`academicInformation.${index}.endDate` as const}
              defaultValue={item.endDate as any}
              render={({
                field: { onChange, value, onBlur, ...rest },
                fieldState,
              }) => (
                <div>
                  <Label text="Fecha de fin" htmlFor="birthdate" />
                  <ACalendar
                    birthdateForm={moment(value)}
                    updateBirthdate={(value: any) => {
                      onChange(value);
                      onBlur();
                      handleOnBlurSave();
                    }}
                    setMarginForCalendar={setMarginForCalendar}
                    isOpenCalendar={endDateCalendarsState[index]}
                    setIsOpenCalendar={(value: any) =>
                      setIsEndDateCalendarOpen(index, value)
                    }
                    error={fieldState.error !== undefined}
                  />
                </div>
              )}
            />
            <div className={styles.AcademicInfo__AddButton}>
              {index === fields.length - 1 && fields.length < Numbers.five && (
                <AddButton onClick={onClickAppend} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

import { useGetGendersQuery } from 'services/sap';
import { catalogoPersonal, catalogoLenguage } from 'utils/helpers/dictionaries';

type CatalogResponse = { loading: boolean; data: string[] };

/**
 * Custom hook to fetch catalog data.
 * @param name - The name of the catalog.
 * @param language - The language of the catalog (default: catalogoLenguage.ES).
 * @returns An object containing the loading state and the catalog data.
 */
export default function useCatalog(
  name: catalogoPersonal,
  language: catalogoLenguage[] = [catalogoLenguage.ES],
  filter?: catalogoLenguage[],
  father?: string
): CatalogResponse {
  const { data, isLoading } = useGetGendersQuery({
    catalogName: name,
    languages: language,
    filter,
    father,
  });

  const newData = data as { [key: string]: string }[];

  if (newData === undefined) {
    return {
      loading: isLoading,
      data: ['Cargando'],
    };
  }

  const values = newData.map((value) => Object.values(value)[0]);

  return {
    loading: isLoading,
    data: values,
  };
}

import { useState } from 'react';
import './ASelect.scss';
import { chevronDown } from 'utils/assets';

interface IProps {
  data: { label: string; value: number | string }[];
  value: number | string | undefined;
  onChange: (value: number | string) => void;
}

export const ASelect = ({ data, value, onChange }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (newValue: number | string) => {
    onChange(newValue);
    setIsOpen(false);
  };

  return (
    <div className="customSelectContainer">
      <div className="selectedValue" onClick={() => setIsOpen(!isOpen)}>
        {data.find((element) => element.value === value)?.label ||
          'Seleccione una opción'}{' '}
        <img
          className={isOpen ? 'rotated' : ''}
          src={chevronDown}
          alt="dropdown"
        />
      </div>
      {isOpen && (
        <div className="optionsContainer">
          {data.map((element) => (
            <div
              key={element.value}
              className="optionItem"
              onClick={() => handleSelect(element.value)}
            >
              {element.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

import { useState, createContext, useContext } from 'react';
import './DatePickers.scss';

interface IHour {
    startHour:string,
    endHour:string
}

interface IProps {
    date?: string,
    handleClick?: () => void,
    hour?:IHour[],
    isActive?:boolean
    nameDay?:string,
    type: string,
}


export const DatePickers = (props: IProps) => {
    
    const { date, type, nameDay, handleClick, hour, isActive} = props;



    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const getDayAndDate = (dateTime: string) => {
        // Agregar 'T00:00:00-05:00' al final para indicar zona horaria GMT-5 si no está presente
        const formattedDate = dateTime.endsWith('-05:00') ? dateTime : dateTime + 'T00:00:00-05:00';

        const dateObject = new Date(formattedDate);
        const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric' };

        // Especificar la zona horaria GMT-5 al formatear la fecha
        const dayOfWeek = dateObject.toLocaleDateString('es-ES', { ...options, timeZone: 'America/Bogota' });
        const dayOfMonth = dateObject.getDate();

        return { dayOfWeek, dayOfMonth };
    };

    const isToday = (dateTime: string) => {
        const formattedDate = dateTime.endsWith('-05:00') ? dateTime : dateTime + 'T00:00:00-05:00';
        const dateObject = new Date(formattedDate);
        const today = new Date(); // Obtiene la fecha actual

        // Obtener partes de la fecha en GMT-5
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        const todayDate = today.getDate();

        const dateYear = dateObject.getFullYear();
        const dateMonth = dateObject.getMonth();
        const dateDate = dateObject.getDate();

        return (
            dateYear === todayYear &&
            dateMonth === todayMonth &&
            dateDate === todayDate
        );
    };

    





    // Estado para manejar el día seleccionado y el tipo actual
    const [selectedHours, setSelectedHours] = useState<string[]>([]);

    

    const handleDayClick = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
        // Cambia el tipo a 'hour' cuando se hace clic en un día
    };

    const handleHourClick = (hour: string) => {
        // Si la hora ya está seleccionada, deselecciónala
        if (selectedHours.includes(hour)) {
            setSelectedHours(selectedHours.filter((h) => h !== hour));
        } else {
            // Agrega la hora seleccionada
            setSelectedHours([...selectedHours, hour]);
        }
    };

    return (
        <div className='containerPicker'>
            {type === 'day' && date && (
                <div
                    className={isActive ? 'picker picker__active': 'picker'}
                    onClick={handleClick && handleClick !== undefined ? handleClick : () => handleDayClick(0)} // Establece el índice como 0 al hacer clic
                >
                    {isToday(date) && <p>Hoy</p>}
                    <p className='picker__text mt-#2'>{nameDay?.slice(0,3)}</p> {/* Muestra el día de la semana */}
                    <p className='picker__day'>{getDayAndDate(date).dayOfMonth}</p> {/* Muestra el número del día */}
                </div>
            )}


            {type === 'hour' && hour && (
                // Renderiza Date Pickers de tipo 'hour' solo si hay un DatePicker activo
                <div className="hour-pickers">                    
                        <div                            
                        className={isActive? 'picker picker__hour picker__active' : 'picker picker__hour'}
                        onClick = { handleClick && handleClick !== undefined ? handleClick : () => handleDayClick(0)} // Establece el índice como 0 al hacer clic
                        >
                        {hour[0].startHour} - {hour[0]?.endHour}                                                 
                        </div>
                    
                </div>
            )}
        </div>
    );
}

export default DatePickers;

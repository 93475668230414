import { useEffect, useState } from 'react';


import { currentActivity, selectCurrentActivity, selectStages, selectUserInfo, setScheduleActive } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IStage, TStageState } from 'utils/interfaces/store/user.interfaces';
import { IIconStage } from 'utils/interfaces/store/user.interfaces';

import useViewport from 'utils/hooks/useViewport';

import { AImage, AButton } from 'components/atoms';

import './Stage.scss';
import { useNavigate } from 'react-router-dom';
import { BorderStageSelected } from 'utils/assets';
import { useWindowMobile } from 'hooks/useWindowMobile';

interface IProps {
  id: number;
  name: string;
  icons: IIconStage[];
  state?: TStageState; 
  idStage: number;
}

export const Stage = ({ id, name, icons, state, idStage }: IProps) => {
  const navigate = useNavigate();
  const viewport = useViewport()
  const dispatch = useAppDispatch();
  const { stages } = useAppSelector(selectStages);
  const { idActivity } = useAppSelector(selectCurrentActivity)
  const { userState } = useAppSelector(selectUserInfo)
  const { isMobile } = useWindowMobile(744);

  // tener el icon general ()
  const [icon, setIcon] = useState("");

  useEffect(() => {
    const activity = stages.find((stage: IStage) => stage.id === idStage);
    setIcon(activity?.icons.find((icon: IIconStage) => icon.identifier === 'normal')?.urlIcon || '')
  }, [icons, viewport, idActivity]);

  let stageActivated: boolean = true;
  let iconState!: string;

  // icons-state
  switch (state) {
    case 'pending':
      break;
    case 'completed':
      stageActivated = false
      break;
    case 'locked':
      stageActivated = false
      break;
    case 'active-lock':
      stageActivated = false
      break;
    default:
      break;
  }

  const handleClick = (idStage: number) => {
    const activity = stages.find((stage: IStage) => stage.id === idStage);
    const icon = activity?.icons.find((icon: IIconStage) => icon.identifier === 'normal')?.urlIcon;

    // remove calendar from view if active
    dispatch(setScheduleActive({ isActive: false }));

    dispatch(currentActivity({
      idActivity: idStage.toString(),
      iconActivity: icon || '',
      nameActivity: activity?.name || '',
      isLoadingCurrentActivity: false,
      status: activity?.status,
      open: false
    }))

    setTimeout(() => {
      dispatch(currentActivity({
        idActivity: idStage.toString(),
        iconActivity: icon || '',
        nameActivity: activity?.name || '',
        isLoadingCurrentActivity: false,
        status: activity?.status,
        open: true
      }))
    }, 1)

    if (viewport.mobile || viewport.tablet) {
      navigate(`/activities/${idStage}`)
    }
  }

  return (
    <AButton
      tag='button'
      type='button'
      className={`stage stage--${state} ${idStage === Number(idActivity) && userState === 9 ? 'stage--selected' : ''}`}
      disabled={!stageActivated}
      handleClick={() => handleClick(idStage)}
    >

      {
        (idStage === Number(idActivity) && userState === 9) &&
        <>
          <img src={BorderStageSelected} alt="stage-border" className='stage--selected-span stage--selected-span--1' />
          <img src={BorderStageSelected} alt="stage-border" className='stage--selected-span stage--selected-span--2' />
          <img src={BorderStageSelected} alt="stage-border" className='stage--selected-span stage--selected-span--3' />
          <img src={BorderStageSelected} alt="stage-border" className='stage--selected-span stage--selected-span--4' />
        </>
      }

      <AImage className='stage__image' url={icon} alt='' />

      <p className="stage__title">
        {name}
      </p>
    </AButton>
  )
}

import * as z from "zod";
const REQUIRED_ERROR_MESSAGE = 'Este campo es requerido';
const RequiredString = z.string({
  required_error: REQUIRED_ERROR_MESSAGE,
}).min(1, { message: REQUIRED_ERROR_MESSAGE }).trim().default('')
const MAX_LENGTH_ERROR_MESSAGE = 'Este campo no puede tener más de 40 caracteres';
export const CompensationInformationSchema = z.object({
  "id": z.number(),
  "payrollComponent": RequiredString,
  "salaryBase": z.coerce. string({
    required_error: REQUIRED_ERROR_MESSAGE,
  }).min(1, { message: REQUIRED_ERROR_MESSAGE }).max(40).default(''),
  "currency": RequiredString,
  "position": z.number(),
});
export type CompensationInformation = z.infer<typeof CompensationInformationSchema>;

export const GrowInformationSchema = z.object({
  "id": z.coerce.number(),
  "chapter": z.string({ invalid_type_error: REQUIRED_ERROR_MESSAGE }).default('').nullable(),
  "specialty": z.string({ invalid_type_error: REQUIRED_ERROR_MESSAGE }).default("").nullable(),
  "idChapterSap": z.string().nullable(),
  "technology": z.string().nullable(),
  "idTechnologySap": z.string().nullable(),
  "technicaLevel": z.string().nullable(),
  "idTechnicalLevelSap": z.string().nullable(),
  "idSpecialtySap": z.string().nullable(),
  "position": z.coerce.number().optional()
});


export type GrowInformation = z.infer<typeof GrowInformationSchema>;

export const OrganizationalInformationSchema = z.object({
  "id": z.number(),
  "contractType": z.string().optional(),
  "position": z.string().optional(),
  "startDate": RequiredString,
  "division": RequiredString,
  "department": RequiredString,
  "referred": z.string(),
  "contractTypeEn": z.string(),
  "contractTypeCountry": z.string(),
});
export type OrganizationalInformation = z.infer<typeof OrganizationalInformationSchema>;

export const AdminPanelFormSchema = z.object({
  "names": RequiredString,
  "secondName": z.string().optional(),
  "lastname": RequiredString,
  "secondLastname": z.string(),
  "documentType": RequiredString,
  "documentNumber": RequiredString,
  "gender": RequiredString,
  "birthdate": RequiredString,
  "countryOfBirth": RequiredString,
  "departamentOfBirth": RequiredString,
  "cityOfBirth": RequiredString,
  "civilStatus": RequiredString,
  "bloodGroup": RequiredString,
  "phone": RequiredString,
  "email": RequiredString,
  "countryOfResidence": RequiredString,
  "departamentOfResidence": RequiredString,
  "cityOfResidence": RequiredString,
  "address": z.string({
    required_error: REQUIRED_ERROR_MESSAGE,
  })
    .min(1, { message: REQUIRED_ERROR_MESSAGE })
    .max(40, { message: MAX_LENGTH_ERROR_MESSAGE })
    .optional()
    .default(''),
  "complementaryAddresses": z.string().max(40, { message: MAX_LENGTH_ERROR_MESSAGE }).optional(),
  "addressType": RequiredString,
  "educationLevel": RequiredString,
  "professionalTitle": RequiredString,
  "professionalCard": z.string().optional(),
  "preferredName": RequiredString,
  "shirtSize": RequiredString,
  "greeting": RequiredString,
  "role": RequiredString,
  "cv": RequiredString,
  "organizationalInformation": OrganizationalInformationSchema,
  "growInformation": z.array(GrowInformationSchema),
  "compensationInformation": z.array(CompensationInformationSchema),
  "countryOfResidenceISO": RequiredString,
  "countryOfBirthEn": RequiredString,
  "state": RequiredString,
  "civilStatusEn": RequiredString,
  "position": RequiredString,
  "contractType": RequiredString,
}).refine((data) => {
  return typeof data.growInformation[0].chapter === "string" && data.growInformation[0].chapter.length > 0;
}, {
  message: REQUIRED_ERROR_MESSAGE,
  path: ['growInformation', 0, 'chapter'],
}).refine((data) => {
  return typeof data.growInformation[0].specialty === "string" && data.growInformation[0].specialty.length > 0;
}, {
  message: REQUIRED_ERROR_MESSAGE,
  path: ['growInformation', 0, 'specialty'],
});

export type IAdminPanelForm = z.infer<typeof AdminPanelFormSchema>;

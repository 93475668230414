import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SapApi } from 'utils/helpers/dictionaries';

export const sapApi = createApi({
  reducerPath: SapApi.sapApi,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SAP_URL,
  }),
  endpoints: (builder) => ({
    getGenders: builder.query<
      any,
      { catalogName: string; filter?: any; languages?: any; father?: any }
    >({
      query: ({ catalogName, filter, languages, father }) => {
        const queryParams = filter
          ? `?value=${catalogName}&filter=${filter}`
          : !father
            ? `?value=${catalogName}&languages=${languages}`
            : `?value=${catalogName}&languages=${languages}&father=${father}`;
        return queryParams;
      },
    }),

    getPersonalInfoByEmail: builder.query<any, { email: string }>({
      query: ({ email }) => {
        return {
          url: "",
          params: {
            select: `userNav/email eq '${email}'`,
            expand: "userNav",
            filter: "name",
            value: "EmpEmployment"
          }
        }
      }
    }),
    getPhotoByEmail: builder.query<any, { email: string }>({
      query: ({ email }) => {
        return {
          url: "",
          params: {
            select: `userNav/email eq '${email}'`,
            expand: "photoNav",
            filter: "name",
            value: "EmpEmployment"
          }
        }
      }
    })
  }),
});

export const { useGetGendersQuery, useGetPersonalInfoByEmailQuery, useGetPhotoByEmailQuery } = sapApi;

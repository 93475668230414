import { Link } from 'react-router-dom';
import { IStage } from 'utils/interfaces/store/user.interfaces';
import { AImage } from 'components/atoms';
import { StagesRender } from '../StagesRender/StagesRender.component';
import relojUpsIcon from 'utils/assets/images/relojUpsIcon.svg';
import './StagesCompleted.scss';
import { useWindowMobile } from 'hooks/useWindowMobile';
import { StagesHome } from '../StagesHome/StagesHome.components';
import { CurrentActivity } from '../CurrentActivity/CurrentActivity.component';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { currentActivity, selectCurrentActivity, selectStages, selectUserInfo, selectVacantInfo } from 'store';
import { useEffect, useState } from 'react';
import { userStates } from 'utils/helpers';

interface IProps {
  completedStages: IStage[];
  activeStages: IStage[];
  currentStage: number;
}

export const StagesCompleted = ({ completedStages, activeStages, currentStage }: IProps) => {
  const { stages } = useAppSelector(selectStages);
  const { idActivity } = useAppSelector(selectCurrentActivity)
  const { userState } = useAppSelector(selectUserInfo);
  const { vacant } = useAppSelector(selectVacantInfo);
  const { isMobile } = useWindowMobile(1024);
  const [icon, setIcon] = useState("");
  const dispatch = useAppDispatch();

  const changeState = () => {

    dispatch(currentActivity({
      idActivity: stages[0].id.toString(),
      iconActivity: icon || '',
      nameActivity: stages[0].name || '',
      isLoadingCurrentActivity: false,
      status: stages[0].status,
      open: false
    }))

    setTimeout(() => {
      dispatch(currentActivity({
        idActivity: stages[0].id.toString(),
        iconActivity: icon || '',
        nameActivity: stages[0].name || '',
        isLoadingCurrentActivity: false,
        status: stages[0].status,
        open: true
      }))
    }, 1)
  }

  if (!completedStages.length) {
    const { name, id } = activeStages.find(activeStage => activeStage.stage === currentStage)
      || { name: 'Sin etapas', id: -1 };

    return (
      <div className='not-stages' data-testid="StagesCompleted">
        <AImage url={relojUpsIcon} alt='' />
        <p>¡Ups!</p>
        <p>Aún no has completado ninguna etapa</p>
        {
          isMobile ?
            userState === userStates.inProgress && (vacant?.idStage !== userStates.makeProposal && vacant?.idStage !== userStates.hire) &&
            <p>
              Comienza ahora con:
              <Link
                className='not-stages__link'
                to={`/activities/${id}`}
                data-testid="link--activities">
                {name}
              </Link>
            </p>
      
          : 
            <p>
              Comienza ahora con:
              <a
                className='not-stages__link'
                data-testid="link--activities"
                onClick={() => changeState()}>
                &nbsp; {name}
              </a>
            </p>
        }
      </div>
    )
  }

  return (
    <StagesRender stages={completedStages} />
  )
}

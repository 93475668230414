export const UNDERSCORE = '_';
export const SPACE = ' ';
export const EMPTY = '';
export const BULL = ' • ';
export const DASH = '-';
export const SLASH = '/';
export const ASTERISK = '*';
export const AT = '@';
export const MONEY_SIGN = '$';
export const GOOGLE = 'google_';
export const VALIDATION_TEXT_DE = ' de ';


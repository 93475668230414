/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { Numbers } from 'utils/helpers/dictionaries';
import {
  IAgenda,
  INotificationPU,
} from 'utils/interfaces/store/talento.interfaces';
import {
  IActivity,
  IStage,
  IVacant,
} from 'utils/interfaces/store/user.interfaces';

interface IUserState {
  user: {
    email: string;
    isLogged: boolean;
    userState: number;
    notificationPU: INotificationPU;
    isActive: boolean;
  };
  vacant: {
    vacant: IVacant | undefined;
    isLoadingVacant: boolean;
  };
  stages: {
    stages: IStage[] | [];
    isLoadingStages: boolean;
  };
  activities: {
    isLoadingActivities: boolean;
    activities: IActivity[] | [];
  };
  currentActivity: {
    idActivity: string;
    iconActivity: string;
    nameActivity: string;
    isLoadingCurrentActivity: boolean;
    status: string;
    open: boolean;
  };
  agendas: IAgenda[] | [];
  currentAgenda: {
    day: string;
    month: string;
    year: number;
    start: string;
    end: string;
    startDateUnix: string;
    endDateUnix: string;
  };
  infoActivities: {
    isScheduleActive: boolean;
  };
  profile: {
    phoneNumber: any;
    city: any;
    country: any;
    states: any;
    phoneCode: any;
    flagUrl: any;
  };
  calendar: {
    isCalendar: boolean;
  };
}

export const initialStateUser: IUserState = {
  user: {
    isActive: false,
    email: '',
    isLogged: false,
    userState: 0,
    notificationPU: {
      activeModal: false,
      message: '',
      messageInfo: '',
      styleModal: '',
      help: false,
    },
  },
  vacant: {
    vacant: undefined,
    isLoadingVacant: false,
  },
  stages: {
    stages: [],
    isLoadingStages: false,
  },
  activities: {
    isLoadingActivities: false,
    activities: [],
  },
  agendas: [],
  currentActivity: {
    idActivity: '',
    iconActivity: '',
    nameActivity: '',
    isLoadingCurrentActivity: true,
    status: '',
    open: true,
  },
  currentAgenda: {
    day: '',
    month: '',
    year: new Date().getFullYear(),
    start: '',
    end: '',
    startDateUnix: '',
    endDateUnix: '',
  },
  infoActivities: {
    isScheduleActive: false,
  },
  profile: {
    phoneNumber: '',
    city: '',
    country: '',
    states: '',
    phoneCode: '',
    flagUrl: '',
  },

  calendar: {
    isCalendar: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {
    setProfile(
      state: {
        phoneNumber?: any;
        city?: any;
        country?: any;
        states?: any;
        phoneCode?: any;
        flagUrl?: any;
        profile?: any;
      },
      action: PayloadAction<{
        phoneNumber: any;
        city: any;
        country: any;
        states: any;
        phoneCode: any;
        flagUrl: any;
      }>
    ) {
      const { phoneNumber, city, country, states, phoneCode, flagUrl } =
        action.payload;
      state.profile = {
        phoneNumber,
        city,
        country,
        states,
        phoneCode,
        flagUrl,
      };
    },
    setUserInfo(
      state: {
        email?: any;
        isLogged?: boolean;
        user?: any;
        userState?: number;
        notificationPU?: INotificationPU;
        isActive?: boolean;
      },
      action: PayloadAction<{
        email: string;
        isLogged: boolean;
        userState?: number;
        notificationPU?: INotificationPU;
        isActive?: boolean;
      }>
    ) {
      const { email, isLogged, userState, notificationPU, isActive } = action.payload;
      state.user = { email, isLogged, userState, notificationPU, isActive };
    },
    setVacantInfo(
      state: { vacant: any; isLoadingVacant?: boolean },
      action: PayloadAction<{
        vacant: IVacant | undefined;
        isLoadingVacant: boolean;
      }>
    ) {
      const { vacant, isLoadingVacant } = action.payload;
      state.vacant = { vacant, isLoadingVacant };
    },
    setStages(
      state: { stagesList?: any; isLoadingStages?: boolean; stages?: any },
      action: PayloadAction<{
        stagesList: IStage[] | [];
        isLoadingStages: boolean;
      }>
    ) {
      const stages = action.payload.stagesList;
      state.stages = {
        stages,
        isLoadingStages: action.payload.isLoadingStages,
      };
    },
    setScheduleActive(state, action: PayloadAction<{ isActive: boolean }>) {
      state.infoActivities.isScheduleActive = action.payload.isActive;
    },
    setCalendar(state, action: PayloadAction<{ isCalendar: boolean }>) {
      state.calendar.isCalendar = action.payload.isCalendar;
    },
    currentActivity(
      state: {
        idActivity?: string;
        iconActivity?: string;
        nameActivity?: string;
        isLoadingCurrentActivity?: false;
        currentActivity?: any;
        status?: string;
        open?: true;
      },
      action: PayloadAction<{
        idActivity: string;
        iconActivity: string;
        nameActivity: string;
        isLoadingCurrentActivity: boolean;
        status?: string;
        open?: boolean;
      }>
    ) {
      const {
        idActivity,
        iconActivity,
        nameActivity,
        isLoadingCurrentActivity,
        status,
        open,
      } = action.payload;
      state.currentActivity = {
        idActivity,
        iconActivity,
        nameActivity,
        isLoadingCurrentActivity,
        status,
        open,
      };
    },
    setActivities(
      state: {
        activities: { isLoadingActivities: boolean; activities: IActivity[] };
      },
      action: PayloadAction<{
        newActivities: IActivity[] | [];
        isLoadingActivities: boolean;
      }>
    ) {
      const { newActivities, isLoadingActivities } = action.payload;
      state.activities = {
        isLoadingActivities,
        activities: [...newActivities],
      };
    },
    setActivityStatus(
      state: {
        idActivity?: string;
        iconActivity?: string;
        nameActivity?: string;
        isLoadingCurrentActivity?: false;
        currentActivity?: any;
        status?: string;
      },
      action
    ) {
      const { status } = action.payload;
      state.currentActivity = { ...state.currentActivity, status };
    },
    setAgendas(
      state: { agendas: IAgenda[] },
      action: PayloadAction<{
        newAgendas: IAgenda[];
      }>
    ) {
      state.agendas = action.payload.newAgendas;
    },
    currentAgenda(
      state: {
        day?: string;
        month?: string;
        year?: number;
        start?: string;
        end?: string;
        startDateUnix?: string;
        endDateUnix?: string;
        currentAgenda: any;
      },
      action: PayloadAction<{
        day: string;
        month: string;
        year: number;
        start: string;
        end: string;
        startDateUnix: string;
        endDateUnix: string;
      }>
    ) {
      const { day, month, year, start, end, startDateUnix, endDateUnix } =
        action.payload;
      state.currentAgenda = {
        day,
        month,
        year,
        start,
        end,
        startDateUnix,
        endDateUnix,
      };
    },
    setStageCompleted(state: any, action) {
      state.stages.stages = [
        ...(state.stages.stages as Array<IStage>).map((stage: IStage) =>
          stage.id == action.payload.id
            ? { ...stage, status: Numbers.four }
            : stage
        ),
      ];
    },
    resetUserInfo(state: { user: { email: string; isLogged: boolean } }) {
      state.user = initialStateUser.user;
    },
    logout() {},
  },
});

export const {
  setUserInfo,
  resetUserInfo,
  setVacantInfo,
  setStages,
  setStageCompleted,
  setActivityStatus,
  setActivities,
  setScheduleActive,
  setCalendar,
  currentActivity,
  setAgendas,
  currentAgenda,
  logout,
  setProfile,
} = userSlice.actions;
export const selectUserInfo = (state: RootState) => state.user?.user;
export const selectVacantInfo = (state: RootState) => state.user?.vacant;
export const selectStages = (state: RootState) => state.user?.stages;
export const selectActivities = (state: RootState) => state.user?.activities;
export const selectCurrentActivity = (state: RootState) =>
  state.user?.currentActivity;
export const selectAgendas = (state: RootState) => state.user?.agendas;
export const selectCurrentAgenda = (state: RootState) =>
  state.user?.currentAgenda;
export const selectProfile = (state: RootState) => state.user?.profile;
export const selectCalendar = (state: RootState) => state.user?.calendar;

export const selectinfoActivities = (state: RootState) =>
  state.user?.infoActivities;

export default userSlice.reducer;

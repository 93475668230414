import { AButton, AImage, Back, Loader } from 'components/atoms';
import DatePickers from 'components/molecules/DatePickers/DatePickers';
import { useEffect, useState } from 'react';
import { useScheduleMeetingMutation } from 'services/talento';
import { selectVacantInfo, setCalendar } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { sunriseIcon, sunsetIcon } from 'utils/assets';
import './ScheduleInterview.scss';
import {
  arrayFirstElement,
  dayAndNight,
  dayParts,
  scheduleVariables,
  hourText,
} from 'utils/constants';
import { Numbers } from 'utils/helpers/dictionaries';
import { scheduleTexts } from 'utils/mockTexts/scheduleTexts';

export const ScheduleInterview = ({ props }: any) => {
  const { date, idCalendar, email, idActivity, scheduleSuccess } = props;
  const [activeDatePickersIndex, setActiveDatePickersIndex] = useState<
    number | null
  >(0);
  const [selectedHour, setSelectedHour] = useState<any>();
  const [activateHourPicker, setActiveHourPicker] = useState<string | null>();
  const [eventId, setEventId] = useState<string | null>();
  const [scheduleMeeting, { isLoading: isLoadingMeeting, isSuccess }] =
    useScheduleMeetingMutation();
  const dispatch = useAppDispatch();

  function formatoMesYAnio(fecha: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
    };
    const fechaDate = new Date(fecha);

    const resultado = fechaDate.toLocaleDateString(
      scheduleVariables.locale,
      options
    );

    const fechaString = resultado.split(" ");
    fechaString.splice(1, 1);

    const resultadoSinDe = fechaString.join(" ");

    return resultadoSinDe;
  }

  useEffect(() => {
    if (date.events.length > Numbers.zero) {
      searchHoursByDay(date.events[arrayFirstElement].date);
    }
  }, [date]);

  useEffect(() => {
    if (isSuccess) {
      scheduleSuccess();
    }
  }, [isSuccess]);

  const handleHourClick = (eventId: string) => {
    if (activateHourPicker == eventId) {
      setActiveHourPicker(eventId);
      setEventId(null);
    } else {
      if (activateHourPicker !== null) {
        setActiveHourPicker(null);
        setEventId(null);
      }
      setActiveHourPicker(eventId);
    }
    setEventId(eventId);
  };

  const validateActivePicker = (index: number) => {
    if (activeDatePickersIndex == index) {
      // Aquí puedes agregar lógica adicional si es necesario
      setActiveDatePickersIndex(null);
    } else {
      if (activeDatePickersIndex !== null) {
        setActiveDatePickersIndex(null);
      }
      setActiveDatePickersIndex(index);
    }
  };

  const handleDayClick = (selectedDate: string, index: number) => {
    setActiveHourPicker(null);
    setEventId(null);
    validateActivePicker(index);
    // Busca las horas disponibles para el día seleccionado
    searchHoursByDay(selectedDate);
  };

  const isMorning = (hourStr: string) => {
    return hourStr.toLowerCase().includes(dayParts.am);
  };
  const vacant = useAppSelector(selectVacantInfo)

  const searchHoursByDay = (selectedDate: string) => {
    const selectedDateInfo = date.events.find(
      (event: any) => event.date === selectedDate
    );
    if (selectedDateInfo) {
      const hours = selectedDateInfo.hours;
      setSelectedHour(hours);
    }
  };

  const morningHours = selectedHour?.filter((hour: any) =>
    isMorning(hour.startHour)
  );
  const afternoonHours = selectedHour?.filter(
    (hour: any) => !isMorning(hour.startHour)
  );

  return (
    <>
      {isLoadingMeeting ? (
        <Loader className="loader__purple" full={true} />
      ) : (
        <div>
          <div className="shiftDay__schedule">
            <Back
              className="backSchedule"
              icon
              handleBack={() => {
                dispatch(
                  setCalendar({
                    isCalendar: false,
                  })
                );
              }}
            />
            <h2 className="currentActivity__title">{scheduleTexts.diary}</h2>
          </div>
          <div
            className={
              date.events.length == Numbers.zero
                ? 'currentActivity__content'
                : ''
            }
          >
            {date.events.length == Numbers.zero ? (
              <>
                <h2 className="currentActivity__noSchedule__title">
                  {scheduleTexts.sorry}
                </h2>
                <h2 className="currentActivity__noSchedule__text">
                  {scheduleTexts.noSchedule}
                </h2>
              </>
            ) : (
              <div>
                <h3 className="currentActivity__recommendations">
                  {scheduleTexts.date}
                </h3>
                {
                  <h1 className="currentActivity__recommendations">
                    {formatoMesYAnio(date.events[arrayFirstElement].date)}
                  </h1>
                }
                <div className="containerPicker">
                  {date.events.map((date: any, index: number) => (
                    <DatePickers
                      key={index}
                      type={dayAndNight.day}
                      date={date.date}
                      isActive={activeDatePickersIndex == index}
                      nameDay={date.dayName}
                      handleClick={() => {
                        handleDayClick(date.date, index);
                      }}
                    ></DatePickers>
                  ))}
                </div>
                <h3 className="currentActivity__recommendations mt-3">
                  {scheduleTexts.hour}
                </h3>
                {morningHours && morningHours.length > Numbers.zero && (
                  <div className="shiftDay mt-3">
                    <AImage
                      alt="calendarIcon"
                      url={sunriseIcon}
                      className="purple"
                    ></AImage>
                    <p className="normalText">{scheduleTexts.morning}</p>
                  </div>
                )}
                {morningHours && morningHours.length > Numbers.zero && (
                  <div className="containerPicker mt-3">
                    {morningHours.map((hour: any, index: number) => (
                      <DatePickers
                        key={index}
                        type={hourText}
                        isActive={activateHourPicker == hour.eventId}
                        hour={[
                          {
                            startHour: hour.startHour,
                            endHour: hour.endHour,
                          },
                        ]}
                        handleClick={() => {
                          handleHourClick(hour.eventId);
                        }}
                      />
                    ))}
                  </div>
                )}
                {afternoonHours && afternoonHours.length > Numbers.zero && (
                  <div className="shiftDay mt-3">
                    <AImage
                      alt="calendarIcon"
                      url={sunsetIcon}
                      className="purple"
                    ></AImage>
                    <p className="normalText">{scheduleTexts.afternoon}</p>
                  </div>
                )}
                {afternoonHours && afternoonHours.length > Numbers.zero && (
                  <div className="containerPicker mt-3">
                    {afternoonHours.map((hour: any, index: number) => (
                      <DatePickers
                        key={index}
                        type={hourText}
                        isActive={activateHourPicker == hour.eventId}
                        hour={[
                          {
                            startHour: hour.startHour,
                            endHour: hour.endHour,
                          },
                        ]}
                        handleClick={() => {
                          handleHourClick(hour.eventId); // Establecer la hora seleccionada en el estado
                        }}
                      />
                    ))}
                  </div>
                )}

                <div className="currentActivity__buttons">
                  <AButton
                    tag="a"
                    disabled={eventId == null}
                    className="button-primary--dark mt-3"
                    target={'_blank'}
                    handleClick={() => {
                      const args = {
                        idEvent: eventId,
                        idCalendar: idCalendar,
                        email: email,
                        idActivity: idActivity,
                        candidateFullName: vacant.vacant?.fullName
                      };
                      scheduleMeeting(args);
                    }}
                  >
                    {scheduleTexts.schedule}
                  </AButton>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

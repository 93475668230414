import { useEffect } from 'react';
import { Input } from 'components/atoms';
import { FieldSet } from '../FieldSet/FieldSet';
import style from './AcademicDataSection.module.scss';
import { useFormContext, Controller } from 'react-hook-form';
import { IAdminPanelForm } from '../AdminPanelForm.schema';
import { useUpdateAcademicInfoHiringMutation } from 'services/talento';
import { useParams } from 'react-router-dom';

export function AcademicDataSection() {
  const { control, getValues } = useFormContext<IAdminPanelForm>();
  const [updateAcademicInfoHiring] = useUpdateAcademicInfoHiringMutation();
  const { email } = useParams();

  useEffect(() => {
    submitData();
  }, []); 
  async function submitData() {
    await updateAcademicInfoHiring({ 
      ...getValues(),
      email: email ?? '',
      professionalCard: getValues('professionalCard') ?? '',
    }).unwrap();
  }

  return (
    <main className={style.AcademicDataSection}>
      <FieldSet title="informacion académica">
        <div className={style.AcademicDataSection__fields}>
        <Controller
          control={control}
          name="professionalTitle"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              id="professionalTitle"
              label="Título profesional"
              {...field}
              error={error !== undefined}
              success={isTouched && error === undefined}
              subtext={error?.message}
            />
          )}
        />
          <Controller
            control={control}
            name="professionalCard"
            render={({
              field: { onBlur, ...field },
              fieldState: { error, isTouched },
            }) => (
              <Input
                onBlur={(e) => {
                  onBlur();
                  submitData();
                }}
                placeholder="Ej: 05202349067ANT"
                id="professionalCard"
                label="Número de tarjeta profesional (opcional)"
                {...field}
                error={error !== undefined}
                success={isTouched && error === undefined}
                subtext={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="cv"
            render={({
              field: { onBlur, ...field },
              fieldState: { error, isTouched },
            }) => (
              <Input
                onBlur={(e) => {
                  onBlur();
                  submitData();
                }}
                id="cv"
                label="Hoja de vida"
                {...field}
                error={error !== undefined}
                success={isTouched && error === undefined}
                subtext={error?.message}
              />
            )}
          />
        </div>
      </FieldSet>
    </main>
  );
}

import { useState, useEffect } from 'react';

interface IViewport {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
}

export default function useViewport() {
    const [viewport, setViewport] = useState<IViewport>({
        mobile: false,
        tablet: false,
        desktop: false
    });

    const updateDimensions = () => {
        if (window.innerWidth > 0 && window.innerWidth < 767) {
            setViewport({ mobile: true, tablet: false, desktop: false });
        }
        if (window.innerWidth >= 767 && window.innerWidth < 1280) {
            setViewport({ mobile: false, tablet: true, desktop: false });
        }
        if (window.innerWidth >= 1280) {
            setViewport({ mobile: false, tablet: false, desktop: true });
        }
    };

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return viewport;
}

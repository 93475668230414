import { Input } from 'components/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import { IUserConfirmData } from '../ConfirmDataTabForm';
import { Group } from 'components/molecules/TabPanel/TabPanel';
import EmergencyContactsSection from '../EmergencyContactSection/EmergencyContactSection';
import CountrySelect from 'components/molecules/CountrySelect/CountrySelect';
import DepartmentSelect from 'components/molecules/DepartmentSelect/DepartmentSelect';
import CitySelect from 'components/molecules/CitySelect/CitySelect';
import DistrictSelect from 'components/molecules/DistrictSelect/DistrictSelect';
import { useExternalCodeContext } from '../ExternalCodesContext';

interface ContactDataSectionProps {
  saveConfirmData: () => void;
}

export default function ContactDataSection({
  saveConfirmData,
}: Readonly<ContactDataSectionProps>) {
  const { control, setValue, getValues } = useFormContext<IUserConfirmData>();
  const {
    countryOfResidence,
    cityOfResidence,
    departmentOfResidence,
    setCityOfResidence,
    setCountryOfResidence,
    setDepartmentOfResidence,
  } = useExternalCodeContext();

  return (
    <>
      <Group>
        <Controller
          control={control}
          name="phone"
          render={({
            field: { value, onChange, onBlur, ...rest },
            fieldState,
          }) => (
            <Input
              id="phone"
              label="Móvil"
              defaultValue={value}
              error={fieldState.error !== undefined}
              subtext={fieldState.error?.message}
              onChange={onChange}
              onBlur={() => {
                saveConfirmData();
                onBlur();
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({
            field: { value, onChange, onBlur, ...rest },
            fieldState,
          }) => (
            <Input
              id="email"
              label="E-mail"
              defaultValue={value}
              subtext={fieldState.error?.message}
              error={fieldState.error !== undefined}
              onChange={onChange}
              onBlur={() => {
                saveConfirmData();
                onBlur();
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="countryOfResidence"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <CountrySelect
              textLabel="País de residencia"
              htmlForLabel="countryOfResidence"
              subtext={fieldState.error?.message}
              placeholder={'Seleccione uno'}
              error={fieldState.error !== undefined}
              disabled
              handleChange={(value) => {
                onChange(value);
              }}
              value={{
                label: 'Perú',
                value: 'Perú',
              }}
              onExternalCodeChange={(externalCode) => {
                setCountryOfResidence(externalCode);
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="departamentOfResidence"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <DepartmentSelect
              textLabel="Departamento de residencia"
              htmlForLabel="departamentOfResidence"
              subtext={fieldState.error?.message}
              error={fieldState.error !== undefined}
              placeholder={'Seleccione uno'}
              handleChange={(value) => {
                if (value !== getValues('departamentOfResidence')) {
                  setValue('cityOfResidence', '');
                }
                onChange(value);
                saveConfirmData();
              }}
              value={{
                label: value as any,
                value: value as any,
              }}
              disabled={!countryOfResidence}
              country={countryOfResidence}
              onExternalCodeChange={(externalCode) => {
                setDepartmentOfResidence(externalCode);
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="cityOfResidence"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <CitySelect
              textLabel="Provincia de residencia"
              htmlForLabel="cityOfResidence"
              subtext={fieldState.error?.message}
              error={fieldState.error !== undefined}
              placeholder={'Seleccione uno'}
              handleChange={(value) => {
                onChange(value);
                saveConfirmData();
              }}
              value={{
                label: value as any,
                value: value as any,
              }}
              disabled={!departmentOfResidence}
              department={departmentOfResidence}
              onExternalCodeChange={(externalCode) => {
                setCityOfResidence(externalCode);
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="residenceDistrict"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <DistrictSelect
              textLabel="Distrito de residencia"
              htmlForLabel="residenceDistrict"
              error={fieldState.error !== undefined}
              subtext={fieldState.error?.message}
              placeholder={'Seleccione uno'}
              handleChange={(value) => {
                onChange(value);
                saveConfirmData();
              }}
              value={{
                label: value as any,
                value: value as any,
              }}
              disabled={!cityOfResidence}
              country={countryOfResidence}
              city={cityOfResidence}
              {...rest}
            />
          )}
        />
      </Group>
      <Group fullWidth>
        <Controller
          control={control}
          name="address"
          render={({
            field: { value, onChange, onBlur, ...rest },
            fieldState,
          }) => (
            <Input
              id="address"
              label="Dirección de residencia"
              defaultValue={value}
              error={fieldState.error !== undefined}
              onChange={onChange}
              onBlur={() => {
                saveConfirmData();
                onBlur();
              }}
              subtext={
                fieldState.error?.message ??
                'Especifica Av./ Jr./ Calle/ Block/ Carretera/ Malecon/Plaza/Parque        N°/ Km/ Mz/ Int/ Dpto/ Lote/ Sector/ Etapa'
              }
            />
          )}
        />
      </Group>
      <Group
        fullWidth
        title="Contacto de emergencia"
        description="Agrega la información de personas que podamos contactar en caso de que necesitemos ayuda para comunicarnos contigo. Puedes agregar hasta dos contactos de emergencia."
      >
        <EmergencyContactsSection />
      </Group>
    </>
  );
}

import { AButton, AImage } from 'components/atoms';
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useGetVacantDescriptionQuery } from 'services/talento';
import { selectUserInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { xMarkIcon } from 'utils/assets';
import './InfoVacant.scss'
import ErrorCustom from 'components/organisms/ErrorCustom/ErrorCustom.component';
import DOMPurify from 'dompurify';

type _InfoVacantProps = {
    isOpen: boolean;
    vacantName: string;
    close: React.Dispatch<React.SetStateAction<boolean>>;
}
export type InfoVacantProps = PropsWithChildren<_InfoVacantProps>

export const InfoVacant = ({ isOpen, vacantName, close }: InfoVacantProps) => {

    const { email } = useAppSelector(selectUserInfo);
    const { data: dataVacantDescription } = useGetVacantDescriptionQuery({email})
    const [error, setError] = useState(false);


    const sanitizedData = () => {
        if(dataVacantDescription && dataVacantDescription.data){
          return  {__html: DOMPurify.sanitize(dataVacantDescription.data.description)};
        }else{
            setError(true)
            return undefined
        }
    }

    return (
        <div className={`infoVacant__back ${isOpen ? 'infoVacant__back--show' : ''}`} data-testid='infoVacant__back'>
            {
             error ? <ErrorCustom code={dataVacantDescription!.errorCode} /> :
            
            <div className='infoVacant__container'>
                <div className='infoVacant__container-title'>
                    <h1 className='infoVacant__title'>{vacantName}</h1>
                    <AButton
                        tag='button'
                        dataTestId="close-button"
                        className="infoVacant__close-button"
                        handleClick={() => close(false)}
                    >
                        <AImage url={xMarkIcon} width={17} height={17} alt="Close icon" />
                    </AButton>
                </div>
                <div className='infoVacant__wrap'>
                    {dataVacantDescription && 
                        <div 
                            className='infoVacant__container-info' 
                            data-testid='infoVacant__container-info' 
                            dangerouslySetInnerHTML={sanitizedData()} 
                        />
                    }
                </div>
            </div>
            }
        </div>
    )
}

export default InfoVacant
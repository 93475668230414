import React from 'react';
import { selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { IStage } from 'utils/interfaces/store/user.interfaces'
import { stageState } from 'utils/helpers';
import { Stage } from 'components/molecules';
import './StagesRender.scss';


interface IProps {
  stages: IStage[];
};

export const StagesRender = ({ stages }: IProps) => {
  const { userState } = useAppSelector(selectUserInfo);
  const { vacant } = useAppSelector(selectVacantInfo);

  return (
    <div className={`stagesRender  ${stages.length === 0 ? 'stagesRender--hidden' : undefined}`}>
      {
        stages.length && stages.map(({ id, name, icons, status, stage }) => (
          <div className='btn_container' key={id}>
            <Stage
              id={id}
              icons={icons}
              name={name}
              state={stageState({ status, stage, currentStage: vacant!.idStage, statusUser: userState })}
              idStage={id}
            />
          </div>
        ))
      }
    </div>
  )
}
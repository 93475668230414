import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'hooks/useTranslation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  selectUi,
  setShowPassword,
  selectConfirmNewPasswordForm,
  selectRestorePasswordForm,
  setConfirmNewPasswordForm,
  resetConfirmNewPasswordForm,
  resetLoginForm,
  resetConfirmationCodeForm,
  resetRetorePasswordForm,
  setFormType,
  selectUserInfo,
} from 'store';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import { useNewPasswordMutation } from 'services/talento';
import { IConfirmNewPasswordForm, IModal } from 'utils/interfaces';
import { confirmNewPasswordFormSchema } from 'utils/schemas';

import { AButton, AImage, LogoPragma } from 'components/atoms';
import { Back } from 'components/atoms/Back/Back.components';
import { errorIconParagraph, lockSuccess, passwordHideIcon, passwordShowIcon, resetpassImage } from 'utils/assets';
import { ModalAlert } from 'components/molecules';
import { selectTranslations } from 'store/translation/translation.slice';
import './ConfirmNewPasswordForm.scss';


interface ICurrentUser {
  cognitoUser?: [];
  requiredAttributes?: [];
}
interface IProps {
  tittle: string;
  user?: ICurrentUser;
}

export const ConfirmNewPasswordForm = ({ tittle, user }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const [generateNewPassword, { isSuccess, error }] = useNewPasswordMutation();
  const { showNewPassword, showConfirmNewPassword } = useAppSelector(selectUi);
  const { email } = useAppSelector(selectUserInfo);
  const { email: emailRestorePass } = useAppSelector(selectRestorePasswordForm);
  const { newPassword, confirmNewPassword } = useAppSelector(
    selectConfirmNewPasswordForm
  );
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [modal, setModal] = useState<IModal>({ message: '', type: 'success', isOpen:false , handleClick: () => { } })

  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    setError,
    formState: { errors, isValid, dirtyFields },
  } = useForm<IConfirmNewPasswordForm>({
    mode: 'onChange',
    defaultValues: { newPassword, confirmNewPassword },
    resolver: yupResolver(confirmNewPasswordFormSchema),
  });
  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) =>
    dispatch(setConfirmNewPasswordForm({ name, value }));

  const onSubmit: SubmitHandler<IConfirmNewPasswordForm> = async (values) => {
    await generateNewPassword({
      email: emailRestorePass,
      newPassword: values.newPassword,
      confirmedPassword: values.confirmNewPassword,
    }).then(cb => {
      setModal({ message: '¡Contraseña cambiada!', type: 'success', isOpen: true, handleClick: () => navigate('/login') })
    }).catch(e => {
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoginForm());
      dispatch(resetRetorePasswordForm());
      dispatch(resetConfirmationCodeForm());
      dispatch(resetConfirmNewPasswordForm());
    }
  }, [navigate, isSuccess]);

  useEffect(() => {
    const values = getValues();
    if (isValid) { clearErrors() } else {
      if (values.newPassword !== values.confirmNewPassword) {
        setError('confirmNewPassword', { type: 'custom', message: 'Las contraseñas no coinciden' })
      }
    }
  }, [isValid, error]);



  useEffect(() => {
    if (screen.width >= 1280) {
      setIsDesktop(true)
    } else {
      setIsDesktop(false)
    }
  }, []);

  window.addEventListener("resize", () => {
    if (screen.width >= 1280) {
      setIsDesktop(true)
    } else {
      setIsDesktop(false)
    }
  });

  return (
    <main className='reset-pass__wrapper' data-testid='main'>
      <ModalAlert
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        confirmButton={true}
        toggleModal={(isOpen: boolean) => setModal(state => ({ ...state, isOpen }))}
        handleClick={modal.handleClick}
      />

      <Back className='reset-pass__button' handleBack={() => { dispatch(setFormType('confirmationCode')) }} />
      <div className='reset-pass__wrapper-container'>

        <div className="reset-pass__logo">
          <LogoPragma />
        </div>

        <div className="reset-pass__icon">
          <AImage
            role='presentation'
            className='login-page__icon'
            url={lockSuccess}
            alt='resetpassicon'
          />
        </div>

        <form className="reset-pass__form" onSubmit={handleSubmit(onSubmit)} data-testid='form'>
          <h2 className='reset-pass__form-title'>{tittle}</h2>
          <p className="reset-pass__form-description">{t('paramsChangePassword')}</p>
          <div className="reset-pass__form-box">
            <label className="reset-pass__form-label" htmlFor="newPassword">

              <div className={
                `reset-pass__form-label-wrapper 
                ${errors.newPassword && 'reset-pass__form-label-wrapper--error'}
                ${errors.newPassword == undefined && dirtyFields.newPassword && 'reset-pass__form-label-wrapper--isValid'}`
              }>

                <input
                  className="reset-pass__form-input"
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  value={newPassword}
                  placeholder={isDesktop ? 'Ingresa tu nueva contraseña' : 'Contraseña nueva'}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('newPassword', { onChange: handleChange })}
                  tabIndex={1}
                />

                <AButton
                  tag='button'
                  handleClick={() => dispatch(setShowPassword('newPassword'))}
                  type="button"
                  className='butn'
                >
                  <AImage
                    role='presentation'
                    className='input-text__icon'
                    url={showNewPassword ? passwordShowIcon : passwordHideIcon}
                    alt=''
                  />
                </AButton>
              </div>
            </label>

            <p className='error-password'>
              {errors?.newPassword &&
                <AImage
                  role='error'
                  className='error-icon'
                  url={errorIconParagraph}
                  alt='close'
                />
              }
              {errors?.newPassword && errors.newPassword.message} &nbsp;
            </p>
          </div>

          <div className="reset-pass__form-box">
            <label className="reset-pass__form-label" htmlFor="confirmNewPassword">
              <div className={
                `reset-pass__form-label-wrapper  
                ${errors.confirmNewPassword && 'reset-pass__form-label-wrapper--error'}
                ${error && 'input-text__element--error'} 
                ${errors.confirmNewPassword == undefined && dirtyFields.confirmNewPassword && 'reset-pass__form-label-wrapper--isValid'}`
              }>
                <input
                  className="reset-pass__form-input"
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  placeholder={isDesktop ? 'Confirma tu nueva contraseña' : 'Repite contraseña nueva' }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('confirmNewPassword', { onChange: handleChange })}
                  tabIndex={2}
                />
                <AButton
                  tag='button'
                  handleClick={() => dispatch(setShowPassword('confirmNewPassword'))}
                  type="button"
                  className='butn'
                >
                  <AImage
                    role='presentation'
                    className='input-text__icon'
                    url={showConfirmNewPassword ? passwordShowIcon : passwordHideIcon}
                    alt=''
                  />
                </AButton>

              </div>
            </label>

            <p className='error-password'>
              {errors?.confirmNewPassword &&
                <AImage
                  role='error'
                  className='error-icon'
                  url={errorIconParagraph}
                  alt='close'
                />
              }
              {errors?.confirmNewPassword && errors.confirmNewPassword.message} &nbsp;
            </p>

            {error && 'data' in error && (
              <p className='error'>{error?.data.message}</p>
            )}

            <AButton tag='button' type='submit' disabled={!isValid} className='button-primary--white btn' data-testid='button'>
              Restablecer
            </AButton>
          </div>
        </form>
      </div>
    </main>
  );
};

export default ConfirmNewPasswordForm
import {
  Select,
  IProps as SelectProps,
} from 'components/atoms/Select/Select.components';
import { useEffect } from 'react';
import { useGetGendersQuery } from 'services/sap';
import { catalogoPersonal, catalogoLenguage } from 'utils/helpers/dictionaries';

interface ContractTypeSelectProps extends Omit<SelectProps, 'handleChange'> {
  handleChange: (value: {
    value: string;
    externalCode: string;
    valueEn: string;
  }) => void;
}

interface CatalogueItem {
  externalCode: string;
  label_es_ES: string;
  label_en_US: string;
}

interface ListItem {
  value: string;
  label: string;
  english?: string;
}

export default function ContractTypeSelect({
  handleChange,
  ...rest
}: Readonly<ContractTypeSelectProps>) {
  const { data: contract, isLoading } = useGetGendersQuery<{
    data: CatalogueItem[];
    isLoading: boolean;
  }>({
    catalogName: catalogoPersonal.contractType,
    languages: catalogoLenguage.ESyUSyISO,
  });
 
  useEffect(()=>{
    if(isLoading === true){
      return; 
    }
    const contractFormat = rest?.value?.value.split("-")
    const selectedContract = contract?.find(
      ({ label_es_ES, externalCode }) => label_es_ES === contractFormat[0] && externalCode.includes(contractFormat[1])
    );
    handleChange({
        value: rest.value?.value?.toString() ?? '',
        externalCode: selectedContract?.externalCode ?? '',
        valueEn: selectedContract?.label_en_US ?? '',
    });
  },[isLoading])  
  function mapCatalogueItems(catalogue?: CatalogueItem[]) {
    const mappedList = catalogue?.map((Results: CatalogueItem) => {
      const ISO = Results.externalCode.split(catalogoLenguage.line);
      const spanishFormat = `${Results.label_es_ES}${catalogoLenguage.line}${ISO[0]}`;

      return {
        value: spanishFormat,
        label: spanishFormat,
      };
    });

    return mappedList;
  }

  const options = mapCatalogueItems(contract) ?? [];

  return (
    <Select
      handleChange={(value) => {
        if (typeof value !== 'string') return
        const contractFormat = value.split("-")
    const selectedContract = contract?.find(
      ({ label_es_ES, externalCode }) => label_es_ES === contractFormat[0] && externalCode.includes(contractFormat[1])
    );
    handleChange({
        value: value?.toString() ?? '',
        externalCode: selectedContract?.externalCode ?? '',
        valueEn: selectedContract?.label_en_US ?? '',
    });
      }}
      disabled={isLoading}
      options={options}
      {...rest}
    />
  );
}

import { useEffect, useState } from 'react'
import { IHourSelected, IScheduleHour } from 'utils/interfaces/store/schedule.interface';
import { Tab, SubtitleWithIcon } from 'components/atoms';
import { ScheduleHours } from 'components/molecules';
import { sunsetIcon, sunriseIcon } from 'utils/assets';
import './SchedulePartsOfDay.mobile.scss';


interface IProps {
  currentDayIndex: number;
  hoursList: {
    dateMorning: IScheduleHour[];
    dateAfternoon: IScheduleHour[];
  };
  hourSelected: IHourSelected | null;
  setHourSelected: (date: IHourSelected) => void;
}

const tabsData = [
  {
    partOfDay: 'morning',
    icon: sunriseIcon,
    title: 'Mañana'
  },
  {
    partOfDay: 'afternoon',
    icon: sunsetIcon,
    title: 'Tarde'
  },
]

export const SchedulePartsOfDayMobile = ({ currentDayIndex, hoursList, hourSelected, setHourSelected }: IProps) => {
  const [partOfDaySelected, setPartOfDaySelected] = useState<'morning' | 'afternoon'>('morning')
  const [currentHoursList, setCurrentHoursList] = useState<IScheduleHour[]>(hoursList?.dateMorning)

  useEffect(() => {
    setCurrentHoursList(
      (partOfDaySelected === 'morning')
        ? hoursList?.dateMorning
        : hoursList?.dateAfternoon
    )
  }, [partOfDaySelected])

  return (
    <div className='schedule-part-of-day-mobile'>

      {/* Tabs */}
      <div className='schedule-part-of-day-mobile__tabs'>
        {
          tabsData.map(({ icon, partOfDay, title }, index) => (
            <Tab
              key={partOfDay + index}
              setCurrentTab={setPartOfDaySelected}
              to={partOfDay}
              isActive={partOfDaySelected === partOfDay}
            >
              <div className='schedule-part-of-day-mobile__tabs__text-container'>
                <SubtitleWithIcon title={title} icon={icon} />
              </div>
            </Tab>
          ))
        }
      </div>

      {/* Hours */}
      <div className={`${currentHoursList?.length ? 'schedule-part-of-day-mobile__hoursList' : 'schedule-part-of-day-mobile__noHoursList'}`}>
        <ScheduleHours
          dayIndex={currentDayIndex}
          hours={currentHoursList}
          hourSelected={hourSelected}
          partOfDay={partOfDaySelected}
          setHourSelected={setHourSelected}
        />
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react';
import { selectVacantInfo, selectStages } from 'store';
import { useAppSelector } from 'store/hooks';
import { useMessageProgressBar } from 'hooks/useMessageProgressBar';
import { ProgressBar } from 'components/molecules';
import './RememberHome.scss';
import { Numbers, userStates } from 'utils/helpers/dictionaries';

export const RememberHome = () => {
  const { stages, isLoadingStages } = useAppSelector(selectStages);
  const { isLoadingVacant } = useAppSelector(selectVacantInfo);

  const [stageCount, setStageCount] = useState({
    totalStages: Numbers.zero,
    completedStages: Numbers.zero,
  });

  const { totalStages, completedStages } = stageCount;
  const { name, description } = useMessageProgressBar(
    totalStages,
    completedStages,
    isLoadingStages
  );

  useEffect(() => {
    if (isLoadingStages || isLoadingVacant) return;

    setStageCount({
      totalStages: stages.length,
      completedStages: stages.filter(
        (s) =>
          s.status === userStates.makeProposal.toString() ||
          s.status === userStates.parked.toString()
      ).length,
    });
  }, [isLoadingStages, stages]);

  return (
    <div className="home-remember">
      <h2 className="home-remember__info__title">{name}</h2>
      <p className="home-remember__info__text">{description}</p>
      <div className="progess-bar__wrapper">
        {totalStages !== 0 &&
          <ProgressBar
            totalStages={totalStages}
            completedStages={completedStages}
          />
        }
      </div>
    </div>
  );
};

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface TimerState {
  confirmationCodeTimer: {
    value: number;
  };
}

const initialState: TimerState = {
  confirmationCodeTimer: {
    value: 72000,
  },
};

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setConfirmationCodeTimer(state, action: PayloadAction<number>) {
      state.confirmationCodeTimer.value = action.payload;
    },
    reduceConfirmationCodeTimer(state) {
      state.confirmationCodeTimer.value -= 1000;
    },
  },
});

export const { setConfirmationCodeTimer, reduceConfirmationCodeTimer } =
  timerSlice.actions;

export const selectConfirmationCodeTimer = (state: RootState) =>
  state.timer.confirmationCodeTimer;

export default timerSlice.reducer;

import { Loader } from 'components/atoms';
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import './LoadingPage.scss';

export const LoadingPage = () => {
    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);

    return (
        <div className='loading__page' data-testid="loading">
            <Loader />
        </div>
    )
}
import {
  Section,
  TabPrevious,
  TabNext,
  TabList,
  TabPanel,
  Tab,
  Group,
} from 'components/molecules/TabPanel/TabPanel';
import { checkIcon, iconError } from 'utils/assets';
import { useEffect, useMemo, useState } from 'react';
import { SectionContainer } from '../SectionContainer/SectionContainer.components';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import PersonalDataSection from './PersonalDataSection/PersonalDataSection';
import ContactDataSection from './ContactDataSection/ContactDataSection';
import EducationSection from './EducationSection/EducationSection';
import SummarySection from './SummarySection/SummarySection';
import { ExternalCodesProvider } from './ExternalCodesContext';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './ConfirmDataTabForm.schema';
import './ConfirmDataTabForm.scss';
import { AButton, AImage } from 'components/atoms';
import {
  useSubmitConfirmDataMutation,
  useUpdateAllUserDataMutation,
  useUpdateUserDataMutation,
} from 'services/talento';
import { useNavigate } from 'react-router';
import { LoaderCataloge } from 'components/atoms/LoaderCataloge/LoaderCataloge.components';
import { useAppSelector } from 'store/hooks';
import { selectUserInfo, selectVacantInfo } from 'store';
import { ModalAlert } from 'components/molecules';
import { useTabFormValidation } from 'hooks/useTabFormValidation';
import AlertModalCard from 'components/atoms/AlertModalCard/AlertModalCard.component';
import { Modal } from 'components/organisms/Modal/Modal.components';
import { ReactComponent as WarningIcon } from 'utils/assets/images/warningIcon.svg';
import {
  ALERT_MODAL_DESCRIPTION,
} from 'utils/constants/adminPanelFormConstants';
import styles from './ConfirmDataTabForm.module.scss';
const DATE_START_INDEX = 0;
const DATE_END_INDEX = 10;

interface ConfirmDataTabFormProps {
  confirmBeforeSubmit?: boolean;
}

export default function ConfirmDataTabForm({
  confirmBeforeSubmit = true,
}: Readonly<ConfirmDataTabFormProps>) {
  const description = (
    <>
      Para iniciar tu proceso de contratación necesitaremos algunos datos. No
      olvides verificar la información de todos los campos y enviarlos.{' '}
      <strong>Una vez enviados no podrás editarlos.</strong>
    </>
  );
  const { email } = useAppSelector(selectUserInfo);
  const { vacant } = useAppSelector(selectVacantInfo);
  const { user, loading } = useUser(email);
  const [updateUser] = useUpdateUserDataMutation();
  const [submitConfirmData] = useSubmitConfirmDataMutation();
  const [updateAllUserData] = useUpdateAllUserDataMutation();
  const [selected, setSelected] = useState(false);
  const { setModalType, ...modalProps } = useModal();

  const methods = useForm<IUserConfirmData>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return user ?? {};
    }, [user]),
  });

  useEffect(() => {
    methods.reset(user ?? {});
  }, [user, methods.reset]);

  async function onSubmit(data: IUserConfirmData) {
    try {
      await updateAllUserData(data).unwrap();

      if (!vacant) return;

      await submitConfirmData({ email, idVacant: vacant.idVacant }).unwrap();
      setModalType('success');
    } catch (error) {
      setModalType('error');
    }
  }

  function saveOnBlur() {
    updateUser(methods.getValues());
  }

  const fields: Array<keyof IUserConfirmData>[] = [
    [
      'names',
      'lastname',
      'birthdate',
      'documentType',
      'documentNumber',
      'countryOfBirth',
      'departamentOfBirth',
      'cityOfBirth',
      'bloodGroup',
      'gender',
      'civilStatus',
      'preferredName',
      'shirtSize',
    ],
    [
      'phone',
      'email',
      'countryOfResidence',
      'departamentOfResidence',
      'cityOfResidence',
      'residenceDistrict',
      'address',
      'addressType',
      'emergencyContactInformation',
    ],
    ['academicInformation'],
  ];

  const { isTabValid, validateLastTab, triggerTab } = useTabFormValidation({
    fields,
    form: methods,
  });

 
  const [modal, setModal] = useState(false);

  function onGoBack() {
    setModal(true);
  }

  return ( 
    <>
      {loading && <LoaderCataloge textContent="Cargando datos" />}
      <ConfirmationStateModal {...modalProps} />
      <NotSavedAlertModal modal={modal} setModal={setModal} />
      <FormProvider {...methods}>
        <ExternalCodesProvider>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SectionContainer
              title="Confirma tus datos"
              subtitle={description}
              onGoBack={onGoBack}
            >
              <TabPanel>
                <TabList>
                  <Tab
                    onClick={() => validateLastTab(0)}
                    state={isTabValid(0)}
                    id={0}
                  >
                    Datos Personales
                  </Tab>
                  <Tab
                    onClick={() => validateLastTab(1)}
                    state={isTabValid(1)}
                    id={1}
                  >
                    Datos de Contacto
                  </Tab>
                  <Tab
                    onClick={() => validateLastTab(2)}
                    state={isTabValid(2)}
                    id={2}
                  >
                    Educación
                  </Tab>
                  <Tab
                    onClick={() => {
                      validateLastTab(3);
                      methods.trigger();
                      for (let i = 0; i <= 3; i++) triggerTab(i);
                    }}
                    state="none"
                    disabled={!methods.formState.isValid}
                    id={3}
                  >
                    Resumen
                  </Tab>
                </TabList>
                <div className="border-bottom"></div>
                <Section
                  id={0}
                  next={<TabNext onNext={() => triggerTab(0)} next={1} />}
                >
                  <Group>
                    <PersonalDataSection saveConfirmData={saveOnBlur} />
                  </Group>
                </Section>
                <Section
                  id={1}
                  prev={
                    <TabPrevious onPrevious={() => triggerTab(1)} prev={0} />
                  }
                  next={<TabNext onNext={() => triggerTab(1)} next={2} />}
                >
                  <ContactDataSection saveConfirmData={saveOnBlur} />
                </Section>
                <Section
                  id={2}
                  prev={
                    <TabPrevious onPrevious={() => triggerTab(2)} prev={1} />
                  }
                  next={
                    <TabNext
                      onNext={() => triggerTab(2)}
                      disabled={!methods.formState.isValid}
                      next={3}
                    />
                  }
                >
                  <Group
                    fullWidth
                    description="Para completar tu perfil agrega tu información académica. Puedes agregar hasta cinco instituciones educativas."
                  >
                    <EducationSection />
                  </Group>
                </Section>
                <Section
                  id={3}
                  next={
                    <AButton
                      tag="button"
                      type="submit"
                      className="button-primary"
                      disabled={confirmBeforeSubmit && !selected}
                    >
                      <span>Enviar</span>
                    </AButton>
                  }
                  prev={<TabPrevious prev={2} />}
                >
                  <SummarySection
                    confirmBeforeSubmit={confirmBeforeSubmit}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </Section>
              </TabPanel>
            </SectionContainer>
          </form>
        </ExternalCodesProvider>
      </FormProvider>{' '}
    </>
  );
}
function NotSavedAlertModal({
  modal,
  setModal,
}: Readonly<{
  modal: boolean;
  setModal: (value: boolean) => void;
}>) {
  const navigate = useNavigate();
  function handleExit() {
    window.location.reload();
  }

  return (
    <Modal isVisible={modal}>
      <AlertModalCard
        description={ALERT_MODAL_DESCRIPTION}
        iconBackground="Yellow"
        Icon={
          <WarningIcon
            style={{ width: '40px', height: '40px', filter: 'inherit' }}
          />
        }
      >
        <div className={styles.containerButtonsModal}>
          <button className={styles.secondary} onClick={() => setModal(false)}>
            Seguir editando
          </button>
          <button className={styles.primary} onClick={handleExit}>
            Salir
          </button>
        </div>
      </AlertModalCard>
    </Modal>
  );
}
function useModal() {
  const [modal, setModal] = useState<
    Omit<Parameters<typeof ConfirmationStateModal>[0], 'setIsOpened'>
  >({
    isOpened: false,
  });

  function setIsOpened(state: boolean) {
    setModal((prev) => ({ ...prev, isOpened: state }));
  }

  function setModalType(type: 'success' | 'error') {
    setModal((prev) => ({ ...prev, type, isOpened: true }));
  }

  return {
    isOpened: modal.isOpened,
    setIsOpened,
    setModalType,
    type: modal.type,
  };
}

function ConfirmationStateModal({
  type,
  isOpened,
  setIsOpened,
}: Readonly<{
  type?: Parameters<typeof ModalAlert>[0]['alertType'];
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
}>) {
  const navigate = useNavigate();

  function navigateHomeHiring() {
    navigate('/home-hiring')
    window.location.reload()
  }

  const alertText =
    type === 'success'
      ? '¡Has confirmado tus datos!'
      : '¡Ha ocurrido un error!';

  return (
    <ModalAlert
      alertType={type}
      alertText={alertText}
      isOpened={isOpened}
      confirmButton
      toggleModal={() => setIsOpened(true)}
    >
      <div className="confirm-data-form__modal-container">
        {type === 'success' && (
          <AImage
            className="confirm-data-form__modal-icon"
            url={checkIcon}
            alt="Icon Check"
          />
        )}

        {type === 'error' && (
          <AImage
            className="confirm-data-form__modal-icon"
            url={iconError}
            alt="Icon Error"
          />
        )}
        <span className="sizeText"> {alertText} </span>
        <div className="confirm-data-form__buttons-container">
          <AButton
            tag="button"
            className="button-primary--dark"
            handleClick={() =>
              type === 'success' ? navigateHomeHiring() : setIsOpened(false)
            }
          >
            {type === 'success' ? 'Aceptar' : 'Volver a intentar'}
          </AButton>
        </div>
      </div>
    </ModalAlert>
  );
}

function useUser(email: string) {
  const [user, setUser] = useState<IUserConfirmData | null>(null);
  const [loading, setLoading] = useState(false);

  function setNullsToEmptyString(data: IUserConfirmData) {
    const keys: Array<keyof IUserConfirmData> = Object.keys(data) as Array<
      keyof IUserConfirmData
    >;
    const newObject: any = {};
    keys.forEach((key) => {
      if (data[key] === null) {
        newObject[key] = '';
      } else {
        newObject[key] = data[key];
      }
    });
    newObject.emergencyContactInformation =
      data.emergencyContactInformation.map((contact) => {
        return {
          id: contact.id ?? '',
          fullname: contact.fullname ?? '',
          primaryPhone: contact.primaryPhone ?? '',
        };
      });

    newObject.academicInformation = data.academicInformation.map((info) => {
      const currentDate = '';
      const startDate = info.startDate
        ? new Date(info.startDate)
            .toISOString()
            .slice(DATE_START_INDEX, DATE_END_INDEX)
        : currentDate;
      const endDate = info.endDate
        ? new Date(info.endDate)
            .toISOString()
            .slice(DATE_START_INDEX, DATE_END_INDEX)
        : currentDate;

      return {
        id: info.id ?? '',
        educationalLevel: info.educationalLevel ?? '',
        professionalTitle: info.professionalTitle ?? '',
        professionalCard: info.professionalCard ?? '',
        institution: info.institution ?? '',
        startDate,
        endDate,
      };
    });
    return newObject;
  }

  useEffect(() => {
    setLoading(true);

    axios
      .get<{ data: IUserConfirmData }>(
        `${process.env.REACT_APP_TALENTO_URL}/hiring/candidates/user`,
        {
          params: {
            email,
          },
        }
      )
      .then((res) => {
        const data = setNullsToEmptyString(res.data.data);
        setUser(data);
        setLoading(false);
      });
  }, []);

  return { user, loading };
}
// Generated by https://quicktype.io

export interface IUserConfirmData {
  id: number;
  names: string;
  secondName: string;
  lastname: string;
  documentType: string;
  documentNumber: string;
  gender: string;
  birthdate: string;
  countryOfBirth: string;
  departamentOfBirth: string;
  cityOfBirth: string;
  civilStatus: string;
  bloodGroup: string;
  indicative: string;
  phone: string;
  email: string;
  countryOfResidence: string;
  departamentOfResidence: string;
  cityOfResidence: string;
  address: string;
  complementaryAddresses: string;
  addressType: string;
  educationLevel: string;
  professionalTitle: string;
  professionalCard: string;
  preferredName: string;
  shirtSize: string;
  greeting: string;
  role: string;
  cv: string;
  civilStatusEn: string;
  countryOfResidenceISO: string;
  state: string;
  countryOfBirthEn: string;
  secondLastname: string;
  updatedInformation: boolean;
  residenceDistrict: string;
  academicInformation: AcademicInformation[];
  emergencyContactInformation: EmergencyContactInformation[];
}

export interface AcademicInformation {
  id: number | null;
  educationalLevel: string;
  professionalTitle: string;
  professionalCard: string;
  institution: string;
  startDate: string;
  endDate: string;
  country?: string;
}

export interface EmergencyContactInformation {
  id: string | null;
  fullname: string;
  primaryPhone: string;
}

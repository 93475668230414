import { Loader } from 'components/atoms';
import './ProgressBar.scss';

interface IProps {
  totalStages: number;
  completedStages: number;
}


export const ProgressBar = ({
  totalStages,
  completedStages,
}: IProps) => {

  const calculatePercentage = () => `${100 / totalStages * completedStages}%`;
  const calculatePercentageBox = () => `${100 / totalStages}%`;
  const calculateBarDelay = () => { 
    const delay = (.3 * totalStages) + 1;
    return `${delay}s`
  }

  if (calculatePercentage() == 'NaN%') return <Loader />
  return (
    <div className='progress-bar' data-testid="progress">

      <section className='progress-bar__box--container' data-testid="container">
        {(() => {

          const box = [];
          let delay = 1

          for (let i = 0; i < totalStages; i++) {
            box.push(
              <div
                key={i}
                className={`${(i + 1 !== totalStages) ? 'progress-bar__box' : ''} progress-bar__box--show `}
                style={{ width: calculatePercentageBox(), animationDelay: `${delay}s` }}>
              </div> 
            );
            delay += .3;
          }

          return box;
        })()}
      </section>

      <div 
        data-testid="barCompleted"
        className={`progress-bar__background ${(calculatePercentage() == '100%') && 'progress-bar__background-completed'}`}
        style={{
          maxWidth: calculatePercentage(), animationDelay: calculateBarDelay() }}>
      </div>

    </div>
  );
};

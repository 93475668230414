import './Label.scss';
interface ILabel {
  text: string | number
  htmlFor?: string
  className?: string
  children?: React.ReactNode
  url?: string;
  icon?: boolean;
  secondary?: boolean;
}
export const Label = ({ text, htmlFor, className, children, url, icon, secondary = false }: ILabel) => {
  return (
    <label htmlFor={htmlFor} className={`label ${className}`} data-testid="testIdLabel" >
      {icon &&
        <img className={`label__img ${secondary ? 'label__img--secondary' : ''}`} src={url} alt="icono" />
      }
      {text}
      {children}
    </label>
  )
}

export default Label

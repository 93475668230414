import { collection, onSnapshot} from "firebase/firestore";
import { useEffect, useState} from "react";
import { db } from "services/firebase";
import { useGetListNotificationsQuery, useDeleteNotificationsFirebaseMutation, useUpdateStatusNotificationsMutation } from "services/talento";
import { useNavigate } from "react-router-dom";
import {firebase} from 'utils/constants/Notifications/indexConstants'
import {Numbers} from 'utils/helpers/dictionaries'
import {ROUTES} from 'utils/constants';
export interface Notification {
  id: number;
  notificationId: number;
  title: string;
  description: string;
  path: string;
  notificationStatus: string;
  notificationType?: string;
  idJobApplication?:number;
}

export default function useRealtimeNotifications(email: string) {
  const { data: notifications, refetch: fetchNotifications, isLoading } = useGetListNotificationsQuery({ email });
  const [deleteNotification] = useDeleteNotificationsFirebaseMutation();
  const [updateStatusNotification] = useUpdateStatusNotificationsMutation();
  const navigate = useNavigate(); 
  useEffect(() => {
    if (!email) {
      return;
    }
    const notificationsCollectionRef = collection(db, firebase.users);
    const unsubscribe = onSnapshot(notificationsCollectionRef, async (querySnapshot) => {
      if (querySnapshot.size <= Numbers.zero) {
        return;
      }

      if (!querySnapshot.docs.some((doc) => doc.id === email)) {
        return;
      }

      await fetchNotifications().unwrap();
      navigate(ROUTES.DASHBOARD_ROUTES.HOME.ROUTE);
      await pruneFirebaseNotifications(email);
    });
    return unsubscribe;
  }, [email]);
  
  async function pruneFirebaseNotifications(email: string) {
    if (!email) {
      return;
    }
    try {
      await deleteNotification(email).unwrap();
    } catch (error) {
      return;
    }
  }

  async function updateNotification(notificationId: number, status: string) {
    try {
      await updateStatusNotification({ idNotificacion: notificationId, status: status }).unwrap();
      await fetchNotifications().unwrap();
    } catch (error) {
    }
  }

  return {
    notifications: notifications?.data ?? [],
    updateNotification,
    isLoading
  };
}
import CatalogSelect from 'components/molecules/CatalogSelect/CatalogSelect';
import { useFormContext, Controller } from 'react-hook-form';
import { catalogoPersonal } from 'utils/helpers/dictionaries';
import { IAdminPanelForm } from '../AdminPanelForm.schema';
import styles from './ContactDataSection.module.scss';
import { Input } from 'components/atoms';
import CountrySelect from 'components/molecules/CountrySelect/CountrySelect';
import { useExternalCodeContext } from 'utils/helpers/ExternalCodesContext';
import DepartmentSelect from 'components/molecules/DepartmentSelect/DepartmentSelect';
import CitySelect from 'components/molecules/CitySelect/CitySelect';
import { useUpdateContactInfoHiringMutation } from 'services/talento';
import { useParams } from 'react-router-dom';
import { Select } from 'components/atoms';
import { IOption } from 'utils/interfaces';
export default function ContactDataSection() {
  const { control, getValues, setValue } = useFormContext<IAdminPanelForm>();
  setValue('addressType', 'Domicilio');
  setValue('phone', getValues('phone')?.replace('+', ''));
  const { email } = useParams();
  const [updateContactInfoHiring] = useUpdateContactInfoHiringMutation();
  const {
    countryOfResidence,
    departmentOfResidence,
    setCityOfResidence,
    setCountryOfResidence,
    setDepartmentOfResidence,
  } = useExternalCodeContext();
  let options: IOption[] = [{ value: 'Domicilio', label: 'Domicilio' }];

  async function submitData() {
    const phone = getValues('phone')?.replace('+', '') ?? '';
    await updateContactInfoHiring({
      address: getValues('address'),
      addressType: getValues('addressType'),
      cityOfResidence: getValues('cityOfResidence'),
      complementaryAddresses: getValues('complementaryAddresses') ?? '',
      countryOfResidence: getValues('countryOfResidence'),
      departamentOfResidence: getValues('departamentOfResidence'),
      email: email ?? '',
      phone,
      countryOfBirthEn: getValues('countryOfBirthEn'),
      state: getValues('state'),
      countryOfResidenceISO: getValues('countryOfResidenceISO'),
    }).unwrap();
  }
  return (
    <div className={styles.ContactDataSection}>
      <h2 className={styles.ContactDataSection__Title}>
        Información de contacto
      </h2>
      <div className={styles.ContactDataSection__Grid}>
        <Controller
          control={control}
          name="countryOfResidence"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <CountrySelect
              textLabel="País de residencia"
              htmlForLabel="countryOfResidence"
              subtext={fieldState.error?.message}
              placeholder={'Seleccione uno'}
              error={fieldState.error !== undefined}
              handleChange={(value, externalCode, valueEn) => {
                if (value !== getValues('countryOfResidence')) {
                  setValue('departamentOfResidence', '');
                  setValue('cityOfResidence', '');
                  setValue('documentType', '');
                }
                setValue('countryOfResidenceISO', externalCode ?? '');
                setValue('countryOfBirthEn', valueEn ?? '');
                onChange(value);
                submitData();
              }}
              value={{
                label: value,
                value,
              }}
              onExternalCodeChange={(externalCode) => {
                setCountryOfResidence(externalCode);
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="departamentOfResidence"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <DepartmentSelect
              textLabel="Departamento de residencia"
              htmlForLabel="departamentOfResidence"
              subtext={fieldState.error?.message}
              error={fieldState.error !== undefined}
              placeholder={'Seleccione uno'}
              handleChange={(value, externalCode) => {
                if (value !== getValues('departamentOfResidence')) {
                  setValue('cityOfResidence', '');
                }
                setValue('state', externalCode ?? '');
                onChange(value);
                submitData();
              }}
              value={{
                label: value as any,
                value: value as any,
              }}
              disabled={!countryOfResidence}
              country={countryOfResidence}
              onExternalCodeChange={(externalCode) => {
                setDepartmentOfResidence(externalCode);
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="cityOfResidence"
          render={({ field: { onChange, value, ...rest }, fieldState }) => (
            <CitySelect
              textLabel="Ciudad de residencia"
              htmlForLabel="cityOfResidence"
              subtext={fieldState.error?.message}
              error={fieldState.error !== undefined}
              placeholder={'Seleccione uno'}
              handleChange={(value) => {
                onChange(value);
                submitData();
              }}
              value={{
                label: value as any,
                value: value as any,
              }}
              disabled={!departmentOfResidence}
              department={departmentOfResidence}
              onExternalCodeChange={(externalCode) => {
                setCityOfResidence(externalCode);
              }}
              {...rest}
            />
          )}
        />
        <Controller
          control={control}
          name="addressType"
          defaultValue="Domicilio"
          render={({ field, fieldState: { error } }) => (
            <Select
              textLabel="Tipo de dirección"
              htmlForLabel="Tipo de dirección"
              placeholder="Seleccione una"
              disabled={true}
              options={options}
              error={error !== undefined}
              subtext={error?.message}
              {...field}
              handleChange={(value) => {
                field.onChange(value);
                submitData();
              }}
              value={{
                label: 'Domicilio',
                value: 'Domicilio',
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              id="address"
              label="Dirección"
              {...field}
              error={error !== undefined}
              success={isTouched && error === undefined}
              subtext={error?.message}
              maxLength={40}
            />
          )}
        />
        <Controller
          control={control}
          name="complementaryAddresses"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              id="complementaryAddresses"
              label="Complemento dirección (opcional)"
              {...field}
              error={error !== undefined}
              success={isTouched && error === undefined}
              subtext={error?.message}
              maxLength={40}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              id="phone"
              label="Número de contacto"
              {...field}
              error={error !== undefined}
              success={isTouched && error === undefined}
              subtext={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({
            field: { onBlur, ...field },
            fieldState: { error, isTouched },
          }) => (
            <Input
              onBlur={(e) => {
                onBlur();
                submitData();
              }}
              id="email"
              label="Correo personal"
              {...field}
              type="email"
              disabled
              error={error !== undefined}
              success={isTouched && error === undefined}
              subtext={error?.message}
            />
          )}
        />
      </div>
    </div>
  );
}

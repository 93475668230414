
import { HelmetProvider, Helmet } from 'react-helmet-async';

interface IProps {
  children: JSX.Element | JSX.Element[];
  title: string;
}

export const Seo = ({ children, title }: IProps) => (
  <HelmetProvider>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    {children}
  </HelmetProvider>
);

import { ChangeEvent } from 'react';
import { selectCurrentActivity } from 'store';
import { useAppSelector } from 'store/hooks';
import {
  activities,
  bytesToMB,
  isValidFile,
  isValidMagicNumber,
} from 'utils/helpers';
import { isValidFiles } from 'utils/helpers/isValidFile';
import { IFiles } from 'utils/interfaces';
import './InputFile.scss';
import { arrayFirstElement } from 'utils/constants';

type IResponse = { type?: string; isValid?: boolean } | unknown | any;
interface Props {
  setModal: (state: IModal) => void;
  setFiles?: (state: IFiles[]) => void;
  disabled?: boolean;
  files?: IFiles[];
  accept: string;
  keyInputs?: number;
}

interface IModal {
  message: string;
  type: 'success' | 'error' | 'inProgress';
  isOpen: boolean;
  title?: string;
  confirmButton?: boolean;
  closeIcon?: boolean;
}
export const AInputFile = ({
  disabled,
  setModal,
  setFiles = () => {},
  files,
  accept,
  keyInputs = 0,
}: Props) => {
  const { idActivity } = useAppSelector(selectCurrentActivity);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target.files) {
      const file = e.target.files[arrayFirstElement];

      if (idActivity === activities.essay) {
        if (isValidFile(file).isValid) {
          isValidMagicNumber(file).then((response: IResponse) => {
            if (response.type === 'pdf') {
              if (files) {
                let newFiles = files.concat({
                  fileSize: bytesToMB(file.size),
                  nameFile: file.name,
                  file: file,
                  urlFile: URL.createObjectURL(file),
                  base64: '',
                });
                setFiles(newFiles);
              }
            } else {
              setModal({
                message: 'Recuerda debe ser pdf',
                isOpen: true,
                type: 'error',
                confirmButton: true,
              });
            }
          });
        } else {
          const { message = '', title = '' } = isValidFile(file);
          setModal({
            message,
            title,
            isOpen: true,
            type: 'error',
            closeIcon: true,
          });
        }
      } else {
        if (isValidFiles(file).isValid) {
          isValidMagicNumber(file).then((response: IResponse) => {
            if (
              response.type === 'doc,xls,ppt,zip' ||
              response.type === 'pdf'
            ) {
              if (files) {
                let newFiles = files.concat({
                  fileSize: bytesToMB(file.size),
                  nameFile: file.name,
                  file: file,
                  urlFile: URL.createObjectURL(file),
                  base64: '',
                });
                setFiles(newFiles);
              }
            } else {
              setModal({
                message: 'Tipo de archivo no compatible',
                isOpen: true,
                type: 'error',
                confirmButton: true,
              });
            }
          });
        } else {
          const { message = '', title = '' } = isValidFiles(file);
          setModal({
            message,
            title,
            isOpen: true,
            type: 'error',
            closeIcon: true,
          });
        }
      }
    } else {
      return;
    }
  };

  return (
    <input
      type="file"
      id="upload_file"
      name="file"
      data-testid="upload_file"
      accept={accept}
      style={{ display: 'none' }}
      onChange={handleFile}
      disabled={disabled}
      role="input"
      key={keyInputs}
    />
  );
};

export default AInputFile;

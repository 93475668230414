import React, { ReactNode } from 'react';
import styles from './ALertModalCard.module.scss';
import { ReactComponent as CloseIconModal } from 'utils/assets/images/closeIconModal.svg';

interface AlerModalCard {
  Icon?: ReactNode;
  title?: string;
  description?: string;
  children?: any;
  iconBackground: 'Yellow' | 'Red' | 'Green' | 'Transparent';
  onClose?: any;
}

const AlertModalCard = ({
  Icon,
  title,
  description,
  children,
  iconBackground,
  onClose,
}: AlerModalCard) => {
  let backgrounColor;

  switch (iconBackground) {
    case 'Yellow':
      backgrounColor = styles.yellow;
      break;
    case 'Red':
      backgrounColor = styles.red;
      break;
    case 'Green':
      backgrounColor = styles.green;
      break;
    case 'Transparent':
      backgrounColor = styles.transparent;
      break;
  }

  return (
    <div>
      <div className={styles.alert_modal_card}>
        {!children && (
          <button
            type="button"
            className={styles.closeButton}
            onClick={onClose}
          >
            <CloseIconModal />
          </button>
        )}
        <div className={backgrounColor}>
          <div className={styles.alert_modal_card__icon}>
            <div className={styles.alert_modal_card__iconSvg}>{Icon}</div>
          </div>
        </div>
        <div className={styles.alert_modal_card__title}>{title}</div>
        <div className={styles.alert_modal_card__description}>
          {description}
        </div>
        <div className={styles.alert_modal_card__children}>{children}</div>
      </div>
    </div>
  );
};

export default AlertModalCard;

import { AButton } from 'components/atoms'
import { ContentNotFound } from 'components/atoms/ContentNotFound/ContentNotFound.components'
import React from 'react'
import './ErrorCustom.scss';


interface IProps {
  code: string;
}

const ErrorCustom = ({code} : IProps) => {

  return (
    <div className='error-custom' data-testid="container__error">
      <div className='error-custom__content'>

        <div className='error-custom__span-container'>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
          <span className='error-custom__span'></span>
        </div>

        <ContentNotFound error={"Error"} code={code} />
        <AButton className='button-primary--white error-custom__button' tag='a' url='/login'>Volver al inicio</AButton>
      </div>
    </div>
  )
}

export default ErrorCustom

import { IAFile } from 'utils/interfaces';
import { ImageUploadConstants } from 'utils/constants/constants';
const MAGIC_NUM_PDF = '25504446';
const MAGIC_NUM_OFF_ZIP = '504b34';
const MAGIC_NUM_JPEG = 'ffd8ffe0';
const MAGIC_NUM_PNG = '89504e47';
const MAX_SIZE_FILE_ESSAY = 7340032; // 7MB
const MAX_SIZE_FILES = 8000000; // 8MB
const fileLimitExceededTitle = ImageUploadConstants.FILE_LIMIT_EXCEEDED_TITLE;
const acceptedType = ImageUploadConstants.ACCEPTED_TYPES;
interface IValidFile {
  isValid: boolean;
  message?: string;
  title?: string;
}

const validateType = (name: string, only?: string) => {
  const arrayName = name.split('.');
  const extension = arrayName[arrayName.length - 1].toLowerCase();
  let isValid = false;

  if (only) {
    if (only === extension) isValid = true;
  } else {
    switch (extension) {
      case 'pdf':
        isValid = true;
        break;
      case 'doc':
        isValid = true;
        break;
      case 'docx':
        isValid = true;
        break;
      case 'ppt':
        isValid = true;
        break;
      case 'pptx':
        isValid = true;
        break;
      case 'xls':
        isValid = true;
        break;
      case 'xlsx':
        isValid = true;
        break;
      case 'zip':
        isValid = true;
        break;
      case 'jpeg':
        isValid = true;
        break;
      case 'jpg':
        isValid = true;
        break;
      case 'png':
        isValid = true;
        break;
      default:
        break;
    }
  }

  return isValid;
};

//Validate file type, when it is an individual pdf file (Essay activity)
export const isValidFile = (file: IAFile): IValidFile => {
  if (file.type === 'application/pdf') {
    if (file.size <= MAX_SIZE_FILE_ESSAY) {
      if (validateType(file.name, 'pdf')) {
        return { isValid: true, message: 'valido' };
      }
    } else {
      return {
        isValid: false,
        message: 'Recuerda el peso máximo es de 7MB',
        title: '¡Ups! El archivo supera las 7MB',
      };
    }
  } else {
    return { isValid: false, message: 'Recuerda debe ser pdf' };
  }

  return { isValid: false };
};

//Validate file type, when dealing with multiple files of type pdf,doc,xls,ppt,zip (Activity approach to role)
export const isValidFiles = (file: IAFile): IValidFile => {
  if (
    file.type === 'application/pdf' ||
    file.type === 'application/x-zip-compressed' ||
    file.type === 'application/msword' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/vnd.ms-excel' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
    file.type === 'application/vnd.ms-powerpoint'
  ) {
    if (file.size <= MAX_SIZE_FILES) {
      if (validateType(file.name)) {
        return { isValid: true, message: 'valido' };
      }
    } else {
      return {
        isValid: false,
        message: 'Recuerda el peso máximo es de 7Mb',
        title: '¡Ups! El archivo supera las 7MB',
      };
    }
  } else {
    return { isValid: false, message: 'Tipo de archivo no compatible' };
  }

  return { isValid: false };
};

//Unambiguously validate file type
export const isValidMagicNumber = async (
  file: IAFile
): Promise<{ type: string; isValid: boolean }> => await magicNumbers(file);

const magicNumbers = (
  file: IAFile | any
): Promise<{ type: string; isValid: boolean }> =>
  new Promise((resolve) => {
    const fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      const uint = new Uint8Array(e.target?.result as ArrayBuffer).subarray(
        0,
        4
      );
      let header = '';
      let type = null;
      let isValid = false;
      for (let i = 0; i < uint.length; i++) {
        header += uint[i].toString(16);
      }

      switch (header) {
        case MAGIC_NUM_PDF:
          type = 'pdf';
          isValid = true;
          break;
        case MAGIC_NUM_OFF_ZIP:
          type = 'doc,xls,ppt,zip';
          isValid = true;
          break;
        case MAGIC_NUM_JPEG:
          type = 'jpeg';
          isValid = true;
          break;
        case MAGIC_NUM_PNG:
          type = 'png';
          isValid = true;
          break;
        default:
          type = 'unknown';
          isValid = false;
          break;
      }
      resolve({ type, isValid });
    };
    fileReader.readAsArrayBuffer(file.slice(0, 4));
  });

export const isValidImage = (file: IAFile): IValidFile => {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    if (file.size <= MAX_SIZE_FILES) {
      if (
        validateType(file.name, acceptedType.JPEG) ||
        validateType(file.name, acceptedType.PNG) ||
        validateType(file.name, acceptedType.JPG)
      ) {
        // Resto de la lógica para el tamaño del archivo y otras validaciones si es necesario
        return { isValid: true, message: 'Es una imagen válida' };
      }
    } else {
      return {
        isValid: false,
        message: 'Recuerda el peso máximo es de 8Mb',
        title: fileLimitExceededTitle,
      };
    }
  } else {
    return { isValid: false, message: 'Recuerda debe ser jpg o png' };
  }

  return { isValid: false };
};
